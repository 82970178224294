import { useState } from 'react'
import { Link } from 'react-router-dom'

const SingleConnector = ({ connector, count, countEl, currencyAlignment, enterpriseText }) => {
    const formatNumber = (num) => {
        if (typeof num !== 'number') return num
        return num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
    const [isExpand, setIsExpand] = useState(false)

    return (<div className='connector-list-item'>
        <div className='connector-list-item-header' style={{ position: 'relative' }} onClick={() => setIsExpand(!isExpand)} >
            <div className={'expand' + (!!isExpand ? "  expanded" : "")}> </div>
            <div className='connector-item-title'> {connector.name}  (  {count}) <span style={{ color: '#4caf50', fontWeight: '600' }}>{enterpriseText}</span>  </div>
            <div className='amount' > {formatNumber(connector.value)} </div>
            <div className='currency' > {connector.currency} </div>
        </div>
        {
            !!isExpand &&
            <div className='connector-list-item-details'>
                <div className='connector-list-item-details-list'>
                    {
                        connector.details.map((detail, detailIndex) => (
                            <div className='connector-list-item-details-list-item' style={{ position: 'relative' }} key={detailIndex} >
                                <div className='connector-item-title' style={{ display: "flex" }}>
                                    <p style={{ minWidth: "120px", margin: 0 }}>{detail.name}</p>
                                    {detailIndex === 0 && connector.details.length <= 4 && <Link to={"/account/connections"} style={{ margin: 0, textDecoration: "none" }}>( <span>{detail.count === "1" ? <span> <span style={{ color: "var(--data-con-blue)", textDecoration: "underline" }}>1 Connection</span> &nbsp;</span> : <span style={{ color: "var(--data-con-blue)", textDecoration: "underline" }}>{detail.count} Connections</span>}</span> )</Link>}
                                    {detailIndex !== 0 && <Link to={"/account/connections"} style={{ margin: 0, textDecoration: "none" }}>( <span>{detail.count === "1" ? <span> <span style={{ color: "var(--data-con-blue)", textDecoration: "underline" }}>1 Connection</span> &nbsp;</span> : <span style={{ color: "var(--data-con-blue)", textDecoration: "underline" }}>{detail.count} Connections</span>}</span> )</Link>}
                                </div>
                                <div className='amount' > {formatNumber(detail.value)} </div>
                                <div className='currency' > {detail.currency} </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        }
    </div>);

}


export default SingleConnector;