import { Typography, Card, CardContent } from "@mui/material"

export default function BusinessCard ({ Image, title, txt1, txt2, phone, email }) {
  return (
    <Card raised sx={{ display: 'flex', flexWrap: 'wrap', maxWidth: 460 }}>
      <CardContent sx={{ pt: 0, pl: 0 }}>
        <img src={Image} alt={title} style={{ width: 130 }} />
      </CardContent>
      <CardContent>
        <Typography component="div" variant="h5" sx={{ pb: 1 }}>
          {title}
        </Typography>
        <Typography variant="h6" component="div" sx={{ color: '#85C340', pb: 2, lineHeight: 'normal' }}>
          {txt1}
          <br />
          {txt2}
        </Typography>
        <Typography component={'h6'} variant="h6" sx={{ lineHeight: 'normal' }}>
          Contact:
        </Typography>
        <Typography component={'div'} variant="h6" sx={{ lineHeight: 'normal' }}>
          Phone: {phone}
        </Typography>
        <Typography component={'div'} variant="h6" sx={{ lineHeight: 'normal' }}>
          Email: {email}
        </Typography>
      </CardContent>
    </Card>
  )
}