import React, { useEffect, useState, useContext, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { DataGrid } from '@mui/x-data-grid';
import { TextField } from '@mui/material';
import userEditIcon from '../../../assets/images/user-edit-icon.png';
import deleteIcon from '../../../assets/images/delete.png';
import { UserAPIService, PermissionAPIService } from '../../../config/api-service';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Close';
import { PermissionContext, usePermissionContext } from '../../../context/permission.context'
import { CommonLoaderContext } from "../../../config/router";
import moment from 'moment';
import AddUserModal from "./addUserModal"
import AlertDialog from "../../common/dialog"
import PermissionSelect from './permissionSelect';
import MaterialUITheme from '../../../config/material-ui-theme'



const renderEditPermissions = (params) => {
    return <EditPermissions {...params} />
}
const uuidv4 = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    // FIXME: 
    // eslint-disable-next-line
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

const validEmailRegex = (email) => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

const RenderPermissionCell = (params) => {
    const allPermissionList = usePermissionContext()
    const { value } = params;
    let permissionListNameArr = [];
    !!value && value.forEach(permission => {
        let permissionObj = allPermissionList.find(p => p.PermissionID === permission)
        !!permissionObj && permissionListNameArr.push(permissionObj.PermissionNM)
    })
    return permissionListNameArr.join(",");
}

const RenderRemoveUser = (params) => {
    const [userDeleteModal, setUserDeleteModal] = useState(false)
    const [isMainContact, setIsMainContact] = useState(false)
    const [confirmMsg, setConfirmMsg] = useState('Are you sure you want to remove this user?')

    const history = useHistory()

    const handleDeleteUser = async () => {
        if (isMainContact) {
            history.push('/account/organization')
        } else {
            try {
                await UserAPIService.deleteUser(params.row.UserID)
                api.updateRows([{ id, _action: 'delete' }])
                setIsMainContact(false)
                toast.success('User successfully deleted')
            } catch (error) {
                const { response: { data } } = error
                if (data.showModal) {
                    setIsMainContact(true)
                    setConfirmMsg(data.message)
                }
                toast.error(data.message)
            }
        }
    }

    useEffect(() => {
        setConfirmMsg('Are you sure you want to remove this user?')
        setIsMainContact(false)
    }, [userDeleteModal])

    const { api, id } = params
    const isInEditMode = api.getRowMode(id) === 'edit'

    const handleClickDelete = (e) => {
        e.preventDefault();
        e.stopPropagation()
        setUserDeleteModal(true)
    }

    const handleClickEdit = (e) => {
        e.stopPropagation()
        api.setRowMode(id, 'edit')
    }

    const handleClickCancel = (e) => {
        e.preventDefault()
        e.stopPropagation()
        api.setRowMode(id, 'view')

        const row = api.getRow(id)
        if (row.isNew) {
            api.updateRows([{ id, _action: 'delete' }]);
        }
    }

    const handleClickSave = async (e) => {
        e.stopPropagation()
        api.commitRowChange(id)

        const row = api.getRow(id)
        api.updateRows([{ ...row, isNew: false }])

        const { FirstNM, LastNM, EmailTXT, permissionIds } = row
        let payload = {
            FirstNM,
            LastNM,
            EmailTXT,
            permissions: permissionIds
        }

        let isValidationError = false
        const model = api.getEditRowsModel(id)[id]
        Object.keys(model).forEach((id) => {
            const { error } = model[id]
            if (error) {
                isValidationError = true
                toast.error('Error')
            }
        })

        if (isValidationError) {
            isValidationError = false
        } else {
            try {
                await UserAPIService.updateUserInformation(params.row.UserID, payload)
                api.setRowMode(id, 'view')
                toast.success('User updated')
            } catch (error) {
                toast.error('Error')
            }
        }

    }

    if (isInEditMode) {
        return (
            <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                <SaveIcon style={{ cursor: 'pointer' }} fontSize="small" onClick={handleClickSave} />
                <CancelIcon style={{ cursor: 'pointer' }} fontSize="small" onClick={handleClickCancel} />
            </div>
        )
    }

    return <div className='remove-user-btn' >
        <img src={userEditIcon} alt="user edit" onClick={(e) => { handleClickEdit(e) }} />
        <img src={deleteIcon} style={{ height: 15 }} alt="user delete" onClick={(e) => { handleClickDelete(e) }} />
        <AlertDialog open={!!userDeleteModal} handleConfirm={handleDeleteUser} handleClose={() => setUserDeleteModal(false)}>
            {confirmMsg}
        </AlertDialog>
    </div>
}

const RenderUserStatusCell = (params) => {
    const { value } = params
    const [resendEmailModal, setResendEmailModal] = useState(false)

    const handleResendVerificationEmail = async () => {
        setResendEmailModal(false)
        try {
            await UserAPIService.resendVerificationEmail(params.row.UserID)
            toast.success('Email sent successfully')
        } catch (error) {
            toast.error('Error')
        }
    }
    // FIXME:
    const hre = "#"
    return <div className='mail-status-icon'>
        {value ? <>
            <CheckIcon sx={{ pl: 1 }} fontSize='small' color='success' />
            <small style={{ color: '#2e7d32' }}>Verified</small>
        </> :
            <>
                <CloseIcon sx={{ pl: 1 }} fontSize='small' color='error' />
                <small style={{ color: '#d32f2f' }}>Not Verified </small>
                <a href={hre} style={{ color: "#aaa", fontSize: '12px', paddingLeft: 5 }} onClick={() => { setResendEmailModal(true) }}>Resend email</a>
                <AlertDialog open={resendEmailModal} handleConfirm={handleResendVerificationEmail} handleClose={() => setResendEmailModal(false)}>
                    Are you sure, you want to resend email?
                </AlertDialog>
            </>
        }
    </div>
}

const EditPermissions = (params) => {
    const [isPermissionUpdate, setIsPermissionUpdate] = React.useState(uuidv4());
    const [permissions, setPermissions] = React.useState({ permissions: [] });
    const allPermissionList = usePermissionContext()

	const handlePermissionChange = (fieldName, PermissionIDs) => {
		let temp = { ...permissions } || {};
		temp[fieldName] = PermissionIDs;
		setPermissions(temp);
		// setPermissionUpdate(uuidv4())
	};

    useEffect(() => {
		setPermissions({ permissions: params.value });
	}, [params.value]);

    return (
        <PermissionSelect key={isPermissionUpdate} handlePermissionChange={handlePermissionChange} permissionList={allPermissionList} fieldName="permissions" selectedArr={permissions.permissions || []} />
    );
}


const columns = [
    {
        field: 'FirstNM',
        headerName: 'First Name',
        flex: 1,
        editable: true,
    },
    {
        field: 'LastNM',
        headerName: 'Last Name',
        flex: 1,
        editable: true,
    },
    {
        field: 'EmailTXT',
        headerName: 'Email',
        editable: true,
        flex: 1,
    }, {
        field: 'EmailVerifiedFLG',
        headerName: 'Email Verification',
        renderCell: RenderUserStatusCell,
        flex: 1,
    },
    {
        field: 'permissionIds',
        headerName: 'Permissions',
        renderEditCell: renderEditPermissions,
        renderCell: RenderPermissionCell,
        editable: true,
        flex: 1,
    },
    {
        field: 'LastLogin',
        headerName: 'Last Login',
        align: "left",
        weight: 'bold',
        width: 170
    },
    {
        field: "Actions",
        headerName: 'Actions',
        renderCell: RenderRemoveUser,
        width: 120,
        align: "center",
    }
];

const escapeRegExp = (value) => {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const RenderSearchField = (props) => {
    return <div className='user-search-input' >
        <TextField
            onChange={props.onChange}
            inputProps={{ style: { backgroundColor: '#fff' } }}
            placeholder="Search Users"
            className='user-ftext'
            size='small'
            variant="outlined"
            name="keyWord"
            fullWidth
            value={props.value}
        />
    </div>
}



const AccountsComponent = () => {
    const [selectionModel, setSelectionModel] = useState([]);
    const [users, setUsers] = useState([])
    const [addUsersModalShow, setAddUsersModalShow] = useState(false);
    const [permissionList, setPermissionList] = useState([])
    const [, setCommonLoader] = useContext(CommonLoaderContext);
    const [searchText, setSearchText] = React.useState('');
    const [rows, setRows] = React.useState(users);

    const handleAddNewUserObj = (data) => {
        fetchUserList();
        setAddUsersModalShow(false)
    }

    const fetchUserList = () => {
        setCommonLoader(true);
        UserAPIService.getUserWithPermission()
            .then(res => {
                if (res) {
                    res.forEach(r => {
                        r.id = r.UserID;
                        r.LastLogin = r.LastLoginDTS ? moment(r.LastLoginDTS).format("YYYY/MM/DD kk:mm") : "";
                        r.permissionIds = r.Permissions.map(function (p) {
                            return p['PermissionID'];
                        });
                    })
                    setUsers(res)
                    setCommonLoader(false);
                    setRows(res)
                }
            })
    }



    useEffect(() => {
        fetchUserList();
        PermissionAPIService.getPermissions()
            .then(res => {
                setPermissionList(res)
            })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = users.filter((row) => {
            return Object.keys(row).some((field) => {
                return !!row[field] && searchRegex.test(row[field].toString());
            });
        });
        setRows(filteredRows);
    };

    const handleRowEditStart = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const [editRowsModel, setEditRowsModel] = useState({})
    const handleEditRowsModelChange = useCallback((newModel) => {
        const updatedModel = { ...newModel }
        Object.keys(updatedModel).forEach((id) => {
            if (updatedModel[id].EmailTXT) {
                const isValid = validEmailRegex(updatedModel[id].EmailTXT.value)
                updatedModel[id].EmailTXT = { ...updatedModel[id].EmailTXT, error: !isValid }
            }
            if (updatedModel[id].FirstNM) {
                const isValid = !!updatedModel[id].FirstNM.value.trim()
                updatedModel[id].FirstNM = { ...updatedModel[id].FirstNM, error: !isValid }
            }
            if (updatedModel[id].LastNM) {
                const isValid = !!updatedModel[id].LastNM.value.trim()
                updatedModel[id].LastNM = { ...updatedModel[id].LastNM, error: !isValid }
            }
        })
        setEditRowsModel(updatedModel)
    }, [])

    return (
        <PermissionContext.Provider value={permissionList}>
            <MaterialUITheme>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: '20px' }}>
                    <div style={{ width: '95%' }}>
                        <div className='accounts-header'>
                            <span>Manage Users</span>
                        </div>
                        <div className='add-user-btn-wrap'>
                            <div className='add-user-btn' onClick={() => setAddUsersModalShow(true)} >ADD USER</div>
                        </div>
                        {/* // eslint-disable-next-line */}
                        <div className='data-Grid-wrap user-table' style={{ height: !!users && users.length ? ((users.length + 1) * 52) : 200, maxHeight:"75vh" }} >
                            <DataGrid
                                editMode="row"
                                headerHeight={39}
                                rows={rows}
                                columns={columns}
                                hideFooterPagination={true}
                                hideFooter={true}
                                selectionModel={selectionModel}
                                onRowEditStart={handleRowEditStart}
                                onRowEditStop={handleRowEditStop}
                                components={{ Toolbar: RenderSearchField }}
                                componentsProps={{
                                    toolbar: {
                                        value: searchText,
                                        onChange: (event) => requestSearch(event.target.value),
                                        clearSearch: () => requestSearch(''),
                                    },
                                }}
                                onSelectionModelChange={(newSelectionModel) => {
                                    setSelectionModel([...newSelectionModel])
                                }}
                                editRowsModel={editRowsModel}
                                onEditRowsModelChange={handleEditRowsModelChange}
                            />
                        </div>
                        {!!addUsersModalShow && <AddUserModal handleAddNewUserObj={(data) => handleAddNewUserObj(data)} permissionList={permissionList} showSaveBtn={true} setAddUsersModalShow={(flag) => setAddUsersModalShow(flag)} />}
                    </div>
                </div>
            </MaterialUITheme>
        </PermissionContext.Provider>

    );
}

export default AccountsComponent;
