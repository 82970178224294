import { useEffect, useState } from 'react'
import { Box, TextField } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockIcon from '@material-ui/icons/Lock';
import InputMask from 'react-input-mask'
import { toast } from 'react-toastify'
import Dialog from "../../common/dialog"
import { UserAPIService } from '../../../config/api-service';
import amexIcon from "../../../assets/images/card/amex_icon.png"
import discoverCardIcon from "../../../assets/images/card/discovercard_icon.png"
import masterCardIcon from "../../../assets/images/card/mastercard_icon.png"
import visaIcon from "../../../assets/images/card/visa_icon.png"

const paymentMethods = [
  {
    name: 'amex',
    icon: amexIcon
  },
  {
    name: 'discover',
    icon: discoverCardIcon
  },
  {
    name: 'mastercard',
    icon: masterCardIcon
  },
  {
    name: 'visa',
    icon: visaIcon
  }
]

const AddOrEditCardModal = ({ open, handleOpen, paymentMethodObj, onSuccess }) => {
  const { id, card = {}, billing_details: { address: { postal_code } = {} } = {} } = paymentMethodObj

  const handleSubmit = async () => {
    const date = inputs['expDate'].split('/')
    let monthYear
    if (date?.length > 1) {
      monthYear = {
        exp_month: date[0],
        exp_year: `20${date[1]}`
      }
    } else {
      monthYear = {
        exp_month: card.exp_month,
        exp_year: card.exp_year
      }
    }

    await UserAPIService.updateCustomerPaymentMethod({
      cpmid: id,
      data: {
        card: monthYear,
        billing_details: {
          address: {
            postal_code: inputs['postal_code']
          }
        }
      }
    })
    toast.success('Payment method updated')
    handleOpen(false)
    onSuccess()
  }
  const [inputs, setInputs] = useState({
    expDate: '',
    postal_code: postal_code
  })

  useEffect(() => {
    if (Object.keys(card)?.length) {
      const month = card?.exp_month?.toString().length === 1 ? `0${card.exp_month}` : card.exp_month
      setInputs({ ...inputs, expDate: `${month}${card?.exp_year?.toString().substr(-2)}` })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [card])

  const handleChange = (event) => {
    setInputs({ ...inputs, [event.target.name]: event.target.value })
  }

  return (
    <Dialog open={!!open} title="Update Your Card" handleConfirm={handleSubmit} handleClose={() => handleOpen(false)} highlightYes>
      <div className='lock-open-icon-wrap'>
        <LockOutlinedIcon />
      </div>

      <Box sx={{ pt: 2, textAlign: 'left', '& img': { mr: .5 } }}>
        {
          paymentMethods.map(({ name, icon }) => (
            <img
              key={name}
              src={icon}
              alt={name}
              className="billing-custom-credit-card"
              style={{ border: name === card.brand ? '3px solid #00bfff' : null, borderRadius: name === card.brand ? 3 : null }}
            />
          ))
        }
      </Box>

      <Box
        component="form"
        sx={{
          textAlign: 'left',
          position: 'relative',
          '& .MuiTextField-root': { mb: 2 }
        }}
      >
        <Box
          sx={{
            fontSize: 12,
            position: 'absolute',
            top: 5,
            right: -2,
            display: 'flex',
            alignItems: 'center'
          }}>
          <span className='size-12' style={{ color: "#7a7a7a" }}>Secure Server</span>
          <LockIcon style={{ fill: '#FFB53F', fontSize: 15, marginLeft: 3 }} />
        </Box>

        <label style={{ fontSize: 14, textTransform: 'uppercase' }}>
          Card Number
        </label>
        <TextField
          required
          fullWidth
          size="small"
          variant="outlined"
          name="exp_month"
          defaultValue={`**** **** **** ${card.last4}`}
          InputProps={{
            readOnly: true,
            disabled: true,
          }}
        />

        <label style={{ fontSize: 14, textTransform: 'uppercase' }}>
          Expiration Date <span style={{ color: 'red' }}>*</span>
        </label>
        <InputMask
          mask={'99/99'}
          value={inputs['expDate']}
          onChange={handleChange}
        >
          <TextField
            required
            fullWidth
            size="small"
            name="expDate"
            placeholder="MM / YY"
          />
        </InputMask>

        <label style={{ fontSize: 14, textTransform: 'uppercase' }}>
          Billing ZIP Code <span style={{ color: 'red' }}>*</span>
        </label>
        <TextField
          required
          fullWidth
          type="number"
          size="small"
          variant="outlined"
          name="postal_code"
          defaultValue={postal_code}
          onChange={handleChange}
        />
      </Box>
    </Dialog>
  );

}


export default AddOrEditCardModal;