import { useState, createContext, useContext } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import PrivateRoute from "../components/common/authentication/PrivateRoute";
import { HelpComponent } from "../components/documentation";
import { LoginComponent, NewConnectorComponent } from "../components";
import SelectOrgForMarketing from "../components/login/SelectOrgForMarketing";
import NavigationComponent from "../components/navigation";
import ResetComponent from "../components/reset";
import MembershipAlert from "../components/account/MembershipAlert";
import JobLogsComponent from "../components/job-logs";
import AccountComponent from "../components/account";
import PipelinesComponent from "../components/pipelines";
import ConnectorProvider from "../context/connector.context";
import AdminComponent from "../components/admin";
import ResourcesPage from "../components/resources/Resources";
import SupportComponent from "../components/support";
import ProfessionalServices from "../components/support/ProfessionalServices";
import AppProvider from "../context/app.context";
import PipelineProvider from "../components/pipeline/pipeline.context";
import { OrganizationContext } from "../context/organization.context"
import { Box, CircularProgress } from '@material-ui/core';
import EditConnector from '../components/admin/edit';
import ForgotPasswordComponent from '../components/forgot';
import FeatureRequestLayout from '../components/feature-request/Layout'
import OAuthHelperComponent from "../components/utils/oauth-helper/OauthHelper";
import PaymentMethodAlert from "../components/account/PaymentMethodAlert";
export const CommonLoaderContext = createContext();

export const RouterConfig = () => {
	const { isActiveUser } = useContext(OrganizationContext);
	const [commonLoader, setCommonLoader] = useState(false);

	return (
		<CommonLoaderContext.Provider value={[commonLoader, setCommonLoader]}>
			{!!commonLoader && (
				<div className="common-loader-wrap">
					{" "}
					<Box sx={{ display: "flex", justifyContent: "center" }}>
						{" "}
						<CircularProgress
							color="white"
							size={16}
							thickness={6}
							style={{ marginRight: 10 }}
						/>
					</Box>
				</div>
			)}
			<AppProvider>
				<Router>
					<Switch>
						<Route path="/oauth">
							<OAuthHelperComponent />
						</Route>
						<Route path="/login">
							<LoginComponent />
						</Route>
						<Route path="/forgot">
							<ForgotPasswordComponent />
						</Route>
						<Route path="/membership-alert">
							<MembershipAlert />
						</Route>
						<Route path="/payment-method-alert">
							<PaymentMethodAlert />
						</Route>
						<Route path="/organization">
							<SelectOrgForMarketing />
						</Route>
						{!isActiveUser && (
							<Route path="/account">
								<AccountComponent />
							</Route>
						)}
						{/* <Route path="/pipelines/new" exact={true}>
							<PipelineProvider>
								<NewConnectorComponent />
							</PipelineProvider>
						</Route> */}
						<PrivateRoute>
							<NavigationComponent />
							<Switch>
								<Route path="/resources" exact={true}>
									<ResourcesPage />
								</Route>
								<Route path="/support" exact={true}>
									<SupportComponent />
								</Route>
								<Route
									path="/support/professional-services"
									exact={true}
								>
									<ProfessionalServices />
								</Route>
								<Route path="/pipelines/new" exact={true}>
									<PipelineProvider>
										<NewConnectorComponent />
									</PipelineProvider>
								</Route>
								<Route path="/pipelines/:id" exact={true}>
									<PipelineProvider>
										<NewConnectorComponent />
									</PipelineProvider>
								</Route>
								<ConnectorProvider>
									<Route path="/logs/:id" exact={true}>
										<JobLogsComponent />
									</Route>
									<Route path="/reset">
										<ResetComponent />
									</Route>
									<Route path="/documentation">
										<HelpComponent />
									</Route>
									{isActiveUser && (
										<Route path="/account">
											<AccountComponent />
										</Route>
									)}
									<Route
										path="/feature-request"
										exact={true}
									>
										<FeatureRequestLayout />
									</Route>
									
									{window.location.hostname.includes("localhost") && (<>
										<Route path="/admin" exact={true}>
											<AdminComponent />
										</Route>
										<Route
											path="/admin/connector/test/:id"
											exact={true}
										>
											<EditConnector />
										</Route>
										</>)
									}
									<Route
										path={["/", "/pipelines"]}
										exact={true}
									>
										<PipelinesComponent />
									</Route>
								</ConnectorProvider>
							</Switch>
						</PrivateRoute>
					</Switch>
				</Router>

				<ToastContainer
					position="bottom-right"
					progress={undefined}
					theme="colored"
				/>
			</AppProvider>
		</CommonLoaderContext.Provider>
	);
};
