import { Card, CardActionArea, CardMedia } from '@mui/material';
import Dialog from '../common/dialog'
import React, {useEffect} from 'react';
import { EventLogAPIService } from '../../config/api-service';

function HelpModal({activeStep, showModal, setShowModal}) {
    useEffect(() => {
        if (showModal) {
            let eventMessage = `User opened help pane on the ${activeStep ===  0 ? "Connect" : activeStep === 1  ? "Map" : "Load" } page.`
            EventLogAPIService.postEvent(eventMessage, null);
        }
    }, [showModal]);
    
    return (
        <Dialog
            open={showModal}
            handleClose={() => setShowModal(false)}
            title={activeStep === 0 ? "Connect" : activeStep === 1 ? "Map" : activeStep === 2 ? "Load" : ""}
            centerAligned={false}
            maxWidth="lg"
        >
            <Help videoUrl={activeStep === 0 ? "https://www.youtube.com/embed/JLBLJqUxoWU" : activeStep === 1 ? "https://www.youtube.com/embed/vZAF_vKxgTo" : activeStep === 2 ? "https://www.youtube.com/embed/q3MY3cpFd9s" : ""} />
            <div style={{ padding: 0 }}>
                <p style={{ padding: 0, margin: 0 }}>Support:</p>
                <p style={{ padding: 0, margin: 0, wordWrap: "break-word" }}>Email: support@dataconnector.com</p>
                <p style={{ padding: 0, margin: 0 }}>Phone: 435-299-1131</p>
            </div>
        </Dialog>
    )
}


const Help = ({ videoUrl }) => {
    return (
        <Card raised sx={{ maxWidth: 730, mx: "auto" }}>
            <CardActionArea>
                <CardMedia
                    component="iframe"
                    alt="header"
                    sx={{ height: { xs: 215, sm: 300, md: 400 } }}
                    src={videoUrl}
                />
            </CardActionArea>
        </Card>
    );
};

export default HelpModal;