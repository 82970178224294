import { Table, TableHead, TableBody, TableRow, TableCell, IconButton } from '@mui/material'
import EditPng from '../../../assets/images/user-edit-icon.png'
import DeletePng from '../../../assets/images/delete.png'

// const ControlledCheckbox = ({ value, handleChange }) => {
//   const [checked, setChecked] = useState(false)

//   useEffect(() => {
//     setChecked(value)
//   }, [value])

//   const handleCheck = (event) => {
//     // setChecked(event.target.checked)
//     !event.target.checked && handleChange()
//   }

//   return (
//     <Checkbox
//       color="primary"
//       checked={checked}
//       onChange={handleCheck}
//     />
//   )
// }

export default function ConnectionTable ({ data, onEditConnection, onDeleteConnection, onCheckUncheck }) {
  const getImage = (name) => require(`./../../../assets/images/app-icons/${name}`).default

  return (
    <Table
      sx={{
        '& .MuiTableRow-root': { verticalAlign: 'top' },
        '& .MuiTableCell-root': { fontSize: 12 }
      }}
      size="small"
    >
      <TableHead>
        <TableRow sx={{ textTransform: 'uppercase', '& .MuiTableCell-root': { fontWeight: 600 } }}>
          {/* <TableCell>Enabled</TableCell> */}
          <TableCell sx={{ width: 150 }}>Connector</TableCell>
          <TableCell sx={{ flex: 1 }}>Connection</TableCell>
          <TableCell sx={{ width: 150 }}>Total pipelines</TableCell>
          <TableCell align="center" sx={{ px: 0.6, width: 35 }}>Edit</TableCell>
          <TableCell align="center" sx={{ px: 0.6, width: 55 }}>Delete</TableCell>
        </TableRow>
      </TableHead>
      {Object.keys(data).map(key => (
        <TableBody
          key={key}
          sx={{
            '&::after': { content: '""', display: 'block', height: 20 },
            '& .MuiTableRow-root:last-child': { borderBottom: (theme) => `1px solid ${theme.palette.divider}` }
          }}
        >
          {data[key].map((row, i) => (
            <TableRow key={row.connector_credential_id}>
              {
                i === 0 &&
                <>
                  {/* <TableCell
                    rowSpan={data[key]?.length < 3 ? 3 : data[key]?.length}
                    sx={{ borderBottom: 0, pt: '30px !important' }}
                    padding="checkbox"
                  >
                    <ControlledCheckbox
                      value={!!row.supported_flg}
                      handleChange={() => onCheckUncheck(row)}
                    />
                  </TableCell> */}
                  <TableCell
                    rowSpan={data[key]?.length < 3 ? 3 : data[key]?.length}
                    sx={{ pl: 0, borderBottom: 0 }}
                  >
                    <img
                      style={{ width: 115, maxHeight: 80, borderRadius: 5 }}
                      src={getImage(row.source_image_txt)}
                      alt={row.connector_nm}
                    />
                  </TableCell>
                </>
              }
              <TableCell title={row.connection_nm}>{row.connection_nm}</TableCell>
              <TableCell>{row.pipeline_ct}</TableCell>
              <TableCell align="center" sx={{ px: 0.6 }}>
                <IconButton
                  aria-label="edit"
                  size="small"
                  sx={{ padding: '0' }}
                  onClick={() => onEditConnection(row)}
                >
                  <img alt="edit" src={EditPng} />
                </IconButton>
              </TableCell>
              <TableCell align="center" sx={{ px: 0.6 }}>
                <IconButton
                  aria-label="delete"
                  size="small"
                  sx={{ padding: '0' }}
                  onClick={() => onDeleteConnection(row)}
                >
                  <img alt="delete" src={DeletePng} style={{ width: 15 }} />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}

          {
            data[key]?.length < 3
            && [...Array(3 - data[key].length).keys()].map(val => (
              <TableRow key={val}>
                <TableCell sx={{ borderBottom: 0 }}>&nbsp;</TableCell>
                <TableCell sx={{ borderBottom: 0 }}>&nbsp;</TableCell>
                <TableCell sx={{ borderBottom: 0 }}>&nbsp;</TableCell>
                <TableCell sx={{ borderBottom: 0 }}>&nbsp;</TableCell>
              </TableRow>

            ))
          }
        </TableBody>
      ))}
    </Table>
  )
}
