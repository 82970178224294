import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Grid, Modal, Card, TextField, InputAdornment, IconButton } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles'
import ClearIcon from '@mui/icons-material/Clear'
import { usePipelineContext } from "../pipeline.context";
import SectionDividerComponent from '../../common/section-divider';
import { ConfigureAPIService } from '../../../config/api-service';

export default function ConfigureFiltersComponent ({ jobID }) {
    const {
        sourceState,
        filterObject,
        setFilterObject,
        dateRangeID,
        setDateRangeID,
        startSelectionDR,
        setStartSelectionDR,
        endSelectionDR,
        setEndSelectionDR,
        startCustomDate,
        setStartCustomDate,
        startDaysFromToday,
        setStartDaysFromToday,
        endDaysFromToday,
        setEndDaysFromToday,
        endCustomDate,
        setEndCustomDate

    } = usePipelineContext();
    const [openStartDateModal, setOpenStartDateModal] = useState(false);
    const [openEndDateModal, setOpenEndDateModal] = useState(false);
    const [openEndDayModal, setOpenEndDayModal] = useState(false);
    const [openStartDayModal, setOpenStartDayModal] = useState(false);

    const useStyles = makeStyles((theme) =>
        createStyles({
            selectAdornment: {
                marginRight: theme.spacing(2),
            },
        })
    );

    useEffect(() => {
        if (!jobID && filterObject) {
            let selection = [null, "Today", "Yesterday", "Today Subtract", "Custom Date"];

            let StartSelectionNM = selection[startSelectionDR];
            let EndSelectionNM = selection[endSelectionDR];

            let StartValueTXT = ((startSelectionDR === 3) ? startDaysFromToday : ((startSelectionDR === 4) ? startCustomDate : null));
            let EndValueTXT = ((endSelectionDR === 3) ? endDaysFromToday : ((endSelectionDR === 4) ? endCustomDate : null));

            let payload = {
                FilteredColumnNM: filterObject,
                StartSelectionNM,
                EndSelectionNM,
                StartValueTXT,
                EndValueTXT,
                TimezoneOffsetNBR: new Date().getTimezoneOffset() / 60
            }

            ConfigureAPIService.putETLJobDateRange(dateRangeID, payload)
                .then(res => {
                    setDateRangeID(res.PipelineDateRangeID)
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterObject, startCustomDate, endCustomDate, endDaysFromToday, startDaysFromToday, startSelectionDR, endSelectionDR])

    useEffect(() => {
        if (startSelectionDR === 3) {
            if (startDaysFromToday === 'X')
                setStartDaysFromToday(1);
        }
        if (startSelectionDR === 4) {
            if (startCustomDate === "Custom Date")
                setStartCustomDate(new Date().toISOString().substr(0, 16))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openStartDayModal, openStartDateModal])

    useEffect(() => {
        if (endSelectionDR === 3) {
            if (endDaysFromToday === 'X')
                setEndDaysFromToday(1);
        }
        if (endSelectionDR === 4) {
            if (endCustomDate === "Custom Date")
                setEndCustomDate(new Date().toISOString().substr(0, 16))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [endSelectionDR])

    const handleStartDayModalClose = () => {
        setOpenStartDayModal(false);
    };
    const handleEndDayModalClose = () => {
        setOpenEndDayModal(false);
    };
    const handleStartDateModalClose = () => {

        setOpenStartDateModal(false);
    }
    const handleEndDateModalClose = () => {

        setOpenEndDateModal(false);
    }

    const classes = useStyles()

    return (
        <>
            <SectionDividerComponent label="Date Range Filter" noPadding={false}>
                <FormControl variant="outlined" fullWidth size="small" style={{ marginBottom: 10 }}>
                    <InputLabel id="demo-simple-select-outlined-label">Filtered Column</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={filterObject}
                        onChange={(e) => setFilterObject(e.target.value)}
                        label="Filtered Column"
                        endAdornment={<InputAdornment className={classes.selectAdornment} position="start">
                            <IconButton
                                size="small"
                                onClick={() => setFilterObject('')}
                            >
                                <ClearIcon fontSize="small" />
                            </IconButton>
                        </InputAdornment>}
                    >
                        {!sourceState.fields.length && (<MenuItem value="">No fields have been selected</MenuItem>)}
                        {sourceState.fields.map(row => (
                            <MenuItem key={row.field_id} value={row.field_id}>{row.field_label}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <Grid container spacing={1} >
                    <Grid item xs={6}>
                        <FormControl variant="outlined" fullWidth size="small">
                            <InputLabel id="demo-simple-select-outlined-label">Start</InputLabel>
                            <Select
                                label="Start"
                                value={startSelectionDR}
                                onChange={(e) => {
                                    if (e.target.value === 3) {
                                        setOpenStartDayModal(true);
                                    } else if (e.target.value === 4) {
                                        setOpenStartDateModal(true);
                                    }
                                    setStartSelectionDR(e.target.value)
                                }}
                            >
                                <MenuItem value={0}>None</MenuItem>
                                <MenuItem value={1}>Today</MenuItem>
                                <MenuItem value={2}>Yesterday</MenuItem>
                                <MenuItem value={3}>Today - ({startDaysFromToday}) days</MenuItem>
                                <MenuItem value={4}>{(startCustomDate === 'Custom Date') ? startCustomDate : new Date(startCustomDate).toLocaleString()}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl variant="outlined" fullWidth size="small">
                            <InputLabel id="demo-simple-select-outlined-label">End</InputLabel>
                            <Select
                                label="End"
                                value={endSelectionDR}
                                onChange={(e) => {
                                    if (e.target.value === 3) {
                                        setOpenEndDayModal(true);
                                    } else if (e.target.value === 4) {
                                        setOpenEndDateModal(true);
                                    }
                                    setEndSelectionDR(e.target.value)
                                }}
                            >
                                <MenuItem value={0}>None</MenuItem>
                                <MenuItem value={1}>Today</MenuItem>
                                <MenuItem value={2}>Yesterday</MenuItem>
                                <MenuItem value={3}>Today - ({endDaysFromToday}) days</MenuItem>
                                <MenuItem value={4}>{(endCustomDate === 'Custom Date') ? endCustomDate : new Date(endCustomDate).toLocaleString()}</MenuItem>

                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </SectionDividerComponent>

            <Modal
                open={openStartDateModal}
                onClose={handleStartDateModalClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <Card className="modal">
                    <TextField
                        type="datetime-local"
                        value={startCustomDate}
                        onChange={(e) => setStartCustomDate(e.target.value)}
                    />
                </Card>
            </Modal>
            <Modal
                open={openEndDateModal}
                onClose={handleEndDateModalClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <Card className="modal">
                    <TextField
                        type="datetime-local"
                        value={endCustomDate}
                        onChange={(e) => setEndCustomDate(e.target.value)}
                    />
                </Card>
            </Modal>
            <Modal
                open={openEndDayModal}
                onClose={handleEndDayModalClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <Card className="modal">
                    <TextField
                        label="Days - Today"
                        type="number"
                        value={endDaysFromToday}
                        onChange={(e) => setEndDaysFromToday(e.target.value)}
                    />
                </Card>
            </Modal>
            <Modal
                open={openStartDayModal}
                onClose={handleStartDayModalClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <Card className="modal">
                    <TextField
                        label="Days - Today"
                        type="number"
                        value={startDaysFromToday}
                        onChange={(e) => setStartDaysFromToday(e.target.value)}
                    />
                </Card>
            </Modal>
        </>
    )
}
