import React, { useEffect, useContext, useState } from 'react'
import { Grid, Button, TextField, FormControl, Select, Tooltip } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton'
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify'
import MaterialUITheme from '../../../config/material-ui-theme'
import Dialog from '../../common/dialog'
import ChangePasswordForm from './ChangePasswordForm'
import { UserAPIService } from '../../../config/api-service';
import { CommonLoaderContext } from "../../../config/router";

import { APP_KEY } from '../../../config/constants';

var UsaStates = require('usa-states').UsaStates;
var usStates = new UsaStates();

export default function AccountSettingsComponent () {
    const [user, setUser] = React.useState({});
    const [userError, setUserError] = React.useState({});
    const [saveBtnEnable, setSaveBtnEnable] = React.useState(false);
    const [, setCommonLoader] = useContext(CommonLoaderContext);

    useEffect(() => {
        setCommonLoader(true);
        UserAPIService.getUserDetail()
            .then(res => {
                setUser(res.user);
                setCommonLoader(false);
            }).catch(e => {
                console.error(e)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleFormChange = (e) => {
        let field = e.target.name;
        let value = e.target.value;
        let tempUserData = user;
        tempUserData[field] = value;
        setUser(tempUserData);
        handleFieldWiseValidation(field, value)
    }

    const handleFieldWiseValidation = (field, value) => {
        let tempUserError = { ...userError };
        switch (field) {
            case "FirstNM":
                tempUserError.FirstNM = !value;
                break;
            case "LastNM":
                tempUserError.LastNM = !value;
                break;
            case "EmailTXT":
                tempUserError.EmailTXT = !(/^\w+([.-.+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value));
                break;
            case "PrimaryPhoneNBR":
                tempUserError.PrimaryPhoneNBR = !value;
                break;
            default:
                break;
        }
        setUserError(tempUserError);
    }

    const handleFullValidation = () => {
        let tempUserError = { ...userError };
        tempUserError.FirstNM = !user.FirstNM;
        tempUserError.LastNM = !user.LastNM;
        tempUserError.EmailTXT = !(/^\w+([.-.+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(user.EmailTXT));
        tempUserError.PrimaryPhoneNBR = !user.PrimaryPhoneNBR;
        setUserError(tempUserError);
    }

    const [loading, setLoading] = useState(false)
    const handleSubmitBtn = async () => {
        if (saveBtnEnable) {
            setLoading(true)
            let params = user;
            // need call save api
            try {
                await UserAPIService.update(params.UserID, params)
                handleFullValidation()
                toast.success('Account updated')
                UserAPIService.getUserDetail()
                    .then(res => {
                        localStorage.setItem(APP_KEY, JSON.stringify({ user: res?.user, permissions: res?.permissionList }))
                        if (typeof window !== "undefined") {
                            window.dispatchEvent(new Event("userUpdated"));
                        }
                    }).catch(e => {
                        console.error(e)
                    })
                setLoading(false)
            } catch (error) {
                setLoading(false)
                toast.error("Error")
            }
        } else {
            // need call save api
        }
    }

    // FIXME: Replace this link with the real route to send email
    const hre = "#"


    const handleSaveBtnEnable = () => {
        const { FirstNM, LastNM, PrimaryPhoneNBR, EmailTXT } = user || {}
        setSaveBtnEnable(!!FirstNM && !!LastNM && !!PrimaryPhoneNBR && !!EmailTXT && (/^\w+([.-.+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(EmailTXT)))
    }

    useEffect(() => {
        handleSaveBtnEnable();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.FirstNM, user.LastNM, user.EmailTXT, user.PrimaryPhoneNBR])



    const [changePasswordModal, setChangePasswordModal] = useState(false)
    const handleFormSubmit = async ({ oldPassword, newPassword }) => {
        try {
            const {
                message
            } = await UserAPIService.changePassword({
                currentPassword: oldPassword,
                newPassword
            })
            if (message === "Password changed successfully.") {
                toast.success('Password changed successfully');
                setChangePasswordModal(false);
            }
            else toast.error(message);
        } catch (error) {
        }
    }

    return (
        <MaterialUITheme>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: '20px' }}>
                <div style={{ width: '50%' }}>
                    <div className='accounts-header'>
                        <span>Personal Information</span>
                    </div>

                    <Grid container spacing={2} className='p-20'>
                        <Grid item xs={12} sm={6}>
                            <label className='account-flabel'>First Name<span style={{ color: 'red' }}>*</span></label>
                            <TextField inputProps={{ style: { backgroundColor: '#fff' } }} className="account-ftext" size='small' variant="outlined" fullWidth name="FirstNM" onChange={(e => handleFormChange(e))} value={user.FirstNM} />
                            {!!userError.FirstNM && <p className='account-setting-page-error'> Mandatory field </p>}
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <label className='account-flabel'>Last Name<span style={{ color: 'red' }}>*</span></label>
                            <TextField inputProps={{ style: { backgroundColor: '#fff' } }} className="account-ftext" size='small' variant="outlined" name="LastNM" onChange={(e => handleFormChange(e))} fullWidth value={user.LastNM} />
                            {!!userError.LastNM && <p className='account-setting-page-error'> Mandatory field </p>}
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <div className='account-flabel mail-status-icon'>
                                Email<span style={{ color: 'red' }}>*</span>
                                {user.EmailVerifiedFLG ? <>
                                    <CheckIcon sx={{ pl: 1 }} fontSize='small' color='success' /><small style={{ color: '#2e7d32' }}>Verified</small>
                                </> :
                                    <>
                                        <CloseIcon sx={{ pl: 1 }} fontSize='small' color='error' /><small style={{ color: '#d32f2f' }}>Not Verified </small> <a href={hre} style={{ color: "#aaa", fontSize: '12px' }}>Resend email</a>
                                    </>
                                }
                            </div>
                            <Tooltip title="Can not change Email" arrow>
                                <TextField InputProps={{ readOnly: true }} inputProps={{ style: { backgroundColor: '#fff' } }} className="account-ftext" size='small' variant="outlined" fullWidth name="EmailTXT" onChange={(e => handleFormChange(e))} value={user.EmailTXT} />
                            </Tooltip>
                            
                            {!!userError.EmailTXT && <p className='account-setting-page-error'> Invalid email </p>}
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <label className='account-flabel'>Phone Number<span style={{ color: 'red' }}>*</span></label>
                            <TextField inputProps={{ style: { backgroundColor: '#fff' } }} className="account-ftext" size='small' variant="outlined" fullWidth name="PrimaryPhoneNBR" onChange={(e => handleFormChange(e))} value={user.PrimaryPhoneNBR} />
                            {!!userError.PrimaryPhoneNBR && <p className='account-setting-page-error'> Mandatory field </p>}
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <label className='account-flabel'>Street Address 1</label>
                            <TextField inputProps={{ style: { backgroundColor: '#fff' } }} className="account-ftext" size='small' variant="outlined" fullWidth name="Address01TXT" onChange={(e => handleFormChange(e))} value={user.Address01TXT} />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <label className='account-flabel'>Street Address 2</label>
                            <TextField inputProps={{ style: { backgroundColor: '#fff' } }} className="account-ftext" size='small' variant="outlined" fullWidth name="Address02TXT" onChange={(e => handleFormChange(e))} value={user.Address02TXT} />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <label className='account-flabel'>City</label>
                            <TextField inputProps={{ style: { backgroundColor: '#fff' } }} className="account-ftext" size='small' variant="outlined" fullWidth name="CityNM" onChange={(e => handleFormChange(e))} value={user.CityNM} />
                        </Grid>

                        <Grid item xs={12} sm={8}>
                            <label className='account-flabel'>State</label>
                            <FormControl className="account-ftext" variant="outlined" size="small" fullWidth style={{ minWidth: 175 }}>
                                <Select
                                    inputProps={{ style: { backgroundColor: '#fff' } }}
                                    native
                                    value={user.StateNM}
                                    name="StateNM"
                                    onChange={(e => handleFormChange(e))}
                                    style={{ fontSize: 14 }}
                                >
                                    <option value="">Select a state</option>
                                    {usStates.states.map(stateObj => (
                                        <option key={stateObj.name}>{stateObj.name}</option>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <label className='account-flabel'>Zip Code</label>
                            <TextField inputProps={{ style: { backgroundColor: '#fff' } }} className="account-ftext" size='small' variant="outlined" fullWidth name="ZipCD" onChange={(e => handleFormChange(e))} value={user.ZipCD} />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <label className='account-flabel'>Password
                            </label>
                            <Button onClick={() => setChangePasswordModal(true)} size="small" variant='contained' >Change Password</Button>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <LoadingButton onClick={handleSubmitBtn} loading={loading} disabled={!saveBtnEnable} size="small" variant='contained' className='float-right'>Save Changes</LoadingButton>
                        </Grid>
                    </Grid>

                    <Dialog
                        open={changePasswordModal}
                        handleClose={() => setChangePasswordModal(false)}
                        title="Change Password"
                        centerAligned={false}
                    >
                        <ChangePasswordForm handleCancel={() => setChangePasswordModal(false)} handleFormSubmit={handleFormSubmit} />
                    </Dialog>
                </div>
            </div>
        </MaterialUITheme>
    )
}
