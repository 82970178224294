import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Box, TextField, Button } from '@mui/material'
import ConnectionTable from './ConnectionTable'

export default function Connection ({ label, data, onEditConnection, onDeleteConnection, onCheckUncheck }) {
  const [connections, setConnections] = useState([])
  const [groupedConnections, setGroupedConnections] = useState([])

  useEffect(() => {
    setConnections(data)
  }, [data])

  useEffect(() => {
    setGroupedConnections(groupBy(connections, 'connector_id'))
  }, [connections])

  const groupBy = (array, key) => array.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [
        ...(result[item[key]] || []),
        item
      ]
    }),
    {}
  )

  const [searchValue, setSearchValue] = useState('')
  const handleSearch = (e) => {
    const val = e.target.value.toLowerCase()
    setSearchValue(val)
    if (!val) {
      setConnections(data)
      return
    }
    const filteredResults = data.filter(({ connection_nm }) => connection_nm.toLowerCase().includes(val))
    setConnections(filteredResults)
  }

  return (
    <Box sx={{ width: 1, display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ width: 1, display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-between' }}>
        <TextField placeholder={"Search " + label} size="small" variant="outlined" value={searchValue} onChange={handleSearch} />
        <Button
          component={Link}
          to="/pipelines/new"
          color="primary"
          variant="contained"
          disableElevation
          sx={{ fontWeight: 600 }}
        >
          + Connection
        </Button>
      </Box>

      <Box className="horizontal-timeline" sx={{ maxWidth: 1, mt: 0.25, textTransform: 'uppercase' }}>{label}</Box>

      <ConnectionTable data={groupedConnections} onEditConnection={onEditConnection} onDeleteConnection={onDeleteConnection} onCheckUncheck={onCheckUncheck} />
    </Box>
  )
}
