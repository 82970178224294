import React, { useEffect, useContext } from 'react'
import { Button, TextField, FormControl, Select } from '@mui/material';
import Grid from '@mui/material/Grid';
import { toast } from 'react-toastify'
import { OrganizationAPIService } from '../../../config/api-service';
import { CommonLoaderContext } from "../../../config/router";
import momentTZ from 'moment-timezone';

import { ORGANIZATIONS } from '../../../config/constants';


export default function OrganizationSettingsComponent () {
    const [userList, setUserList] = React.useState([]);
    const [organizationInfo, setOrganizationInfo] = React.useState([]);
    const [organization, setOrganization] = React.useState({});
    const [organizationError, setOrganizationError] = React.useState(false);
    const [organizationExist, setOrganizationExist] = React.useState(false);
    const [timezoneTXTError, setTimezoneTXTError] = React.useState(false);
    const [mainContactUserIDError, setMainContactUserIDError] = React.useState(false);
    const [submitBtnEnable, setSubmitBtnEnable] = React.useState(false);
    const [, setCommonLoader] = useContext(CommonLoaderContext);

    useEffect(() => {
        setCommonLoader(true);
        OrganizationAPIService.getOrganizationDetail()
            .then(res => {
                setOrganizationInfo(res.organization);
                setOrganization(res.organization);
            }).catch(e => {
                console.error("fail to load user list")
            })

        OrganizationAPIService.getAllOrganizationUsers()
            .then(resp => {
                // resp = resp.map(r => (
                //     r['FullName'] = r.FirstNM + " " + r.LastNM
                // ));
                setUserList(resp);
                setCommonLoader(false);
            }).catch(e => {
                console.error("fail to load organization detail")
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handelChange = async (e) => {
        let fieldName = e.target.name;
        let value = e.target.value;
        // FIXME:
        // eslint-disable-next-line
        let temp = !!organization && { ...organization } || {}
        temp[fieldName] = value;
        setOrganization(temp)
        checkFieldWiseValidation(fieldName, value);
    }

    const checkFieldWiseValidation = (fieldName, value) => {
        switch (fieldName) {
            case "OrganizationNM":
                setOrganizationError(!!!value);
                break;
            case "MainContactUserID":
                setMainContactUserIDError(!!!value);
                break;
            case "TimezoneTXT":
                setTimezoneTXTError(!!!value);
                break;
            default:
                break;
        }
    }

    const checkFullValidation = () => {
        setOrganizationError(!(!!organization && !!organization.OrganizationNM));
        setMainContactUserIDError(!(!!organization && !!organization.MainContactUserID));
        setTimezoneTXTError(!(!!organization && !!organization.TimezoneTXT));

    }


    const checkButtonDisable = () => {
        const { OrganizationNM, TimezoneTXT, MainContactUserID } = organization || {}
        setSubmitBtnEnable(!(!!OrganizationNM && !!TimezoneTXT && !!MainContactUserID && !organizationExist))
    }

    const handelSaveBtn = async () => {
        if (!submitBtnEnable) {
            try {
                await OrganizationAPIService.updateOrganizationDetail(organization)
                toast.success('Organization updated')
                const { organizationList = [] } = await OrganizationAPIService.getOrganizations()
                const localStorageData = JSON.parse(localStorage.getItem(ORGANIZATIONS) || '{}')
                localStorage.setItem(ORGANIZATIONS, JSON.stringify({ ...localStorageData, organizations: organizationList }))
                if (typeof window !== "undefined") {
                    window.dispatchEvent(new Event("organizationUpdated"));
                }
            } catch (error) {
                const { response: { data } } = error
                toast.error(data.message)
            }

        } else {
            checkFullValidation();
        }
    }

    useEffect(() => {
        checkButtonDisable();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organization.OrganizationNM, organization.TimezoneTXT, organization.MainContactUserID, organizationExist])



    const checkOrganizationAvailability = async (e) => {
        if (organizationInfo.OrganizationNM !== e.target.value) {
            try {
                const availability = await OrganizationAPIService.checkOrganizationAvailability(organization.OrganizationNM)

                if (availability) setOrganizationExist(true)
                else setOrganizationExist(false)
            } catch (error) {
            }
        }

    }


    const timeZoneList = momentTZ.tz.names();

    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: '20px' }}>
            <div style={{ width: '50%' }}>
                <div className='organization-header'>
                    <span>Organization Information</span>
                </div>

                <Grid container spacing={2} className='p-20'>
                    <Grid item xs={12} sm={12}>
                        <div className='organization-flabel'>Organization Name</div>
                        <TextField
                            inputProps={{ style: { backgroundColor: '#fff' } }}
                            className="organization-ftext"
                            size='small'
                            error={organizationError}
                            variant="outlined"
                            fullWidth
                            name="OrganizationNM"
                            value={organization.OrganizationNM || ''}
                            onChange={handelChange}
                            onBlur={checkOrganizationAvailability}
                        />
                        {!!organizationError && <p className='organization-field-error'> Organization Name is required  </p>}
                        {!!organizationExist && <p className='organization-field-error'> Organization Name already in use  </p>}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <div className='organization-flabel'>Email</div>
                        <FormControl
                            className="organization-ftext"
                            variant="outlined"
                            size="small"
                            fullWidth
                            style={{ minWidth: 175, marginLeft: 3 }}
                        >
                            <Select
                                inputProps={{ style: { backgroundColor: '#fff' } }}
                                native
                                value={organization?.MainContactUserID || ''}
                                style={{ fontSize: 14 }}
                                name="MainContactUserID"
                                onChange={handelChange}
                            >
                                <option value="">Select Email</option>
                                {userList.map((u, i) => <option value={u?.UserID || ''} key={i}>{u?.EmailTXT}</option>)}
                            </Select>
                        </FormControl>
                        {!!mainContactUserIDError && <p className='organization-field-error'> Contact User is required  </p>}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <div className='organization-flabel'>Time Zone
                        </div>
                        <FormControl
                            className="organization-ftext"
                            variant="outlined"
                            size="small"
                            fullWidth
                            style={{ minWidth: 175, marginLeft: 3 }}
                        >
                            <Select
                                inputProps={{ style: { backgroundColor: '#fff' } }}
                                native
                                value={organization.TimezoneTXT}
                                name="TimezoneTXT"
                                onChange={handelChange}
                                style={{ fontSize: 14 }}
                            >
                                <option value="">Select a Time Zone</option>
                                {timeZoneList.map(timeZone => (
                                    <option key={timeZone}>{timeZone}</option>
                                ))}
                            </Select>
                        </FormControl>
                        {!!timezoneTXTError && <p className='organization-field-error'> Select a Time zone is required  </p>}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Button
                            size="small"
                            variant='contained'
                            onClick={handelSaveBtn}
                            className='float-right'
                            disabled={submitBtnEnable}
                        >Save Changes</Button>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}
