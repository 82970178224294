import React, {useEffect, useState} from 'react';

export default function FilteredSearchBar({objects, setFiltered, placeholder, filterParams}) {
    const [searchStr, setSearchStr] = useState("");

    useEffect(() => {

        //Filtering to include any icons whose fields contain the search text
        if(searchStr !== "") {
            let filtered = objects;
            if (filterParams) {
                for (let param in filterParams) {
                    filtered = objects.filter((obj) => {
                        return obj[param].toLowerCase().includes(searchStr.toLowerCase())
                    });
                }
            } else {
                filtered = objects.filter((obj) => {
                    return obj.toLowerCase().includes(searchStr.toLowerCase());
                });
            }
            setFiltered(filtered);
        } else {
            setFiltered(objects);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchStr]);

    return (
        <input
            className="input-no-outline"
            type="text"
            onChange={({target}) => setSearchStr(target.value)}
            placeholder={placeholder}
        />
    )
}