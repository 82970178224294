import { useState, createContext, useEffect } from 'react'
import { ORGANIZATIONS } from '../config/constants'

export const OrganizationContext = createContext()

export default function OrganizationProvider ({ children }) {
  const [isActiveUser, setIsActiveUser] = useState(true)

  const checkUserActiveStatus = () => {
    const { selectedOrganization, organizations } = JSON.parse(localStorage.getItem(ORGANIZATIONS) || '{}')
    if (selectedOrganization) {
      const sectectedOrg = organizations.find(org => org.organization_id === selectedOrganization)
      setIsActiveUser(!!sectectedOrg?.active_flg)
    }
  }

  useEffect(() => {
    checkUserActiveStatus()
  }, [])

  return (
    <OrganizationContext.Provider
      value={{
        isActiveUser,
        setIsActiveUser,
        checkUserActiveStatus
      }}
    >
      {children}
    </OrganizationContext.Provider>
  )
}