import React, { useState } from 'react';
import { Box, Button, Checkbox, FormGroup, FormControlLabel } from '@mui/material'
import LockIcon from '@material-ui/icons/Lock';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { toast } from 'react-toastify';
import { UserAPIService } from '../../../config/api-service';
import { useHistory } from 'react-router-dom'

const CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
        base: {
            iconColor: "#c4f0ff",
            color: "#000",
            fontWeight: 500,
            fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
            fontSize: "16px",
            fontSmoothing: "antialiased",
            ":-webkit-autofill": { color: "#fff" },
            "::placeholder": { color: "#aaa" }
        },
        invalid: {
            iconColor: "#ffc7ee",
            color: "#ffc7ee"
        }
    }
}

const PaymentForm = ({ user, onSuccess }) => {
    const stripe = useStripe()
    const elements = useElements()
    const [preferred, setPreferred] = useState(false)
    const history = useHistory()

    const handleSubmit = async (event) => {
        // event.preventDefault();
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement),
            billing_details: {
                email: user.EmailTXT
            },
        });

        // If that was successful
        if (!error) {
            // Send payment method id along with email to backend to create a stripe customer
            try {
                const { id } = paymentMethod;
                const response = await UserAPIService.createPaymentMethod({
                    id,
                    EmailTXT: user.EmailTXT,
                    paymentMethodId: paymentMethod.id,
                    preferred
                })

                if (response?.success) {
                    onSuccess()
                    toast.success('Payment method added')
                    setTimeout(() => {
                        history.push('/pipelines')
                    }, 6000);
                } else {
                    toast.error('Error')
                }
            } catch (err) {
                toast.error('Error')
            }
        } else {
            toast.error('Error')
        }
    };
    return (
        <div>
            <div className='lock-open-icon-wrap'>
                <LockOutlinedIcon />
            </div>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', fontSize: 12, pb: .5 }}>
                <span className='size-12' style={{ color: "#7a7a7a" }}>Secure Server</span>
                <LockIcon style={{ fill: '#FFB53F', fontSize: 15, marginLeft: 3 }} />
            </Box>
            <Box component="form">
                <fieldset style={{ border: '2px solid #00bfff', borderRadius: 5 }}>
                    <CardElement options={CARD_OPTIONS} />
                </fieldset>

                <FormGroup row>
                    <FormControlLabel
                        sx={{ ml: 0.25 }}
                        control={<Checkbox value={preferred} onChange={(e) => setPreferred(e.target.checked)} />}
                        label="Make preferred"
                        labelPlacement="start"
                    />
                </FormGroup>
            </Box>
            <Button fullWidth variant="contained" onClick={handleSubmit}>Continue Membership</Button>
        </div>
    );
};

export default PaymentForm;