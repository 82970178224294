import { Grid, TextField, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { UserAPIService } from '../../../config/api-service';
import { APP_KEY, DAYS, MONTHS, PERIOD_DROPDOWN } from '../../../config/constants';
import SectionDivider from '../../common/section-divider';
import SelectComponent from '../../common/select2';
import { usePipelineContext } from "../pipeline.context";
import Register from '../../navigation/Register';

export default function ConfigureScheduleComponent({ timezone = '' }) {
    const { runPeriod, setRunPeriod,
        minute, setMinute,
        time, setTime,
        weekDay, setWeekDay,
        monthDay, setMonthDay,
        month, setMonth, scheduleEnabled, setScheduleEnabled } = usePipelineContext();

    const [userInfo, setUserInfo] = useState({});
    const [openRegisterModal, setOpenRegisterModal] = useState(false);

    useEffect(() => {
        const localStorageData = localStorage.getItem(APP_KEY);
        if (localStorageData) {
            setUserInfo(JSON.parse(localStorageData));
        } else {
            const getUserDetails = async () => {
                const { user, permissionList } = await UserAPIService.getUserDetail();
                setUserInfo({ user, permissions: permissionList });
            }
            getUserDetails();
        }

        window.addEventListener('anonymousUserEventChange', () => {
            const localStorageUserData = localStorage.getItem(APP_KEY);
            if (localStorageUserData) {
                setUserInfo(JSON.parse(localStorageUserData));
            }
        })
    }, []);

    const handleCheck = (e) => {
        if (userInfo?.user?.UserAnonymousFlg) {
            setOpenRegisterModal(true);
        }
        else {
            setScheduleEnabled(e.target.checked);
        }
    };

    const getTimePeriod = (runPeriod) => {
        switch (runPeriod) {
            case 0: // Hourly Selection
                return (
                    <Grid item xs={6}>
                        {minuteField()}
                    </Grid>
                )
            case 1: // Daily Selection
                return (
                    <Grid item xs={6}>
                        {timeField()}
                    </Grid>
                )
            case 2: // Every Weekday Selection
                return (
                    <Grid item xs={6}>
                        {timeField()}
                    </Grid>
                )
            case 3: // Weekly Selection
                return (<>
                    <Grid item xs={6}>
                        {weekdayField()}
                    </Grid>
                    <Grid item xs={6}>
                        {timeField()}
                    </Grid>
                </>)
            case 4: // Monthly Selection
                return (<>
                    <Grid item xs={6}>
                        {monthDayField()}
                    </Grid>
                    <Grid item xs={6}>
                        {timeField()}
                    </Grid>
                </>)
            case 5: // Yearly Selection
                return (<>
                    <Grid item xs={4}>
                        {monthField()}
                    </Grid>
                    <Grid item xs={4}>
                        {monthDayField()}
                    </Grid>
                    <Grid item xs={4}>
                        {timeField()}
                    </Grid>
                </>)
            case 6: // Manual Selection
                return (<>
                    <Grid item xs={6}>
                        {manualField()}
                    </Grid>
                </>)
            default:
                return (<>

                </>)
        }
    }

    const minuteField = () => {
        return (<>
            <TextField
                variant="outlined"
                size="small"
                type="number"
                value={minute}
                onChange={(e) => setMinute(e.target.value)}
                fullWidth
                label="Minute"
                disabled={!scheduleEnabled}
            />
        </>)
    }

    const timeField = () => {
        return (<>
            <TextField
                variant="outlined"
                size="small"
                type="time"
                value={time}
                onChange={(e) => setTime(e.target.value)}
                fullWidth
                label="Time"
                disabled={!scheduleEnabled}
            />
        </>)
    }

    const weekdayField = () => {
        return (<>
            <SelectComponent
                value={weekDay}
                setValue={setWeekDay}
                items={DAYS}
                label="Weekday"
                disabled={!scheduleEnabled}
            />
        </>)
    }

    const monthField = () => {
        return (<>
            <SelectComponent
                value={month}
                setValue={setMonth}
                items={MONTHS}
                InputProps={{ inputProps: { min: 1, max: 12 } }}
                label="Month"
                disabled={!scheduleEnabled}
            />
        </>)
    }

    const monthDayField = () => {
        return (<>
            <TextField
                variant="outlined"
                size="small"
                type="number"
                value={monthDay}
                InputProps={{ inputProps: { min: 1, max: 31 } }}
                onChange={(e) => setMonthDay(e.target.value)}
                fullWidth
                label="Day of Month"
                disabled={!scheduleEnabled}
            />
        </>)
    }

    const manualField = () => {
        return (<>
            <TextField
                variant="outlined"
                size="small"
                type="number"
                defaultValue={1}
                // value={monthDay}
                // onChange={(e) => setMonthDay(e.target.value)}
                InputProps={{ inputProps: { min: 1, max: 31 } }}
                fullWidth
                label="Manual"
                disabled={!scheduleEnabled}
            />
        </>)
    }

    return (
        <>
            <SectionDivider label="Pipeline Run Schedule" checkbox={true} tooltip={userInfo?.user?.UserAnonymousFlg ? "Register to enable this feature" : ""} enabled={scheduleEnabled} checked={handleCheck}>
                <Grid container spacing={1} style={{ marginBottom: 3 }}>
                    <Grid item xs={6}>
                        <SelectComponent
                            value={runPeriod}
                            setValue={setRunPeriod}
                            items={PERIOD_DROPDOWN}
                            label="Period"
                            disabled={!scheduleEnabled}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Tooltip title="To edit the timezone you must update the timezone in the Organization settings page">
                            <div>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    value={timezone}
                                    fullWidth
                                    label="Timezone"
                                    disabled
                                />
                            </div>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            {getTimePeriod(runPeriod)}
                        </Grid>
                    </Grid>
                </Grid>
                {
                    openRegisterModal && <Register openRegisterModal={openRegisterModal} setOpenRegisterModal={setOpenRegisterModal} />
                }
            </SectionDivider>
        </>
    )
}
