import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { usePipelineContext } from "../pipeline.context";

export default function ConfigureGeneralComponent () {
    const { method, setMethod } = usePipelineContext();
    return (
        <>
            <FormControl variant="outlined" fullWidth size="small">
                <InputLabel id="demo-simple-select-outlined-label">Update Method</InputLabel>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={method}
                    onChange={(e) => setMethod(e.target.value)}
                    label="Update Method"
                >
                    <MenuItem value={1}>Append</MenuItem>
                    <MenuItem value={2}>Replace</MenuItem>
                </Select>
            </FormControl>
        </>
    )
}