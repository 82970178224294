import { useEffect, useState } from 'react'
import { Card, Box, CircularProgress, Typography, Button } from '@mui/material'
import MaterialUITheme from '../../config/material-ui-theme'
import SelectOrganization from './SelectOrganization'
import { OrganizationAPIService, UserAPIService } from '../../config/api-service'
import { APP_KEY, ORGANIZATIONS } from '../../config/constants'
import DataConnectorImage from '../../assets/images/dataconnector_main.png'
import './style.css'
import useScript from '../../config/useScript'

export default function SelectOrgForMarketing() {
  const [organizations, setOrganizations] = useState([])
  const [user, setUser] = useState({})
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(true)
  const { REACT_APP_MARKETING_SITE = '//' } = process.env


  const createOrganization = () => {
    // const { user } = JSON.parse(localStorage.getItem(APP_KEY))
    UserAPIService.addNewUserOrganization({ UserID: user.UserID, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone })
      .then(res => {
        setOrganizations(res.organizationList)
      })
      .catch(err => { })
  }

  useEffect(() => {
    localStorage.removeItem(APP_KEY)
    localStorage.removeItem(ORGANIZATIONS)

    const getOrgs = async () => {
      setLoading(true)
      try {
        const { organizationList, user } = await OrganizationAPIService.getOrganizations()
        setOrganizations(organizationList)
        setUser(user)
        setEmail(user?.EmailTXT)
        setLoading(false)
      } catch {
        setLoading(false)
      }
    }
    getOrgs()
  }, [])

  useScript()

  return (
    <MaterialUITheme>
      {loading ? <div style={{ alignItems: "center", display: "flex", justifyContent: "center", height: "100vh", width: "100vw" }}>
        <CircularProgress />
      </div> : <Box>
        {organizations?.length === 1 ? <Box>
          <SelectOrganization organizations={organizations} user={user} />
        </Box> : <Card raised={true} className='login'>
          <div style={{ textAlign: 'center' }}>
            <img src={DataConnectorImage} alt="Logo" style={{ maxWidth: 150 }} />
          </div>

              {!!organizations?.length && <h2 className="loginLabel">Select Organization</h2>}

              <Box component={'form'} noValidate autoComplete="off">
                {
                  loading
                    ? <Box sx={{ display: 'flex', justifyContent: 'center', height: 116.5, alignItems: 'center' }}>
                      <CircularProgress size={32} />
                    </Box>
                    : organizations?.length
                      ? <SelectOrganization organizations={organizations} user={user} />
                      : <>
                        <Typography component={'div'} mt={2} fontSize="1.2rem" textAlign="center">
                          Hi, {email},<br />welcome back to Data Connector!
                        </Typography>
                        <Typography component={'div'} mt={2} fontSize="1.2rem" textAlign="center">
                          Looks like your email is not tied to an active<br />account. Please click below to start<br />your 14-Day free trial!
                        </Typography>
                        <Typography component={'div'} mt={3} mb={4} fontSize="1.2rem" textAlign="center">
                          If you need help, please reach out to us at<br />support@dataconnector.com
                        </Typography>
                        <Button fullWidth variant="contained" onClick={createOrganization}>Create new account</Button>
                      </>
                }
              </Box>
        </Card>}
      </Box>}

    </MaterialUITheme>
  )
}
