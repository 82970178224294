import React from 'react';
import { createTheme, ThemeProvider} from '@mui/material/styles'

export default function MaterialProvider({children}) {
    const theme = createTheme({
        palette: {
            primary: {
                main: '#00bfff',
                contrastText: '#fff'
            },
            secondary: {
                main: '#00bfff',
            },
        },
        typography: {
            fontSize: 14,
        }
    });

    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    );
}
