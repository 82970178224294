import {useState} from 'react'
import { Box, Button, InputBase, List, ListItemButton, ListItemAvatar, ListItemText, Typography, Skeleton } from '@mui/material'

export default function SideBar ({ width, navs, onClickItem, onClickNewFeature }) {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const handleListItemClick = (index) => {
    setSelectedIndex(index)
  }

  return (
    <Box sx={{
      width: width,
      p: (theme) => theme.spacing(3),
      backgroundColor: '#ECECEC',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      position: 'sticky',
      left: 0,
      top: 0,
      bottom: 0
    }}>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        size="small"
        sx={{ mb: 2, textTransform: 'capitalize' }}
        onClick={onClickNewFeature}
      >
        New Feature Request
      </Button>
      <InputBase
        sx={{ backgroundColor: '#D9D9DB', width: 1, px: 1.5, borderRadius: 1, mb: 1 }}
        placeholder="Search..."
        inputProps={{ 'aria-label': 'search...' }}
      />

      {
        navs?.length
          ? <List sx={{ width: 1, p: 0 }}>
            <ListItemButton dense selected={selectedIndex === 0} onClick={() => {onClickItem(''); handleListItemClick(0)}}>
              <ListItemAvatar sx={{ minWidth: 0, pr: 1 }}>
                <Box sx={{ height: 5, width: 5, backgroundColor: `#5C7490`, borderRadius: '50%' }} />
              </ListItemAvatar>
              <ListItemText primary={'All Categories'} />
            </ListItemButton>
            {navs.map(({ FeatureTypeNM, FeatureTypeID, FeatureColorHEX }) => (
              <ListItemButton key={FeatureTypeID} selected={selectedIndex === FeatureTypeID} dense onClick={() => {onClickItem(FeatureTypeID); handleListItemClick(FeatureTypeID)}}>
                <ListItemAvatar sx={{ minWidth: 0, pr: 1 }}>
                  <Box sx={{ height: 5, width: 5, backgroundColor: `#${FeatureColorHEX}`, borderRadius: '50%' }} />
                </ListItemAvatar>
                <ListItemText primary={FeatureTypeNM} />
              </ListItemButton>
            ))}
          </List>
          : <Box>
            <Skeleton variant="text" sx={{ fontSize: '30px' }} />
            <Skeleton variant="text" sx={{ fontSize: '30px' }} />
            <Skeleton variant="text" sx={{ fontSize: '30px' }} />
            <Skeleton variant="text" sx={{ fontSize: '30px' }} />
            <Skeleton variant="text" sx={{ fontSize: '30px' }} />
          </Box>
      }


      <Box sx={{ flex: 1 }} />
      <Box>
        <Typography fontWeight={600} mb={1}>Support:</Typography>
        <Typography>435-299-1131</Typography>
        <Typography>support@dataconnector.com</Typography>
      </Box>
    </Box>
  )
}
