import { useEffect, useState, useContext } from "react"
import { format } from 'date-fns'
import { Box, Typography } from "@mui/material"
import Dialog from '../common/dialog'
import FeatureReviewForm from './FeatureReviewForm'
import FRTable from './table-components/FRTable'
import { FeatureRequestAPIService } from '../../config/api-service'
import { CommonLoaderContext } from "../../config/router"

export default function FeatureRequest ({ featureTypes, FRKey, featureTypeId }) {
    const [, setCommonLoader] = useContext(CommonLoaderContext)
  const [FRList, setFRList] = useState([])
  const getFeatureRequests = async (id = '') => {
    const data = await FeatureRequestAPIService.getFeatureRequests(id)
    setFRList(data)
  }

  const syncFRList = async (id = '') => {
    setCommonLoader(true)
    await getFeatureRequests(id)
    setCommonLoader(false)
  }

  useEffect(() => {
    syncFRList()
  }, [FRKey])

  useEffect(() => {
    if (featureTypeId !== 0) syncFRList(featureTypeId)
  }, [featureTypeId])

  // Using useEffect to avoid multiple api calls
  const [voteProps, setVoteProps] = useState(null)
  const handleVoting = (voteProps) => {
    setVoteProps(voteProps)
  }
  useEffect(() => {
    if (voteProps) {
      FeatureRequestAPIService.postVote({
      id: voteProps.id,
      data: { VoteForFLG: voteProps.upVote ? 1 : voteProps.downVote ? -1 : 0 }
    })
    }
  }, [voteProps])

  const [reviewData, setReviewData] = useState(false)
  const handleSubmitFeatureReview = async (values) => {
    const data = {
      FeatureRequestNM: values.featureName,
      FeatureRequestDSC: values.featureDescription,
      FeatureTypeID: values.featureType,
      FeatureRequestFeedbackTXT: values.feedBack,
      OrganizationNM: values.organization,
      FeatureAcceptedFLG: values.approve ? 1 : 0,
      DisplayFLG: values.display ? 1 : 0,
      ReviewedFLG: 1,
      FeatureImplementationDTS: values.projectedDate && format(new Date(values.projectedDate), 'yyyy-MM-dd hh:mm aa')
    }
    await FeatureRequestAPIService.reviewFeatureRequest({ id: reviewData?.feature_request_id, data })
    setReviewData(null)
    getFeatureRequests()
  }

  return (
    <Box>
      <Typography variant="h4" textAlign="center" mb={4}>Feature Request</Typography>
      <FRTable rows={FRList} handleVoting={handleVoting} onClickReviewButton={(data) => setReviewData(data)} />

      <Dialog
        open={reviewData}
        handleClose={() => setReviewData(null)}
        title="Review a Feature"
        centerAligned={false}
        maxWidth="md"
      >
        <FeatureReviewForm data={reviewData} featureTypes={featureTypes} handleSubmitFeatureReview={handleSubmitFeatureReview} />
      </Dialog>
    </Box>
  )
}