import { useEffect, useState, useContext } from 'react'
import { Box, Typography, Divider, FormControlLabel, Checkbox } from '@mui/material'
import MaterialUITheme from '../../../config/material-ui-theme'
import { CommonLoaderContext } from "../../../config/router"
import { toast } from 'react-toastify'
import { AccountAPIService } from '../../../config/api-service'



const checkList = [
	{
		field: "MarketingFLG",
		label: "Marketing (discounts, savings, no spam)",
	},
	{
		field: "SupportFLG",
		label: "Support",
	},
	// {
	//   field: 'PipelineFailureFLG',
	//   label: 'Pipeline Failure'
	// },
	// {
	//   field: 'PipelineSuccessFLG',
	//   label: 'Pipeline Success'
	// },
	{
		field: "PipelineRunNotificationFLG",
		label: "Pipeline Run Notification",
	},
	{
		field: "AccountNotificationFLG",
		label: "Account Notification",
	},
	{
		field: "NewsletterFLG",
		label: "Newsletter (best practices, new connectors, announcement, etc)",
	},
];

export default function EmailSettings({ headerDisplay = "block" }) {
  const [, setCommonLoader] = useContext(CommonLoaderContext)
  const [preferences, setPreferences] = useState({})

  const getPreferences = async () => {
    setCommonLoader(true)
    const obj = await AccountAPIService.getEmailPreference()
    setCommonLoader(false)
    if (obj && Object.keys(obj).length) {
      setPreferences(obj)
    }
  }

  useEffect(() => {
    getPreferences();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  const handleOnChange = async (field, label, e) => {
    const isChecked = e.target.checked
    setPreferences({
      ...preferences,
      [field]: isChecked ? 1 : 0
    })

    setCommonLoader(true)
    try {
      await AccountAPIService.updateEmailPreference({
        ...preferences,
        [field]: isChecked ? 1 : 0
      })
      getPreferences()
      toast.success(`${isChecked ? 'Subscribed to' : 'Unsubscribed from'} ${label}`)
    } catch {
      setCommonLoader(false)
      toast.error('Something went wrong')
    }
  }

  return (
    <MaterialUITheme>
      <Box
        sx={{
          minWidth: 500,
          width: '80%',
          maxWidth: '100%',
          margin: '5vh auto',
          '& .MuiFormControlLabel-root': { width: '100%', mb: 2 },
          '& .MuiSvgIcon-root': { fontSize: 28 },
          '& .MuiFormControlLabel-label': { fontSize: 24 }
        }}
      >
        <Typography component={'h2'} variant="h3" sx={{ textAlign: 'center', mb: 1 }} display={headerDisplay}>
          Email Settings
        </Typography>

        <Divider sx={{ mb: 2, display: headerDisplay }} />

        {
          checkList.map(({ field, label }, i) => (
            <FormControlLabel key={field} control={<Checkbox checked={!!preferences[field]} onChange={(e) => handleOnChange(field, label, e)} />} label={label} />
          ))
        }

      </Box>
    </MaterialUITheme>
  )
}