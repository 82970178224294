import {useState, useEffect} from 'react'
import { parseJSON, format } from 'date-fns'
import { TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Checkbox, Button } from "@mui/material"
import UpDownVote from './UpDownVote'
import { APP_KEY } from '../../../config/constants'

export default function FRTable ({ rows, handleVoting, onClickReviewButton }) {
  const [userInfo, setUserInfo] = useState({})
    useEffect(() => {
        const localStorageData = localStorage.getItem(APP_KEY)
        if (localStorageData) {
            setUserInfo(JSON.parse(localStorageData))
            
        }
    }, [])

  return (
    <TableContainer sx={{ borderRadius: 0, maxHeight: 'calc(100vh - 155px)' }}>
    <Table
      sx={{
        minWidth: 650,
        '& .MuiTableCell-root': {
          fontSize: '1rem'
        }
      }}
      stickyHeader
    >
      <TableHead
        sx={{
          '& .MuiTableCell-root': {
            fontWeight: 600,
            textTransform: 'uppercase'
          }
        }}>
        <TableRow>
          <TableCell sx={{ width: 50 }}>Vote</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Description</TableCell>
          <TableCell sx={{ width: 150 }}>Type</TableCell>
          <TableCell sx={{ width: 80 }}>Accepted</TableCell>
          <TableCell sx={{ width: 150 }}>Projected Date</TableCell>
          {
            !!userInfo?.user?.DataconnectorAdminFLG
            && <>
              <TableCell sx={{ width: 60 }}>Display</TableCell>
              <TableCell sx={{ width: 50 }}>Review</TableCell>
              <TableCell sx={{ width: 50 }}>Action</TableCell>
            </>
          }
          
        </TableRow>
      </TableHead>
      <TableBody sx={{ '& .MuiTableCell-root': { borderBottom: 0 } }}>
        {rows.map((row) => (
          <TableRow key={row.feature_request_id}>
            <TableCell>
              <UpDownVote
                voteCount={row.total_vote_for_flg}
                state={row.vote_for_flg}
                handleVoting={(data) => handleVoting({ id: row.feature_request_id, ...data })}
              />
            </TableCell>
            <TableCell>{row.feature_request_nm}</TableCell>
            <TableCell>{row.feature_request_dsc}</TableCell>
            <TableCell>{row.feature_type_nm}</TableCell>
            <TableCell align="center">
              <Checkbox disabled checked={!!row.feature_accepted_flg} />
            </TableCell>
            <TableCell>{row.feature_implementation_dts && format(parseJSON(row.feature_implementation_dts), 'MM/dd/yyyy')}</TableCell>
            {
              !!userInfo?.user?.DataconnectorAdminFLG
              && <>
                <TableCell align="center">
                  <Checkbox disabled checked={!!row.display_flg} />
                </TableCell>
                <TableCell align="center">
                  <Checkbox disabled checked={!!row.reviewed_flg} />
                </TableCell>
                <TableCell>
                  <Button onClick={() => onClickReviewButton(row)}>Review</Button>
                </TableCell>
              </>
            }
            
          </TableRow>
        ))}
      </TableBody>
    </Table>
    </TableContainer>
  )
}