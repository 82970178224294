import { useContext, useEffect, useState } from 'react'
import { format } from 'date-fns'
import { CSVLink } from 'react-csv'
import { Box, Typography, MenuItem, Divider, Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, ListItemText, Checkbox, OutlinedInput, Select, InputLabel, FormControl } from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import MaterialUITheme from '../../../config/material-ui-theme'
import { OrganizationAPIService, UserAPIService, PipelineAPIService, ConnectorAPIService, GlobalAPIService } from '../../../config/api-service'
import { CommonLoaderContext } from "../../../config/router"



const columns = [
  { id: 'log_dts', label: 'DATE' },
  { id: 'event_type_dsc', label: 'EVENT TYPE' },
  { id: 'log_type_dsc', label: 'LOG TYPE' },
  { id: 'pipeline_nm', label: 'PIPELINE' },
  { id: 'connector_nm', label: 'CONNECTOR' },
  { id: 'pipeline_stage_nm', label: 'PIPELINE STAGE' },
  { id: 'email_txt', label: 'USER' },
  { id: 'log_txt', label: 'LOG' }
]

function descendingComparator (a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator (order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function EnhancedTableHead ({ order, orderBy, onRequestSort }) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead sx={{
      '& .MuiTableCell-root.table-header-cell': { backgroundColor: 'var(--data-con-blue)', color: '#fff', fontSize: '0.875rem' },
      '& .MuiButtonBase-root.MuiTableSortLabel-root': {
        '&:hover, &.Mui-active': { color: '#fff' }
      }
    }}>
      <TableRow>
        {columns.map((col) => (
          <TableCell
            key={col.id}
            align="left"
            className="table-header-cell"
            sortDirection={orderBy === col.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === col.id}
              direction={orderBy === col.id ? order : 'asc'}
              onClick={createSortHandler(col.id)}
            >
              {col.label}
              {orderBy === col.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default function LogsComponent () {
  const [, setCommonLoader] = useContext(CommonLoaderContext)

  const defaultValues = {
    user: [],
    eventType: [],
    logType: [],
    pipeline: [],
    connector: [],
    logStage: []
  }
  const [inputs, setInputs] = useState(defaultValues)
  const handleChange = (event) => {
    const {
      target: { value, name }
    } = event
    setInputs({ ...inputs, [name]: typeof value === 'string' ? value.split(',') : value })
  }

  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [users, setUsers] = useState([])
  const [eventTypes, setEventTypes] = useState([])
  const [logTypes, setLogTypes] = useState([])
  const [pipelines, setPipelines] = useState([])
  const [connectors, setConnectors] = useState([])
  const [logStages, setLogStages] = useState([])
  useEffect(() => {
    const getAllLogData = async () => {
      setCommonLoader(true)
      try {
        const [
          users,
          pipelines,
          connectors,
          logTypes,
          eventTypes,
          logStages
        ] = await Promise.all([
          UserAPIService.getUserWithPermission(),
          PipelineAPIService.getPipelines(),
          ConnectorAPIService.getConnectors(),
          GlobalAPIService.getLogTypes(),
          GlobalAPIService.getEventTypes(),
          GlobalAPIService.getLogStages(),
          getOrganizationLogs()
        ])

        setUsers(users)
        setEventTypes(eventTypes)
        setLogTypes(logTypes)
        setPipelines(pipelines)
        setConnectors(connectors)
        setLogStages(logStages)

        setCommonLoader(false)
      } catch {
        setCommonLoader(false)
      }

    }

    getAllLogData()
  }, [])

  const [searchValue, setSearchValue] = useState('')
  const filterWithSearch = (list, key) => {
    const val = key.toLowerCase()
    if (!val) return list

    return list.filter(({ log_txt }) => log_txt.toLowerCase().includes(val))
  }

  const [searchTriggerValue, setSearchTriggerValue] = useState('')
  const handleSearch = (e) => {
    if (e.key === 'Enter') {
      setSearchTriggerValue(e.target.value)
    }
  }

  const [logList, setLogList] = useState([])
  const getOrganizationLogs = async () => {
    const payload = {
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
      eventType: JSON.stringify(inputs['eventType']),
      logTypeId: JSON.stringify(inputs['logType']),
      pipelineId: JSON.stringify(inputs['pipeline']),
      connectorId: JSON.stringify(inputs['connector']),
      user: JSON.stringify(inputs['user']),
      logStageId: JSON.stringify(inputs['logStage'])
    }
    const { logList } = await OrganizationAPIService.getOrganizationLogs(payload)
    setLogList(filterWithSearch(logList, searchValue))
  }

  useEffect(() => {
    const getLogs = async () => {
      setCommonLoader(true)
      await getOrganizationLogs()
      setCommonLoader(false)
    }
    if (users?.length) getLogs()

    const logTimeout = setInterval(getOrganizationLogs, 5000)
    return () => clearInterval(logTimeout)
  }, [startDate, endDate, inputs, searchTriggerValue])

  const handleClearFilters = () => {
    setStartDate(new Date())
    setEndDate(new Date())
    setInputs(defaultValues)
    setSearchValue('')
  }

  const csvHeader = [
    { label: "Date", key: "date" },
    { label: "Event Type", key: "event_type" },
    { label: "Log Type", key: "log_type" },
    { label: "Pipeline", key: "pipeline" },
    { label: "Connector", key: "connector" },
    { label: "Pipeline Stage", key: "pipeline_stage" },
    { label: "User", key: "user" },
    { label: "Log", key: "log" }
  ]
  const [csvData, setCsvData] = useState([])
  useEffect(() => {
    if (logList?.length) {
      setCsvData(logList.map(log => ({
        date: format(new Date(log?.log_dts), 'yyyy-MM-dd hh:mm aa'),
        event_type: log?.event_type_dsc,
        log_type: log?.log_type_dsc,
        pipeline: log?.pipeline_nm,
        connector: log?.connector_nm,
        pipeline_stage: log?.pipeline_stage_nm,
        user: log?.email_txt,
        log: log?.log_txt
      })))
    }
  }, [logList])



  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('DATE')
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  return (
    <MaterialUITheme>
      <Box sx={{ width: '98%', mx: 'auto' }}>
        <Typography component={'h2'} variant="h3" sx={{ textAlign: 'center', mb: 1, mt: 2.5, fontSize: '2.5rem' }}>Logs</Typography>
        <Divider />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1, mb: 2 }}>
          <TextField
            label="Search Logs"
            size="small"
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyDown={handleSearch}
            value={searchValue}
            helperText={searchValue && "Please press 'Enter' to search"}
            sx={{ width: 300, '& .MuiFormHelperText-root': { position: 'absolute', bottom: -18 } }}
          />
          <CSVLink
            filename={'HIPAA-.csv'}
            data={csvData}
            headers={csvHeader}
            style={{ textDecoration: 'none' }}
          >
            <Button color="primary" variant="contained">Export to CSV</Button>
          </CSVLink>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', columnGap: 1, width: 1, '& .MuiFormControl-root': { width: 120 } }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Start Date"
                views={['day']}
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                renderInput={(params) => <TextField sx={{ minWidth: 160 }} size="small" {...params} helperText={null} />}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="End Date"
                views={['day']}
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
                renderInput={(params) => <TextField sx={{ minWidth: 160 }} size="small" {...params} helperText={null} />}
              />
            </LocalizationProvider>
            <FormControl size="small" sx={{ width: '130px !important' }}>
              <InputLabel id="eventType">Event Type</InputLabel>
              <Select
                labelId="eventType"
                multiple
                name="eventType"
                value={inputs['eventType']}
                onChange={handleChange}
                input={<OutlinedInput label="Event Type" />}
                renderValue={(selected) => selected.join(', ')}
              >
                {eventTypes.map(({ EventTypeNM, EventTypeID }) => (
                  <MenuItem key={EventTypeID} value={EventTypeNM}>
                    <Checkbox checked={inputs['eventType'].indexOf(EventTypeNM) > -1} size="small" sx={{ py: 0 }} />
                    <ListItemText primary={EventTypeNM} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl size="small">
              <InputLabel id="logType">Log Type</InputLabel>
              <Select
                labelId="logType"
                multiple
                name="logType"
                value={inputs['logType']}
                onChange={handleChange}
                input={<OutlinedInput label="Log Type" />}
                renderValue={(selected) => selected.map(id => logTypes.find(({ LogTypeID }) => LogTypeID === id).LogTypeNM).join(', ')}
              >
                {logTypes.map(({ LogTypeNM, LogTypeID }) => (
                  <MenuItem key={LogTypeID} value={LogTypeID}>
                    <Checkbox checked={inputs['logType'].indexOf(LogTypeID) > -1} size="small" sx={{ py: 0 }} />
                    <ListItemText primary={LogTypeNM} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl size="small" sx={{ width: '100px !important' }}>
              <InputLabel id="pipeline">Pipeline</InputLabel>
              <Select
                labelId="pipeline"
                multiple
                name="pipeline"
                value={inputs['pipeline']}
                onChange={handleChange}
                input={<OutlinedInput label="Pipeline" />}
                renderValue={(selected) => selected.map(sid => pipelines.find(({ id }) => id === sid).pipeline_nm).join(', ')}
              >
                {pipelines.map(({ pipeline_nm, id }) => (
                  <MenuItem key={id} value={id}>
                    <Checkbox checked={inputs['pipeline'].indexOf(id) > -1} size="small" sx={{ py: 0 }} />
                    <ListItemText primary={pipeline_nm} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl size="small">
              <InputLabel id="connector">Connector</InputLabel>
              <Select
                labelId="connector"
                multiple
                name="connector"
                value={inputs['connector']}
                onChange={handleChange}
                input={<OutlinedInput label="Connector" />}
                renderValue={(selected) => selected.map(id => connectors.find(({ ConnectorID }) => ConnectorID === id).ConnectorNM).join(', ')}
              >
                {connectors.map(({ ConnectorNM, ConnectorID }) => (
                  <MenuItem key={ConnectorID} value={ConnectorID}>
                    <Checkbox checked={inputs['connector'].indexOf(ConnectorID) > -1} size="small" sx={{ py: 0 }} />
                    <ListItemText primary={ConnectorNM} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl size="small" sx={{ width: '150px !important' }}>
              <InputLabel id="logStage">Pipeline Stage</InputLabel>
              <Select
                labelId="logStage"
                multiple
                name="logStage"
                value={inputs['logStage']}
                onChange={handleChange}
                input={<OutlinedInput label="Pipeline Stage" />}
                renderValue={(selected) => selected.map(id => logStages.find(({ LogStageID }) => LogStageID === id).LogStageNM).join(', ')}
              >
                {logStages.map(({ LogStageNM, LogStageID }) => (
                  <MenuItem key={LogStageID} value={LogStageID}>
                    <Checkbox checked={inputs['logStage'].indexOf(LogStageID) > -1} size="small" sx={{ py: 0 }} />
                    <ListItemText primary={LogStageNM} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl size="small" sx={{ flex: 1 }}>
              <InputLabel id="user">User</InputLabel>
              <Select
                labelId="user"
                multiple
                name="user"
                value={inputs['user']}
                onChange={handleChange}
                input={<OutlinedInput label="User" />}
                renderValue={(selected) => selected.map(id => users.find(({ UserID }) => UserID === id).EmailTXT).join(', ')}
              >
                {users.map(({ EmailTXT, UserID }) => (
                  <MenuItem key={UserID} value={UserID}>
                    <Checkbox checked={inputs['user'].indexOf(UserID) > -1} size="small" sx={{ py: 0 }} />
                    <ListItemText primary={EmailTXT} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button variant="outlined" size="small" color="primary" onClick={handleClearFilters}>Clear Filters</Button>
          </Box>
        </Box>

        <TableContainer sx={{ borderRadius: 0, maxHeight: 'calc(100vh - 280px)', mt: 2 }}>
          <Table size="small" stickyHeader>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody sx={{
              '& .MuiTableRow-root': { '&:nth-of-type(even)': { backgroundColor: 'var(--neutral-light)' } },
              '& .MuiTableCell-root': { verticalAlign: 'top' }
            }}>
              {logList
                .slice()
                .sort(getComparator(order, orderBy))
                .map(({ log_dts, event_type_dsc, log_type_dsc, connector_nm, pipeline_nm, pipeline_stage_nm, email_txt, log_txt }, index) => (
                  <TableRow key={Math.random() + log_dts} size="small">
                    <TableCell title={format(new Date(log_dts), 'yyyy-MM-dd hh:mm aa')} sx={{ maxWidth: '150px !important', width: 150 }}>
                      {format(new Date(log_dts), 'yyyy-MM-dd hh:mm aa')}
                    </TableCell>
                    <TableCell title={event_type_dsc} sx={{ maxWidth: '120px !important', width: 110 }}>{event_type_dsc}</TableCell>
                    <TableCell title={log_type_dsc} sx={{ maxWidth: '80px !important', width: 80 }}>{log_type_dsc}</TableCell>
                    <TableCell title={pipeline_nm} sx={{ maxWidth: '120px !important', width: 80 }}>{pipeline_nm}</TableCell>
                    <TableCell title={connector_nm} sx={{ maxWidth: '120px !important', width: 120 }}>{connector_nm}</TableCell>
                    <TableCell title={pipeline_stage_nm} sx={{ maxWidth: '150px !important', width: 130 }}>{pipeline_stage_nm}</TableCell>
                    <TableCell title={email_txt} sx={{ maxWidth: '200px !important', width: 120 }}>{email_txt}</TableCell>
                    <TableCell title={log_txt} sx={{ whiteSpace: 'normal !important' }}>{log_txt}</TableCell>
                  </TableRow>
                )
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </MaterialUITheme >
  )
}
