import React, { useEffect, useState } from 'react'
import { TextField, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import { toast } from 'react-toastify'
import PermissionSelect from "./permissionSelect"
import { UserAPIService } from '../../../config/api-service';

const uuidv4 = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    // FIXME:
     // eslint-disable-next-line
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

const AddUserModal = ({ handleAddNewUserObj, permissionList, setAddUsersModalShow }) => {
    const [userObj, updateUserObj] = useState({ permissions: [] })
    const [userObjError, updateUserObjError] = useState({})
    const [permissionUpdate, setPermissionUpdate] = useState(uuidv4())
    const [addUserBtnEnable, setAddUserBtnEnable] = useState(false)



    const handleChange = (e) => {
        let fieldName = e.target.name;
        let fieldValue = e.target.value;
        let temp = { ...userObj } || {}
        temp[fieldName] = fieldValue;
        updateUserObj(temp);
        handleFieldWiseValidation(fieldName, fieldValue);
    }

    const handlePermissionChange = (fieldName, PermissionIDs) => {
        let temp = { ...userObj } || {}
        temp[fieldName] = PermissionIDs;
        updateUserObj(temp);
        // setPermissionUpdate(uuidv4())

        handleFieldWiseValidation(fieldName, temp[fieldName]);

    }


    const handleFieldWiseValidation = (field, value) => {
        let tempUserObjError = { ...userObjError }
        switch (field) {
            case "FirstNM":
                tempUserObjError[field] = !value;
                break;
            case "LastNM":
                tempUserObjError[field] = !value;
                break;
            case "EmailTXT":
                tempUserObjError[field] = !(!!value && /^\w+([.-.+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value));
                break;
            case "permissions":
                tempUserObjError[field] = !(!!value && !!value.length);
                break;
            default:
                break;
        }
        updateUserObjError(tempUserObjError);
    }



    const handleAllFieldValidation = () => {
        let tempUserObjError = { ...userObjError }
        tempUserObjError.FirstNM = !userObj.FirstNM;
        tempUserObjError.LastNM = !userObj.LastNM;
        tempUserObjError.EmailTXT = !(!!userObj.EmailTXT && /^\w+([.-.+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(userObj.EmailTXT));
        tempUserObjError.permissions = !(!!userObj.permissions && userObj.permissions.length);
        updateUserObjError(tempUserObjError)

    }

    const handleSaveButtonEnable = () => {
        const { FirstNM, LastNM, EmailTXT, permissions } = userObj;
        setAddUserBtnEnable(!!FirstNM && !!LastNM && !!EmailTXT && /^\w+([.-.+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(EmailTXT) && !!permissions && !!permissions.length);
    }



    useEffect(() => {
        handleSaveButtonEnable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userObj])



    const handleSaveBtn = async () => {
        const { FirstNM, LastNM, EmailTXT, permissions } = userObj || {}
        if (addUserBtnEnable) {
            let params = {
                FirstNM: FirstNM,
                LastNM: LastNM,
                EmailTXT: EmailTXT,
                permissions: permissions
            }
            try {
                await UserAPIService.saveUser(params)
                handleAddNewUserObj()
                toast.success('User added')
            } catch (error) {
                toast.error("Error")
            }
        } else {
            handleAllFieldValidation()
        }
    }

    return (
        <div className='add-user-modal'>
            <div className='add-user-modal-header'>
                <span className='add-user-modal-header-title'>Add User</span>
                <CloseIcon onClick={() => setAddUsersModalShow(false)} />
            </div>
            <Grid container spacing={2} className='p-20'>
                <Grid item xs={12} sm={6}>
                    <label className='user-flabel'>FIRST NAME <span style={{ color: 'red' }}>*</span>
                    </label>
                    <TextField onChange={handleChange} inputProps={{ style: { backgroundColor: '#fff' } }} name="FirstNM" size='small' variant="outlined" fullWidth value={userObj.FirstNM} />
                    {!!userObjError.FirstNM && <p className='add-user-modal-field-error'> required field </p>}
                </Grid>
                <Grid item xs={12} sm={6}>
                    <label className='user-flabel'>LAST NAME <span style={{ color: 'red' }}>*</span></label>
                    <TextField onChange={handleChange} inputProps={{ style: { backgroundColor: '#fff' } }} size='small' variant="outlined" name="LastNM" fullWidth value={userObj.LastNM} />
                    {!!userObjError.LastNM && <p className='add-user-modal-field-error'> required field </p>}

                </Grid>
            </Grid>
            <Grid container spacing={2} className='p-20'>
                <Grid item xs={12} sm={6}>
                    <label className='user-flabel'>EMAIL  <span style={{ color: 'red' }}>*</span></label>
                    <TextField onChange={handleChange} inputProps={{ style: { backgroundColor: '#fff' } }} size='small' variant="outlined" name="EmailTXT" fullWidth value={userObj.EmailTXT} />
                    {!!userObjError.EmailTXT && <p className='add-user-modal-field-error'> Invalid email</p>}

                </Grid>
                <Grid item xs={12} sm={6}>
                    <label className='user-flabel'>PERMISSIONS  <span style={{ color: 'red' }}>*</span></label>
                    <PermissionSelect key={permissionUpdate} handlePermissionChange={handlePermissionChange} permissionList={permissionList} fieldName="permissions" handleChange={handleChange} selectedArr={userObj.permissions || []} />
                    {!!userObjError.permissions && <p className='add-user-modal-field-error'> required field </p>}
                </Grid>
            </Grid>
            <Button disabled={!addUserBtnEnable} size="small" variant='contained' className='float-right add-user-save-btn' onClick={() => handleSaveBtn()} >  Save </Button>
        </div>
    );
}
export default AddUserModal;