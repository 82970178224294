import { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { Box, } from '@mui/material'
import MaterialUITheme from '../../config/material-ui-theme'
import Sidebar from './Sidebar'
import FeatureRequest from './FeatureRequest'
import NewFeatureRequestForm from './NewFeatureRequestForm'
import Dialog from '../common/dialog'
import { FeatureRequestAPIService } from '../../config/api-service'
import useScript from '../../config/useScript'

const drawerWidth = 250

const Main = styled('main')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  padding: theme.spacing(3),
  paddingBottom: theme.spacing(1),
  marginLeft: 0
}))

export default function FeatureRequestLayout () {
  const [featureTypes, setFeatureTypes] = useState([])
  useEffect(() => {
    const getFeatureTypes = async () => {
      const types = await FeatureRequestAPIService.getFeatureTypes()
      setFeatureTypes(types)
    }
    getFeatureTypes()
  }, [])

  const [featureTypeId, setFeatureTypeId] = useState(0)
  const handleFilter = (key) => {
    setFeatureTypeId(key)
  }

  const [newFeatureDialog, setNewFeatureDialog] = useState(false)
  const openNewFeatureDialog = () => {
    setNewFeatureDialog(true)
  }

  const [FRKey, setFRKey] = useState(1)
  const handleNewFeatureRequest = async (payload) => {
    await FeatureRequestAPIService.createFeatureRequest(payload)
    setNewFeatureDialog(false)
    setFRKey(Math.random())
  }

  useScript()

  return (
    <MaterialUITheme>
      <Box sx={{ display: 'flex', height: 'calc(100% - 54px)', overflow: 'auto' }}>
        <Sidebar navs={featureTypes} width={drawerWidth} onClickItem={handleFilter} onClickNewFeature={openNewFeatureDialog} />

        <Main>
          <FeatureRequest featureTypes={featureTypes} FRKey={FRKey} featureTypeId={featureTypeId} />
        </Main>

        <Dialog
          open={newFeatureDialog}
          handleClose={() => setNewFeatureDialog(false)}
          title="Request a Feature"
          centerAligned={false}
        >
          <NewFeatureRequestForm featureTypes={featureTypes} handleSubmitNewFeature={handleNewFeatureRequest} />
        </Dialog>
      </Box>
    </MaterialUITheme>
  )
}
