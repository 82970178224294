import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Button, MenuItem, CircularProgress, Box, Stack } from '@mui/material'
import DataConTextField from '../common/data-con-form/FormFields'
import { OrganizationAPIService, UserAPIService } from '../../config/api-service'
import { APP_KEY, ORGANIZATIONS } from '../../config/constants'

export default function SelectOrganization({ organizations, user }) {
  const history = useHistory()

  const { handleSubmit, control } = useForm({
    mode: 'all',
    defaultValues: {
      selectedOrganization: ''
    }
  })

  const submitOrganization = async ({ selectedOrganization = '' }) => {
    try {
      const assignedOrganization = await OrganizationAPIService.assignUserToOrganization(selectedOrganization)
      const methods = await UserAPIService.getPaymentMethod()
      localStorage.setItem(ORGANIZATIONS, JSON.stringify({
        organizations, selectedOrganization, hasPMethods: methods?.paymentMethods?.data?.length > 0 ? true : false, organizationTRL: assignedOrganization.organizationTRL
      }))
      !localStorage.getItem(APP_KEY) && localStorage.setItem(APP_KEY, JSON.stringify({ user }))
      history.push('/pipelines')
    } catch { }
  }

  useEffect(() => {
    if (organizations?.length === 1) {
      const [{ organization_id }] = organizations
      submitOrganization({ selectedOrganization: organization_id })
    }
  }, [organizations])

  return (
    <>
      {organizations?.length > 1
        ? <>
          <DataConTextField
            select
            required
            size="small"
            fullWidth
            label="Select Organization"
            variant="outlined"
            name="selectedOrganization"
            control={control}
            rules={{ required: `Organization is required` }}
            sx={{ mb: 5 }}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  sx: {
                    maxWidth: "480px",
                    '& .MuiMenuItem-root': {
                      whiteSpace: 'normal',
                    },
                  },
                },
              }
            }}
          >
            {organizations?.map(({ organization_nm, organization_id }) => (
              <MenuItem key={organization_id} value={organization_id}>
                {organization_nm.length > 60 ? organization_nm.slice(0, 60) + "..." : organization_nm}
              </MenuItem>
            ))}
          </DataConTextField>

          <Button variant="contained" type="submit" fullWidth color="primary" onClick={handleSubmit(submitOrganization)}>Go</Button>
        </>
        :
        <div style={{ alignItems: "center", display: "flex", justifyContent: "center", height: "100vh", width: "100vw" }}>
          <CircularProgress />
        </div>
      }
    </>
  )
}
