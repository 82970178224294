import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Popper, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PasswordChecklist from "react-password-checklist";
import { toast } from 'react-toastify';
import { UserAPIService } from '../../config/api-service';
import { APP_KEY, ORGANIZATIONS } from '../../config/constants';
import { useAppContext } from '../../context/app.context';
import Dialog from '../common/dialog';

const Register = ({ openRegisterModal, setOpenRegisterModal}) => {
    const { anonymousUserEmail, setAnonymousUserEmail } = useAppContext()
    const [userInfo, setUserInfo] = useState({});
    const [emailError, setEmailError] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [passwordValid, setPasswordValid] = useState(false)
    const [formErrorMessage, setFormErrorMessage] = useState("")
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState({
        CompanyNM: "",
        PasswordHASH: "",
        RetypePasswordHASH: "",
    });

    useEffect(() => {
        const localStorageData = localStorage.getItem(APP_KEY);
        if (localStorageData) {
            setUserInfo(JSON.parse(localStorageData));
        }
        window.addEventListener('anonymousUserEventChange', () => {
            const localStorageUserData = localStorage.getItem(APP_KEY)
            if (localStorageUserData) {
                setUserInfo(JSON.parse(localStorageUserData))
            }
        })

    }, []);

    const emailReg = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+.[a-zA-Z0-9+.]$/;
    const open = Boolean(anchorEl)
    const mediaMatch = window.matchMedia('(max-width: 500px)')

    const handleChange = (event) => {
        setValue({ ...value, [event.target.name]: event.target.value });
    };



    const handleClick = (e) => {
        e.preventDefault()
        if (!anonymousUserEmail) {
          setEmailError("Email address is required");
          return;
        }
        else if (!emailReg.test(anonymousUserEmail)) {
            return;
        }
        else if (!passwordValid) {
            setFormErrorMessage('Password did not meet the requirements.')
            return
        }

        try {
            setLoading(true)
            UserAPIService.registerFromDataConnector({ ...value, CompanyNM: value.CompanyNM || anonymousUserEmail, UserID: userInfo.user.UserID, LastNM: '', FirstNM: userInfo.user.FirstNM, EmailTXT: anonymousUserEmail })
                .then(json => {
                    if (json) {
                        setFormErrorMessage('')
                        setFormSubmitted(true)
                        setOpenRegisterModal(false);
                        setValue({
                            CompanyNM: "",
                            PasswordHASH: "",
                            RetypePasswordHASH: "",
                        }); 
                        localStorage.setItem(APP_KEY, JSON.stringify({ ...JSON.parse(localStorage.getItem(APP_KEY)), user: json.user, anonymousRegistered: 1 }));
                        localStorage.setItem(ORGANIZATIONS, JSON.stringify({ ...JSON.parse(localStorage.getItem(ORGANIZATIONS)), organizations: json.organizationList }));
                        window.dispatchEvent(new Event("organizationUpdated"));
                        window.dispatchEvent(new Event("anonymousUserEventChange"));
                        setLoading(false)
                        toast.success('Registration successful. Check your email to verify.');
                    }
                }).catch(err => {
                    setLoading(false)
                    err.text().then(text => setFormErrorMessage(JSON.parse(text).message))
                })
        } catch (err) {
            setLoading(false)
            err.text().then(text => setFormErrorMessage(JSON.parse(text).message))
        }
    };
    
    return (
        <Dialog
          open={openRegisterModal}
          handleClose={() => setOpenRegisterModal(false)}
          title="Register to start your 14-day free trial"
          centerAligned={false}
        >
            <Box component={'form'} onSubmit={handleClick}>
                <Popper open={open} anchorEl={anchorEl} placement="top-end" style={{ zIndex: 10000, marginBottom: 5 }}>
                    <div style={{ border: '1px solid #ccc', backgroundColor: '#fff', padding: 5 }}>
                        <PasswordChecklist
                            rules={["minLength", "number", "match"]}
                            minLength={6}
                            value={value.PasswordHASH}
                            valueAgain={value.RetypePasswordHASH}
                            onChange={(isValid) => setPasswordValid(isValid)}
                            iconSize={mediaMatch ? 10 : 12}
                            className={mediaMatch.matches ? "size-10" : "size-12"}
                            messages={{ minLength: "Password has at least 6 characters" }}
                        />
                    </div>
                </Popper>

                <TextField
                    fullWidth
                    sx={{ mb: 2 }}
                    variant="outlined"
                    size="small"
                    placeholder="Email"
                    type='email'
                    value={anonymousUserEmail}
                    onChange={(e) => setAnonymousUserEmail(e.target.value)}
                    name="EmailTXT"
                    error={emailError !== "" || (!emailReg.test(anonymousUserEmail) && anonymousUserEmail !== "")}
                    label={
                        emailError ? emailError : !emailReg.test(anonymousUserEmail) && anonymousUserEmail !== "" ? "Invalid Email!" : "Email"
                    }
                />
                <TextField
                    fullWidth
                    sx={{ mb: 2 }}
                    variant="outlined"
                    size="small"
                    placeholder="Organization Name"
                    label="Organization Name"
                    type='text'
                    value={value.CompanyNM}
                    onChange={handleChange}
                    name="CompanyNM"
                />

                <TextField
                    fullWidth
                    sx={{ mb: 2 }}
                    size="small"
                    name="PasswordHASH"
                    label="Password"
                    variant="outlined"
                    type="password"
                    value={value.PasswordHASH}
                    error={!value.PasswordHASH && formSubmitted}
                    onFocus={(event) => setAnchorEl(anchorEl ? null : event.currentTarget)}
                    onBlur={(event) => setAnchorEl(anchorEl ? null : event.currentTarget)}
                    onChange={handleChange} />
                <TextField
                    fullWidth
                    size="small"
                    name="RetypePasswordHASH"
                    label="Retype Password"
                    variant="outlined"
                    type="password"
                    value={value.RetypePasswordHASH}
                    error={!value.RetypePasswordHASH && formSubmitted}
                    onFocus={(event) => setAnchorEl(anchorEl ? null : event.currentTarget)}
                    onBlur={(event) => setAnchorEl(anchorEl ? null : event.currentTarget)}
                    onChange={handleChange}
                />
                <Typography color="error" sx={{ mt: 1, textAlign: "center" }}>{formErrorMessage}</Typography>
                {/* <Button fullWidth type="submit" variant="contained" sx={{ mt: 1 }}>Start 14 day free trial</Button> */}
                <LoadingButton fullWidth type="submit" loading={loading} variant="contained" sx={{ mt: 1 }}>
                    Start 14 day free trial
                </LoadingButton>
            </Box>
        </Dialog>
    );
};

export default Register;