import { Grid, Typography, Card, CardContent } from "@mui/material"
import { Quiz, Person, InsertChartOutlined } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import MaterialUITheme from '../../config/material-ui-theme'
import feedbackIcon from '../../assets/images/feedback_icon.png'
import dataConIcon from '../../assets/data_connector_logo_new.png'
import useScript from "../../config/useScript"

const cards = [
  // {
  //   icon: <Quiz sx={{ fontSize: 90 }} />,
  //   text: 'FAQ',
  //   buttonLink: '/resources'
  // },
  // {
  //   icon: <Person sx={{ fontSize: 90 }} />,
  //   text: 'Account/Users',
  //   buttonLink: '/resources'
  // },
  {
    icon: <InsertChartOutlined sx={{ fontSize: 90 }} />,
    text: 'Professional Services',
    buttonLink: '/support/professional-services'
  },
  // {
  //   icon: <img src={dataConIcon} alt="data-connector" style={{ width: 90 }} />,
  //   text: 'Pipelines / Connectors',
  //   buttonLink: '/resources'
  // }
  {
    icon: <img src={feedbackIcon} alt="Feature Request" style={{ width: 90 }} />,
    text: 'Feature Request',
    buttonLink: '/feature-request'
  },
]

export default function SupportComponent () {
  useScript()
  return (
    <MaterialUITheme>
      <Grid
        container
        sx={{
          maxWidth: 1550,
          mx: 'auto',
          p: 2,
          '& .MuiTypography-root': { fontWeight: 600 }
        }}
      >
        <Grid item xs={12}>
          <Typography component={'h6'} variant="h6" sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}`, pb: 2 }}>
            Get Support
          </Typography>
          <Typography component={'h2'} variant="h5" sx={{ py: 3, pb: 4, pt: 2 }}>
            What do you need help with?
          </Typography>
        </Grid>
        <Grid item container xs={12} spacing={8} sx={{ pb: 6 }}>
          {cards.map(({ icon, text, buttonLink }) => (
            <Grid item key={text}>
              <Card
                component={Link}
                to={buttonLink}
                raised
                sx={{
                  width: 250,
                  minHeight: 200,
                  boxSizing: 'border-box',
                  px: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'var(--data-con-blue)',
                  textDecoration: 'none'
                }}
              >
                <CardContent sx={{ display: 'flex', flexFlow: 'column wrap', flex: '1 1 100%', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Typography variant="h6" component="div" sx={{ textAlign: 'center', lineHeight: 'normal' }}>
                    {text}
                  </Typography>
                  {icon}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12}>
          <Typography component={'h6'} variant="h6" sx={{ borderTop: (theme) => `1px solid ${theme.palette.divider}`, pb: 2, pt: 2 }}>
            Contact
          </Typography>
          <Typography component={'div'} variant="h7">
            Email: support@dataconnector.com
          </Typography>
          <Typography component={'div'} variant="h7">
            Phone: 435-299-1131
          </Typography>
        </Grid>
      </Grid>
    </MaterialUITheme>
  )
}