import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { RouterConfig } from './app/config/router';
import './app/config/styles.css';
import './app/config/overriden-mui.css'
import OrganizationProvider from "./app/context/organization.context"

ReactDOM.render(
    <React.StrictMode>
        {/* Transition: Router */}
        <OrganizationProvider>
            <RouterConfig />
        </OrganizationProvider>
    </React.StrictMode>,
    document.getElementById('root')
);


// window.onbeforeunload = (event) => {
//     console.log(window.location);
//     const e = event || window.event;
//     e.preventDefault();
//     return false;
//     // // Show prompt based on state
//     // if (window.location.includes('connectors/new')) {
//     //     return window.confirm("Are you sure you would like to leave this page?");
//     // }
// };

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
