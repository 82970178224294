import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

const BootstrapAlertDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-container': {
    alignItems: 'flex-start'
  },
  '& .MuiDialog-paper': {
    borderRadius: 10,
    marginTop: 90
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(4),
    fontSize: '1.2rem',
    maxHeight: '55vh'
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(0)
  },
}))

const AlertDialogTitle = (props) => {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2, fontWeight: 600 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

AlertDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
}

const ActionButton = styled(LoadingButton)(({ theme }) => ({
  width: 'calc((100% - 50px) / 2)',
  padding: theme.spacing(1)
}))

export default function AlertDialog ({ open, title = 'Confirm', children, handleClose, handleConfirm, actions, centerAligned = true, maxWidth = 'sm', highlightYes }) {
  const [loading, setLoading] = useState(false)
  const onClickYes = () => {
    setLoading(true)
    handleConfirm()
  }

  useEffect(() => {
    setLoading(false)
  }, [open])

  return (
    <BootstrapAlertDialog
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      open={!!open}
      fullWidth
      maxWidth={maxWidth}
    >
      <AlertDialogTitle id="alert-dialog-title" onClose={handleClose}>
        {title}
      </AlertDialogTitle>
      <DialogContent
        dividers
        sx={{
          borderBottom: !!actions ? '' : 'unset',
          textAlign: centerAligned ? 'center' : '',
        }}
      >
        {children}
      </DialogContent>
      {handleConfirm
        ? (<DialogActions sx={{ justifyContent: 'space-around' }}>
          <ActionButton variant="contained" sx={{ backgroundColor: highlightYes || '#aaa' }} onClick={onClickYes} loading={loading}>
            Yes
          </ActionButton>
          <ActionButton variant="contained" sx={{ backgroundColor: highlightYes && '#aaa' }} onClick={handleClose}>
            No
          </ActionButton>
        </DialogActions>)
        : actions
          ? <DialogActions sx={{ flexDirection: "column", alignItems: "stretch" }}>{actions}</DialogActions>
          : null
      }
    </BootstrapAlertDialog>
  )
}

AlertDialog.propTypes = {
  children: PropTypes.node,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func,
  title: PropTypes.string
}
