import React, {useState} from 'react';
import TextField from '@material-ui/core/TextField';
import './style.css';

export const ContactForm = () => {
    const [EmailTXT, setEmail] = useState('');
    const [question, setQuestion] = useState('');
    const [FirstNM, setFirstName] = useState('');
    const [LastNM, setLastName] = useState('');
    const [CompanyNM, setCompanyName] = useState('');
    const [PrimaryPhoneNBR, setPhoneNumber] = useState('');
    // const [success, setSuccess] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();            
    }

    const successMsg = /*!success ? 
    // (<div className="margin-3"> 
    // </div>) : */
    (<div className="success">
        Success! Redirecting you to log in...
    </div>)

    return (
        <div className="main-container margin-5">
            <form noValidate autoComplete="off"  >
                <div className="margin-3">
                    <TextField
                        size="small"
                        className="field submit"
                        id="outlined-basic"
                        label="Email"
                        variant="outlined"
                        value={EmailTXT}
                        onInput={e => setEmail(e.target.value)} />
                    </div>
                    <div className="margin-3">
                    <TextField
                        size="small"
                        className="field submit"
                        id="outlined-basic"
                        label="First Name"
                        variant="outlined"
                        value={FirstNM}
                        onInput={e => setFirstName(e.target.value)} />
                    </div>
                    <div className="margin-3">
                    <TextField
                        size="small"
                        className="field submit"
                        id="outlined-basic"
                        label="Last Name"
                        variant="outlined"
                        value={LastNM}
                        onInput={e => setLastName(e.target.value)} />
                    </div>
                    <div className="margin-3">
                    <TextField
                        size="small"
                        className="field submit"
                        id="outlined-basic"
                        label="Company Name"
                        variant="outlined"
                        value={CompanyNM}
                        // helperText="Optional"
                        onInput={e => setCompanyName(e.target.value)} />
                    </div>
                    <div className="margin-3">
                    <TextField
                        size="small"
                        className="field submit"
                        id="outlined-basic"
                        label="Phone Number"
                        variant="outlined"
                        value={PrimaryPhoneNBR}
                        onInput={e => setPhoneNumber(e.target.value)} />
                    </div>
                    <div className="form-group margin-3">
                        <textarea 
                            value={question}
                            className="form-control input-form" 
                            id="exampleFormControlTextarea1" 
                            rows="2" 
                            placeholder="How can we help you?"
                            onInput={e => setQuestion(e.target.value)}
                        />
                    </div> 
                    {successMsg}
                    <div className="row">
                        <button
                            type="submit" 
                            variant="contained" 
                            className="btn btn-primary"
                            onClick={handleSubmit}>
                            Send question!
                        </button>
                    </div> 
                </form>
        </div>
    )
}
