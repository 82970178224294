import { Box, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import useTimeCounter from '../../utils/useTimeCounter/useTimeCounter';

const TimerComponent = ({ trialEndTime }) => {
    const timeLeft = useTimeCounter(trialEndTime, 1000)
    return (
        <>
            {timeLeft && timeLeft.days() >= 0 && timeLeft.hours() >= 0 && timeLeft.minutes() >= 0 &&
                <Box sx={{ color: "#F96900", marginLeft: { md: "10px" } }}>
                    <Typography sx={{ display: { md: "block", xs: "none" } }}>
                        Time left on free trial: {`${timeLeft.days()}D:${timeLeft.hours()}h:${timeLeft.minutes() + 1}m.`}
                    </Typography>
                    <Typography sx={{ display: { md: "block", xs: "none" } }}>
                        Click <Link to="/account/billing-setting" style={{ color: "#F96900" }}>here</Link> to enter your payment info
                    </Typography>
                    {/* <Typography sx={{ textAlign: "right", background: "orange", color: "white", px: 1, fontWeight: "bold" }}>

                </Typography> */}
                    <Typography sx={{ display: { xs: "block", md: "none" }, textAlign: "center", pt: "5px", fontSize: "14px" }}>
                        Time left on free trial: {`${timeLeft.days()}D:${timeLeft.hours()}h:${timeLeft.minutes() + 1}m.`}
                    </Typography>
                    <Typography sx={{ display: { xs: "block", md: "none" }, textAlign: "center", pb: "5px", fontSize: "14px" }}>
                        Click <Link to="/account/billing-setting" style={{ color: "#F96900" }}>here</Link> to enter your payment info.
                    </Typography>
                </Box>

            }
        </>
    );
};

export default TimerComponent;