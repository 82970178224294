import { useState } from 'react'
import { UserAPIService } from '../../config/api-service'
import { TextField, Button, Card } from '@mui/material'
import MaterialUITheme from '../../config/material-ui-theme'
import ResetPasswordComponent from '../reset-password'
import { useAlertContext } from '../../context/alert.context'
import DataConnectorImage from '../../assets/images/dataconnector_main.png'
import useScript from '../../config/useScript'

export default function ForgotPasswordComponent() {
    const [email, setEmail] = useState("")
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [formErrorMessage, setFormErrorMessage] = useState("")
    const [showResetPage, setShowResetPage] = useState(false)
    const {setMessage, setSeverity} = useAlertContext()

    useScript()

    const handleSubmit = (event) => {
        event.preventDefault()

        setFormSubmitted(true)

        // Validate email field has a value
        if (!email || email === "")
            return

        UserAPIService.forgotPassword({ EmailTXT: email })
            .then(res => {
                setSeverity("success")
                setMessage(res?.message)
                setShowResetPage(true)
            })
            .catch(err => {
                setFormErrorMessage(err?.response?.data?.message)
            })
    }


    return (<>
        {showResetPage ? (<ResetPasswordComponent email={email} />) : (
        <MaterialUITheme>
            <Card raised={true} className='login'>
                <div style={{ textAlign: 'center' }}>
                    <img src={DataConnectorImage} alt="Logo" style={{ maxWidth: 150 }} />
                </div>

                <h2 className="loginLabel">Forgot Password</h2>
                <form noValidate onSubmit={handleSubmit} >
                    <div className="margin-2 not-sides">
                        <TextField
                            size="small"
                            className="field"
                            id="username"
                            name="username"
                            label="Email"
                            variant="outlined"
                            type="text"
                            value={email}
                            error={!email && formSubmitted}
                            onChange={e => setEmail(e.target.value)} />
                    </div>
                    <div className="error text margin-1">{formErrorMessage}</div>
                    <div className="row">
                        <Button fullWidth type="submit" variant="contained" color="primary">Submit</Button>
                    </div>
                </form>
                </Card>
            </MaterialUITheme>
        )}
    </>)
}
