import React from "react";
import { useHistory } from "react-router";
import useScript from "../../../config/useScript";

export default function OAuthHelperComponent() {
	const history = useHistory();
	const queryParams = new URLSearchParams(window.location.search);
	useScript()
	if (queryParams.get("error") && !queryParams.get("code")) {
		history.push({
			pathname: "/oauth",
			search: window.location.search + "&code=null",
		});
	}

	return <div></div>;
}
