import React, { useEffect, useState } from 'react'
import { Select, MenuItem, Box, Typography } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            minHeight: "270px"
        },
    },
};



const permissionListFilter = (data = []) => {
    if (!!data) {
        return data.filter(dataItem => dataItem.InternalFLG === 0 && dataItem.ActiveFLG === 1)
    }
    return data;
}

const getPermissionListGroup = (data = []) => {
    if (!!data) {
        let dataGroup = {}
        data.forEach(dataItem => {
            if (!dataGroup[dataItem.GroupDSC]) {
                dataGroup[dataItem.GroupDSC] = {
                    GroupDSC: dataItem.GroupDSC,
                    groupDataList: [dataItem]
                }
            } else {
                dataGroup[dataItem.GroupDSC].groupDataList.push(dataItem);
            }
        })

        return dataGroup;
    }
    return data;
}


const PermissionSelect = ({ handleChange, fieldName, permissionList, handlePermissionChange, selectedArr }) => {
    const [ localSelectedArr , setLocalSelectedArr] = useState([])
    const processValue = (dataList) => {
        let dataArr = [];
        dataList.forEach(data => {
            let dataObj = permissionList.find(permission => permission.PermissionID === data)
            !!dataObj && dataArr.push(dataObj.PermissionNM)
        })
        return dataArr.join(",");
    }

    const isCheck = (key) => {
        return localSelectedArr.find(permission => permission === key);
    }

    const permissionListGroup = getPermissionListGroup(permissionListFilter(permissionList))


    useEffect (()=>{
        setLocalSelectedArr(selectedArr)
    },[selectedArr])


    const customHandlePermissionChange = (fieldName, PermissionID) =>{
        let tempValue = selectedArr;
        let needPush = -1;
        if (!tempValue) {
            tempValue = [];
        } else {
            needPush = tempValue.indexOf(PermissionID)
        }
        if (needPush === -1) {
            tempValue.push(PermissionID);
        } else {
            tempValue.splice(needPush, 1)
        }
        setLocalSelectedArr(tempValue);
        handlePermissionChange(fieldName, tempValue);
    }

    return (
        <Select
            value={selectedArr || []}
            onChange={handleChange}
            name={fieldName}
            fullWidth
            className='select-permission'
            multiple
            MenuProps={MenuProps}
            renderValue={e => {
                return <div className='selected-text'>{processValue(selectedArr || [])}</div>;
            }}
        >
            <div className='select-list-header'>
                <div className='select-list-header-item'>PERMISSION</div>
                <div className='select-list-header-item'>DESCRIPTION</div>
            </div>
            {
                Object.keys(permissionListGroup).map((groupName, gIndex) => (
                    <div key={gIndex} >
                        <div className='select-group-title'> <span>{permissionListGroup[groupName].GroupDSC}</span>  </div>
                        {permissionListGroup[groupName].groupDataList.map((permission, pIndex) => (
                            <MenuItem
                                key={pIndex}
                                value={permission.PermissionID}
                                style={{ fontSize: "0.875rem" }}
                                onClick={() => customHandlePermissionChange(fieldName, permission.PermissionID)}
                                sx={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "start",
									alignContent: "center",
								}}
                            >
                                <div style={{ width: "120px" }}>
									{permission.PermissionNM}
								</div>
								<Box
									sx={{
										wordWrap: "break-word",
										width: "12rem",
									}}
								>
									<Typography
										style={{
											whiteSpace: "normal",
											fontSize: "13px",
										}}
									>
										{permission.PermissionDSC}
									</Typography>
								</Box>
								<div>
									{isCheck(permission.PermissionID) ? (
										<CheckBoxIcon
											sx={{ color: "#00bfff" }}
										/>
									) : (
										<CheckBoxOutlineBlankIcon
											sx={{ color: "#00bfff" }}
										/>
									)}
								</div>
                            </MenuItem>
                        ))}
                    </div>
                ))
            }
        </Select>
    );
}
export default PermissionSelect;