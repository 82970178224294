import React, {useState} from 'react';
import {Card, TextField, Button} from '@material-ui/core';
import { UserAPIService } from '../../config/api-service';

import {
    useHistory,
    useLocation
} from 'react-router-dom';
import useScript from '../../config/useScript';

function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

export default function ResetComponent(props) {
    let query = useQuery();
    const [password, setPassword] = useState("");
    const [verifyPassword, setVerifyPassword] = useState("");

    const history = useHistory();
    useScript()

    const handleSubmit = (event) => {
        event.preventDefault();
        
        UserAPIService.resetPassword({resetToken: query.get("reset"), password, verifyPassword})
            .then(json => {
                if (json) {
                    history.push('/pipelines');
                }
            })
    }


    return (
        <div>
            <Card raised={true} className="login">
                <h3 className="loginLabel">Change your password</h3>

                <form noValidate autoComplete="off" onSubmit={handleSubmit} >
                     <TextField 
                         size="small"
                         className="field"
                         id="outlined-basic" 
                         label="New Password" 
                         variant="outlined"
                         value={password}
                         onInput={ e=>setPassword(e.target.value)} />
                     <TextField 
                         size="small"
                         className="field"
                         id="outlined-sd" 
                         label="Verify Password" 
                         variant="outlined" 
                         type="password"
                         value={verifyPassword}
                         onInput={ i=>setVerifyPassword(i.target.value)} />
                     <Button fullWidth={true} type="submit" variant="contained" className="submit">Submit</Button></form>
            </Card>
        </div>
    )
}
// import React, {useState} from 'react';
// import { Card } from '@material-ui/core';
// import TextField from '@material-ui/core/TextField';
// import Button from '@material-ui/core/Button';
// import {UserAPIService} from '../../config/api-service';
// import { useHistory, Link } from 'react-router-dom';
// import DataConnectorImage from '../../assets/images/dataconnector_sm.png'
// import './style.css';

// export const LoginComponent = () => {
//     const [EmailTXT, setEmail] = useState('');
//     const [PasswordHASH, setPassword] = useState('');
//     const history = useHistory();

//     const handleSubmit = (event) => {
//         event.preventDefault();
        
//         UserAPIService.login({EmailTXT, PasswordHASH})
//             .then(json => {
//                 if (json) {
//                     history.push('/pipelines');
//                 }
//             })
//     }

//     return (
//         <div>
//             <Card raised={true} className='login'>

//                 <div style={{textAlign: 'center'}}>
//                     <img src={DataConnectorImage} alt="Logo" />
//                 </div>

//                 <h3 className="loginLabel">Sign In</h3>

//                 <form noValidate autoComplete="off" onSubmit={handleSubmit} >
//                     <TextField 
//                         size="small"
//                         className="field"
//                         id="outlined-basic" 
//                         label="Email" 
//                         variant="outlined"
//                         value={EmailTXT}
//                         onInput={ e=>setEmail(e.target.value)} />
//                     <TextField 
//                         size="small"
//                         className="field"
//                         id="outlined-sd" 
//                         label="Password" 
//                         variant="outlined" 
//                         type="password"
//                         value={PasswordHASH}
//                         onInput={ i=>setPassword(i.target.value)} />
//                     <div className="forgot">
//                         <Link to="/forgot" >Forgot Password</Link>
//                     </div>
//                     <Button fullWidth={true} type="submit" variant="contained" className="submit">Submit</Button>
//                 </form>

//             </Card>
//         </div>
//     )
// }
