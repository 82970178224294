import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { FormControl, Select, Button, IconButton, Checkbox } from '@mui/material';
import EditPng from '../../../assets/images/user-edit-icon.png';
import DeletePng from '../../../assets/images/delete.png';
import { EventLogAPIService } from '../../../config/api-service';
import Dialog from '../../common/dialog';

const ConnectionDropdownWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative'
})

const ConnectionDropdownItem = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
})

const ConnectionTable = styled('table')({
  width: '100%',
  borderCollapse: 'collapse',
  marginTop: '0.25em',

  '& thead': {
    borderBottom: '1px solid black',
  },
  '& thead tr th': {
    fontSize: '0.75em',
    fontWeight: '400',
    textAlign: 'left',
    textTransform: 'uppercase',
    padding: '0 5px'
  },
  '& thead tr th:first-of-type': {
    paddingLeft: '4px',
  },
  '& thead tr td:last-of-type': {
    paddingRight: '4px',
  },
  '& tbody tr:hover': {
    backgroundColor: '#e2e2e2',
  },
  '& tbody tr td': {
    fontSize: '0.75em',
    fontWeight: '600',
    color: 'var(--data-con-blue)',
    textAlign: 'left',
    padding: '0 5px'
  },
  '& tbody tr td:first-of-type': {
    cursor: 'pointer',
    maxWidth: 90,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  '& tbody tr td:last-of-type': {
    paddingRight: '4px',
  },
  '& tbody tr td button:first-of-type': {
    marginRight: '10px',
  },
  '& tbody tr td button img': {
    width: '14px',
    height: 'auto',
  },
})

const defaultConnectionCb = (connection) => Boolean(connection.connector_default_flg)

export default function ConnectionsDropdown ({
  connections,
  selectConnection,
  createConnection,
  editConnection,
  deleteConnection,
  credentialID,
  connectorID,
  onChangeDefault
}) {
  const { id } = useParams()
  const [isOpened, setIsOpened] = useState(false)
  const onOpen = () => setIsOpened(true)
  const onClose = () => setIsOpened(false)
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [selectedConnection, setSelectedConnection] = useState({})
  const [count, setCount] = useState(0)
  // useEffect(() => {
  //   if (id || !connections || count > 0) return

  //   const defaultConnection = connections.find(defaultConnectionCb)
  //   if (defaultConnection) {
  //     updateConnection(defaultConnection)
  //   }
  //   setCount(count => count + 1)
  //   //eslint-disable-next-line
  // }, [connections])

  // useEffect(() => {
  //   if (id) return
  //   if (!connections?.length) {
  //     updateConnection({}); return
  //   }

  //   let selectedConnection = connections.find(con => +con.connector_credential_id === +credentialID)
  //   if (!selectedConnection) selectedConnection = connections.find(defaultConnectionCb)
  //   if (selectedConnection) {
  //     updateConnection(selectedConnection)
  //   } else {
  //     updateConnection({})
  //   }
  // }, [connections, connectorID])

  // useEffect(() => {
  //   setCount(0)
  // }, [connectorID])

  useEffect(() => {
    if (credentialID) {
      const selectedConnection = connections.find(con => +con.connector_credential_id === +credentialID)
      setSelectedConnection(selectedConnection);
    } else {
      setSelectedConnection({})
    }
    //eslint-disable-next-line
  }, [credentialID, connections])

  // useEffect(() => {
  // }, [selectedConnection])

  const updateConnection = (val) => {
    // setSelectedConnection(val)
    selectConnection(val)
  }

  const onCreateConnection = () => {
    createConnection()
  }

  const onSelectConnection = (connection) => {
    updateConnection(connection)
    setIsOpened(false)
  }

  const onEditConnection = (connection) => {
    editConnection(connection)
    setIsOpened(false)
  }

  const onDeleteConnection = (connection) => {
    deleteConnection(connection)
    setIsOpened(false)
  }

  const handleConnectionDropdownClicked = () => {
    if (!connectorID) {
      let eventMessage = "Connection dropdown was clicked without user selecting a connector.";
      EventLogAPIService.postEvent(eventMessage, null);
    }
  }

  return (<>
    <ConnectionDropdownWrapper>
      <FormControl variant="outlined" size="small" sx={{ width: { xs: 125, sm: 220, md: 250 } }} onClick={handleConnectionDropdownClicked} disabled={!connectorID}>
        <Select
          value={selectedConnection}
          renderValue={(selected) => selected.connection_nm || 'CHOOSE A CONNECTION'}
          open={isOpened}
          onOpen={onOpen}
          onClose={onClose}>
          <ConnectionDropdownItem>
            <Button
              variant="contained"
              sx={{ width: '96%', padding: '0.125em 1em' }}
              onClick={onCreateConnection}>
              New Connection
            </Button>
            {!!connections?.length && (
              <ConnectionTable>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Pipelines</th>
                    <th>Default</th>
                    <th>Modify</th>
                  </tr>
                </thead>
                <tbody>
                  {[...connections].sort((a, b) => a.connection_nm.localeCompare(b.connection_nm)).map((connection) => (
                    <tr key={connection.connection_nm}>
                      <td onClick={() => onSelectConnection(connection)} title={connection.connection_nm}>
                        {connection.connection_nm}
                      </td>
                      <td>{connection.pipeline_ct}</td>
                      <td style={{ textAlign: 'center' }}>
                        <Checkbox
                          size="small"
                          defaultChecked={connection.connector_default_flg}
                          onChange={(e) => {
                            setIsOpened(false);
                            setShowConfirmDialog({ ...connection, checked: e.target.checked })
                          }}
                          sx={{ padding: 0 }}
                        />
                      </td>
                      <td>
                        <IconButton
                          aria-label="edit"
                          size="small"
                          sx={{ padding: '0' }}
                          onClick={() => onEditConnection(connection)}>
                          <img alt="edit" src={EditPng} />
                        </IconButton>
                        <IconButton
                          aria-label="delete"
                          size="small"
                          sx={{ padding: '0' }}
                          onClick={() => onDeleteConnection(connection)}>
                          <img alt="delete" src={DeletePng} />
                        </IconButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </ConnectionTable>
            )}
          </ConnectionDropdownItem>
        </Select>
      </FormControl>
    </ConnectionDropdownWrapper>
    <Dialog
        open={showConfirmDialog}
        handleClose={() => setShowConfirmDialog(false)}
        title="Confirm Action"
        handleConfirm={() => {
          setShowConfirmDialog(false)
          onChangeDefault(showConfirmDialog)
        }}
    >
        {
            showConfirmDialog?.checked
                ? `You are about to make ${showConfirmDialog?.connection_nm} as default connection. Are you sure to make this change?`
                : `You are about to remove ${showConfirmDialog?.connection_nm} from default connection. Are you sure to make this change?`
        }
    </Dialog>

    </>
  );
}
