import { Checkbox, Tooltip } from '@mui/material';
import React from 'react';

export default function SectionDividerComponent({ label, children, checkbox = false, tooltip = "", enabled, checked, backgroundColor = "#fff", labelBackgroundColor = "#fff", disabledCheckbox = false }) {
    return (
        <>
            <div style={{ border: '1px solid #ccc', borderRadius: '5px', position: 'relative', minHeight: '20px', padding: '20px 10px', margin: '10px 0px', backgroundColor: backgroundColor }}>
                <small style={{ backgroundColor: labelBackgroundColor, position: 'absolute', top: '-10px', left: '10px', color: '#aaa', padding: '0 10px' }}>{label}</small>
                {(checkbox) ? (
                    <Tooltip title={tooltip} placement="top">
                        <Checkbox
                            style={{ backgroundColor: labelBackgroundColor, position: 'absolute', top: '-20px', right: '10px' }}
                            checked={enabled}
                            onChange={checked}
                            disabled={disabledCheckbox}
                        />
                    </Tooltip>
                ) : ""}


                {children}
            </div>
        </>
    )
}
