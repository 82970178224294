import React, { useEffect, useState } from 'react';
import { AdminAPIService } from '../../../config/api-service';
import TableComponent from '../../common/table';

function getConnectorLink(params) {
    return <a href={"admin/connector/test/" + params.row.ConnectorID}>Test</a>
}

function getAuthenticationLink(params) {
    return <a href={"admin/connector/auth/" + params.row.ConnectorID}>Edit</a>
}

const columns = [
    { field: "ConnectorID", headerName: "ID", width: 75, },
    { field: "ConnectorNM", headerName: "Name", flex: 1, editable: true, },
    { field: "SourceFLG", headerName: "Source", type: 'boolean', editable: true, },
    { field: "DestinationFLG", headerName: "Destination", type: 'boolean', editable: true, width: 130},
    { field: "ContainerNM", headerName: "Container Name", flex: 1, editable: true, },
    { field: "VersionNBR", headerName: "Version", type: 'number', editable: true, sortable: false, },
    { field: "LambdaFunctionNM", headerName: "Lambda Function", flex: 1, editable: true, },
    { field: "SourceImageTXT", headerName: "Source Image", flex: 1, editable: true, },
    { field: "SupportedFLG", headerName: "Active", type: 'boolean', editable: true, },
    { field: "authentication", headerName: "Authentication", type: 'link', editable: true, renderCell: getAuthenticationLink },
    { field: "test", headerName: "Test", type: 'link', editable: true, renderCell: getConnectorLink },
]

export default function ConnectorsComponent() {
    const [connectors, setConnectors] = useState([]);
    useEffect(() => {
        AdminAPIService.getConnectors().then(connectors => {
            if (connectors && connectors.length) {
                connectors = connectors.map(con => ({ id: con.ConnectorID, ...con }))
                setConnectors(connectors);
            }
        })
    }, []);

    const handleSave = ({row}) => {
        AdminAPIService.updateConnector(row);
    }


    return (<>
        <div className="text title body-title">Connectors</div>
        <TableComponent rows={connectors} columns={columns} editMode="row" handleRowSave={handleSave} />
    </>);
}
