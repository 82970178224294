import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import { ConnectorAPIService, JobAPIService } from '../../config/api-service';
import { withStyles } from '@mui/styles'
import { DataGrid } from '@mui/x-data-grid';
import { Button, CircularProgress } from '@mui/material';
import MaterialUITheme from '../../config/material-ui-theme'
import AlertDialog from "../common/dialog"
import { useConnectorContext } from '../../context/connector.context';
import useScript from '../../config/useScript';


const StyledDataGrid = withStyles({
    root: {
        "& .MuiDataGrid-renderingZone": {
            maxHeight: "none !important",
            overflowY: 'auto'
        },
        "& .MuiDataGrid-cell": {
            maxHeight: "none !important",
            whiteSpace: "normal"
        },
        "& .MuiDataGrid-row": {
            maxHeight: "none !important"
        }
    }
})(DataGrid)

export default function JobLogsComponent () {
    const { id } = useParams()
    const [logs, setLogs] = useState([])
    const [jobDetail, setJobDetail] = useState({})
    const [runStatus, setRunStatus] = useState('')
    const [loadingLogs, setLoadingLogs] = useState(true)
    const { connectors } = useConnectorContext()
    const [sortModel, setSortModel] = useState([
        {
            field: 'LogDTS',
            sort: 'desc'
        }
    ])

    const getLogs = () => {
        JobAPIService.getLogs(id)
            .then(res => {
                if (res?.logs) {
                    setLogs(res.logs)
                    setJobDetail(res?.jobDetail)
                    setRunStatus(res?.pipelineRunHistory?.StatusDSC)
                    
                    // let destinationConnector = connectors.find(con => +con.ConnectorID === +res?.jobDetail?.DestinationConnectorID);

                    // console.log(res?.jobDetail);

                    // console.log(destinationConnector);

                    // console.log(res);

                    // console.log(res?.pipelineRunHistory?.StatusDSC === 2 && destinationConnector?.DestinationDownloadFLG)

                    // if (res?.pipelineRunHistory?.StatusCD === 2 && destinationConnector?.DestinationDownloadFLG) {
                    //     ConnectorAPIService.downloadRunFile(id).catch(err => {
                    //         console.log(err);
                    //     })
                    // }
                }
                setLoadingLogs(false)
            })
    }

    useEffect(() => {
        setLoadingLogs(true)
        getLogs()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [intervalId, setIntervalId] = useState(null)
    useEffect(() => {
        if (!intervalId && runStatus === 'Running') {
            setIntervalId(setInterval(getLogs, 5000))
        }
        return () => clearInterval(intervalId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [runStatus])

    useScript()

    const formatDate = (value) => {
        let MM, dd, yy, hh, mm, ap, formatted = '';
        if (value) {
            let valueDate = new Date(value);
            dd = String(valueDate.getDate()).padStart(2, '0');
            MM = String(valueDate.getMonth() + 1);
            yy = String(valueDate.getFullYear()).substring(2);
            hh = String((valueDate.getHours() > 12) ? valueDate.getHours() - 12 : valueDate.getHours())
            mm = String(valueDate.getMinutes()).padStart(2, '0');
            ap = (valueDate.getHours() > 12) ? 'PM' : 'AM';

            formatted = `${MM}/${dd}/${yy} ${hh}:${mm} ${ap}`
        }

        return formatted;
    }

    const columns = [
        {
            field: 'LogDTS',
            headerName: 'LOG DATE',
            width: 140,
            headerClassName: 'pipelines-table-header',
            valueFormatter: ({ value }) => formatDate(value),
        },

        {
            field: 'ConnectorID',
            headerName: 'CONNECTOR',
            width: 200,
            headerClassName: 'pipelines-table-header',
            valueFormatter: ({ value }) => {
                if (!value || value === "0") {
                    return "General";
                }
                let app = connectors.find(conn => conn.ConnectorID === value);
                return app.ConnectorNM;
            },
        },
        {
            field: 'LogTXT',
            headerName: 'LOG',
            flex: 1,
            renderCell: ({ value }) => (
                <p style={{ whiteSpace: 'normal', lineHeight: 'normal', margin: 0 }}>
                    {value}
                </p>
            ),
            headerClassName: 'pipelines-table-header',
        },
        // {
        //     field: 'GetHelp',
        //     headerName: 'HELPFUL RELATED ARTICLES',
        //     headerClassName: 'pipelines-table-header',
        //     flex: 1,
        //     sortable: false,
        //     renderCell: (props) => (props.row.LogTypeCD === 2) ? [
        //         <Link href={`/logs/${props.id}`} style={{ marginRight: 5 }} target="_blank">
        //             Connection Guide
        //         </Link>,
        //         <Link href={`/logs/${props.id}`} style={{ marginRight: 5 }} target="_blank">
        //             IP Whitelisting
        //         </Link>,
        //         <Link href={`/logs/${props.id}`} style={{ marginRight: 5 }} target="_blank">
        //             Submit a ticket
        //         </Link>
        //     ] : []
        // },

    ]

    const [stopLogDialog, setStopLogDialog] = useState(false)

    const handleOnClickStop = () => {
        setStopLogDialog(true)
    }

    const handleStopLog = () => { setStopLogDialog(false) }

    return (
        <MaterialUITheme>
            <div className="text title" style={{ fontWeight: 400, fontSize: '2.5rem', margin: 15 }}>Run Log</div>
            <div style={{ height: '70vh', width: '90%', margin: '0 auto' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ fontWeight: 500, fontSize: '1.8rem', marginBottom: 10 }}>{jobDetail?.PipelineNM}</div>
                    <div style={{ marginBottom: 10, display: 'flex', alignItems: 'center' }}>
                        {runStatus === 'Running' && <CircularProgress size={24} />}
                        {runStatus === 'Running' && <Button sx={{ ml: 1 }} variant="contained" color="primary" onClick={handleOnClickStop}>Stop</Button>}
                    </div>
                </div>
                <StyledDataGrid
                    rows={logs}
                    columns={columns}
                    disableSelectionOnClick
                    density="compact"
                    disableColumnMenu={true}
                    hideFooter
                    getRowId={row => row.id + Math.random()}
                    getRowClassName={(params) => {
                        let className = '';
                        if (params.getValue(params.id, 'LogTypeCD') === 2) {
                            className = 'pipelines-table-row-failed'
                        }
                        return className;
                    }}
                    loading={loadingLogs}
                    sortModel={sortModel}
                    onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                />
            </div>

            <AlertDialog
                open={stopLogDialog}
                handleConfirm={handleStopLog}
                handleClose={() => setStopLogDialog(false)}
                highlightYes
            >
                Are you sure you want to stop this pipeline?
            </AlertDialog>
        </MaterialUITheme>
    )
}
