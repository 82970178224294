import React from 'react';
import {
    DataGrid,
    GridOverlay,
} from '@mui/x-data-grid';

import {
    Box,
} from '@mui/material';

export default function TableComponent({ height=250, columns = [], rows = [], editMode="cell", loading=false, noRowsMessage="No data was found", handleRowSave=() => {}, disableSelectionOnClick=true, apiRef=false }) {
    const CustomNoRowsOverlay = () => (
        <GridOverlay>
            <Box sx={{ mt: 20 }}>
                {noRowsMessage}
            </Box>
        </GridOverlay>
    );

    return (
        <>
            <div className="table-container" style={{height: height}}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    disableSelectionOnClick={disableSelectionOnClick}
                    density="compact"
                    disableColumnMenu
                    components={{
                        NoRowsOverlay: CustomNoRowsOverlay
                    }}
                    editMode={editMode}
                    hideFooter
                    loading={loading}
                    onRowEditStop={handleRowSave}
                    apiRef={apiRef}
                />
            </div>
        </>
    )
}
