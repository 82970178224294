import LockIcon from '@material-ui/icons/Lock';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { LoadingButton } from '@mui/lab';
import { Box, Checkbox, FormControlLabel, FormGroup, Popper, styled, TextField, Typography } from '@mui/material';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import ReactPasswordChecklist from 'react-password-checklist';
import { toast } from 'react-toastify';
import { UserAPIService } from '../../../config/api-service';
import { APP_KEY, ORGANIZATIONS } from '../../../config/constants';
import { useAppContext } from '../../../context/app.context';
import Dialog from "../../common/dialog";

const CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
        base: {
            iconColor: "#c4f0ff",
            color: "#000",
            fontWeight: 500,
            fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
            fontSize: "16px",
            fontSmoothing: "antialiased",
            ":-webkit-autofill": { color: "#fff" },
            "::placeholder": { color: "#aaa" }
        },
        invalid: {
            iconColor: "#ffc7ee",
            color: "#ffc7ee"
        }
    }
}

const ActionButton = styled(LoadingButton)(({ theme }) => ({
    width: 'calc((100% - 50px) / 2)',
    padding: theme.spacing(1)
}))

const AddOrEditCardModal = ({ open, handleOpen, user, onSuccess }) => {
    const stripe = useStripe()
    const elements = useElements()
    const [preferred, setPreferred] = useState(false)

    const [anchorEl, setAnchorEl] = useState(null);
    const [value, setValue] = useState({
        CompanyNM: "",
        PasswordHASH: "",
        RetypePasswordHASH: "",
    });
    const [passwordValid, setPasswordValid] = useState(false);
    const mediaMatch = window.matchMedia('(max-width: 500px)');
    const { anonymousUserEmail, setAnonymousUserEmail } = useAppContext();
    const [emailError, setEmailError] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [formErrorMessage, setFormErrorMessage] = useState("");
    const [userInfo, setUserInfo] = useState({});
    const [loading, setLoading] = useState(false);

    const openAnchorEl = Boolean(anchorEl);
    const emailReg = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+.[a-zA-Z0-9+.]$/;

    const handleChange = (event) => {
        setValue({ ...value, [event.target.name]: event.target.value });
    };

    useEffect(() => {
        const localStorageData = localStorage.getItem(APP_KEY);
        if (localStorageData) {
            setUserInfo(JSON.parse(localStorageData));
        }
        window.addEventListener('anonymousUserEventChange', () => {
            const localStorageUserData = localStorage.getItem(APP_KEY)
            if (localStorageUserData) {
                setUserInfo(JSON.parse(localStorageUserData))
            }
        })

    }, []);

    const handleSubmit = () => {

        const addPaymentMethod = async () => {
            setLoading(true)
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: "card",
                card: elements.getElement(CardElement),
                billing_details: {
                    email: userInfo?.user?.EmailTXT
                },
            });

            // If that was successful
            if (!error) {
                // Send payment method id along with email to backend to create a stripe customer
                try {
                    const { id } = paymentMethod;
                    const response = await UserAPIService.createPaymentMethod({
                        id,
                        EmailTXT: userInfo?.user?.EmailTXT,
                        paymentMethodId: paymentMethod.id,
                        preferred
                    })

                    if (response?.success) {
                        localStorage.setItem(ORGANIZATIONS, JSON.stringify({
                            ...JSON.parse(localStorage.getItem(ORGANIZATIONS) || '{}'), hasPMethods: true
                        }))
                        setLoading(false)
                        toast.success('Payment method added')
                        onSuccess()
                        handleOpen(false)
                    } else {
                        toast.error('Error')
                        setLoading(false)
                    }
                } catch (err) {
                    toast.error('Error')
                    setLoading(false)
                }
            } else {
                toast.error('Error')
                setLoading(false)
            }
        }

        if (userInfo?.user?.UserAnonymousFlg) {

            setEmailError('')
            setFormErrorMessage('')

            if (!anonymousUserEmail) {
                setEmailError("Email address is required");
                return;
            }
            else if (!emailReg.test(anonymousUserEmail)) {
                return;
            }
            else if (!passwordValid) {
                setFormErrorMessage('Password did not meet the requirements.')
                return;
            }

            try {
                setLoading(true)
                UserAPIService.registerFromDataConnector({ ...value, CompanyNM: value.CompanyNM || anonymousUserEmail, UserID: userInfo.user.UserID, LastNM: '', FirstNM: userInfo.user.FirstNM, EmailTXT: anonymousUserEmail })
                    .then(json => {
                        if (json) {
                            setFormErrorMessage('')
                            setFormSubmitted(true)
                            // setOpenRegisterModal(false);
                            setValue({
                                CompanyNM: "",
                                PasswordHASH: "",
                                RetypePasswordHASH: "",
                            });
                            localStorage.setItem(APP_KEY, JSON.stringify({ ...JSON.parse(localStorage.getItem(APP_KEY)), user: json.user, anonymousRegistered: 1 }));
                            localStorage.setItem(ORGANIZATIONS, JSON.stringify({ ...JSON.parse(localStorage.getItem(ORGANIZATIONS)), organizations: json.organizationList }));
                            window.dispatchEvent(new Event("organizationUpdated"));
                            window.dispatchEvent(new Event("anonymousUserEventChange"));
                            setLoading(false)
                            toast.success('Registration successful. Check your email to verify.');
                        }
                    }).catch(err => {
                        err.text().then(text => setFormErrorMessage(JSON.parse(text).message))
                        setLoading(false)
                    })
            } catch (err) {
                err.text().then(text => setFormErrorMessage(JSON.parse(text).message))
                setLoading(false)
            }

        } else {
            addPaymentMethod()
        }
    };

    const handleClose = () => {
        handleOpen(false)
        setEmailError('')
        setFormErrorMessage('')
    }

    return (
        <Dialog open={!!open} title="Add Credit or Debit Card" handleClose={handleClose}>
            {
                !userInfo?.user?.UserAnonymousFlg &&
                <div className='lock-open-icon-wrap'>
                    <LockOutlinedIcon />
                </div>
            }
            <Box component={'form'}>
                <Popper open={openAnchorEl} anchorEl={anchorEl} placement="top-end" style={{ zIndex: 10000, marginBottom: 5 }}>
                    <div style={{ border: '1px solid #ccc', backgroundColor: '#fff', padding: 5 }}>
                        <ReactPasswordChecklist
                            rules={["minLength", "number", "match"]}
                            minLength={6}
                            value={value.PasswordHASH}
                            valueAgain={value.RetypePasswordHASH}
                            onChange={(isValid) => setPasswordValid(isValid)}
                            iconSize={mediaMatch ? 10 : 12}
                            className={mediaMatch.matches ? "size-10" : "size-12"}
                            messages={{ minLength: "Password has at least 6 characters" }}
                        />
                    </div>
                </Popper>

                {userInfo?.user?.UserAnonymousFlg && <TextField
                    fullWidth
                    sx={{ mb: 2, mt: 2 }}
                    variant="outlined"
                    size="small"
                    placeholder="Email"
                    type='email'
                    value={anonymousUserEmail}
                    onChange={(e) => setAnonymousUserEmail(e.target.value)}
                    name="EmailTXT"
                    error={emailError !== "" || (!emailReg.test(anonymousUserEmail) && anonymousUserEmail !== "")}
                    label={
                        emailError ? emailError : !emailReg.test(anonymousUserEmail) && anonymousUserEmail !== "" ? "Invalid Email!" : "Email"
                    }
                />}
                {userInfo?.user?.UserAnonymousFlg && <TextField
                    fullWidth
                    sx={{ mb: 2 }}
                    variant="outlined"
                    size="small"
                    placeholder="Organization Name"
                    label="Organization Name"
                    type='text'
                    value={value.CompanyNM}
                    onChange={handleChange}
                    name="CompanyNM"
                />}

                {userInfo?.user?.UserAnonymousFlg && <TextField
                    fullWidth
                    sx={{ mb: 2 }}
                    size="small"
                    name="PasswordHASH"
                    label="Password"
                    variant="outlined"
                    type="password"
                    value={value.PasswordHASH}
                    error={!value.PasswordHASH && formSubmitted}
                    onFocus={(event) => setAnchorEl(anchorEl ? null : event.currentTarget)}
                    onBlur={(event) => setAnchorEl(anchorEl ? null : event.currentTarget)}
                    onChange={handleChange} />}
                {userInfo?.user?.UserAnonymousFlg && <TextField
                    fullWidth
                    size="small"
                    name="RetypePasswordHASH"
                    label="Retype Password"
                    variant="outlined"
                    type="password"
                    value={value.RetypePasswordHASH}
                    error={!value.RetypePasswordHASH && formSubmitted}
                    onFocus={(event) => setAnchorEl(anchorEl ? null : event.currentTarget)}
                    onBlur={(event) => setAnchorEl(anchorEl ? null : event.currentTarget)}
                    onChange={handleChange}
                />}

                {
                    !userInfo?.user?.UserAnonymousFlg &&
                    <>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', fontSize: 12, pb: .5 }}>
                            <span className='size-12' style={{ color: "#7a7a7a" }}>Secure Server</span>
                            <LockIcon style={{ fill: '#FFB53F', fontSize: 15, marginLeft: 3 }} />
                        </Box>
                        <fieldset style={{ border: '2px solid #00bfff', borderRadius: 5 }}>
                            <CardElement options={CARD_OPTIONS} />
                        </fieldset>

                        <FormGroup row>
                            <FormControlLabel
                                sx={{ ml: 0.25 }}
                                control={<Checkbox value={preferred} onChange={(e) => setPreferred(e.target.checked)} />}
                                label="Make preferred"
                                labelPlacement="start"
                            />
                        </FormGroup>
                    </>
                }

                <Typography color="error" sx={{ my: 1, textAlign: "center" }}>{formErrorMessage}</Typography>
                {userInfo?.user?.UserAnonymousFlg ? <Box sx={{ textAlign: "right" }}>
                    <ActionButton variant="contained" onClick={handleSubmit} loading={loading}>
                        Next
                    </ActionButton>
                </Box> : <Box sx={{ display: "flex", justifyContent: 'space-between' }}>
                    <ActionButton variant="contained" onClick={handleSubmit} loading={loading}>
                        Yes
                    </ActionButton>
                    <ActionButton variant="contained" sx={{ backgroundColor: '#aaa' }} onClick={handleClose}>
                        No
                    </ActionButton>
                </Box>}
            </Box>

            {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end', fontSize: 12, pb: .5 }}>
                <span className='size-12' style={{ color: "#7a7a7a" }}>Secure Server</span>
                <LockIcon style={{ fill: '#FFB53F', fontSize: 15, marginLeft: 3 }} />
            </Box> */}
            {/* <Box component="form">
                <fieldset style={{ border: '2px solid #00bfff', borderRadius: 5 }}>
                    <CardElement options={CARD_OPTIONS} />
                </fieldset>

                <FormGroup row>
                    <FormControlLabel
                        sx={{ ml: 0.25 }}
                        control={<Checkbox value={preferred} onChange={(e) => setPreferred(e.target.checked)} />}
                        label="Make preferred"
                        labelPlacement="start"
                    />
                </FormGroup>
            </Box> */}
        </Dialog>
    );
}

export default AddOrEditCardModal;