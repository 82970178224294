import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom"
import { TextField, Grid, Container } from '@mui/material'
import ConfigureScheduleComponent from './Schedule';
import ConfigureNotificationsComponent from './Notifications';
import ConfigureGeneralComponent from './General';
import ConfigureFiltersComponent from './Filters'
import { usePipelineContext } from "../pipeline.context";
import ConfigureTestRunComponent from './TestRun';
import { ConfigureAPIService, OrganizationAPIService } from '../../../config/api-service';
import momentTZ from 'moment-timezone';

export const ConfigureConnectorComponent = () => {
    const {
        sourceState,
        jobName,
        setJobName,
        jobDataID,
        setJobDataID,
        destinationState,
        jobID,
        setJobID,
        method,
        runPeriod,
        minute,
        time,
        weekDay,
        monthDay,
        month,
        scheduleID,
        setScheduleID,
        dateRangeID,
        notificationID,
        scheduleEnabled,
        deployDisabled,
        setDeployDisabled,
    } = usePipelineContext();

    const { id } = useParams()

    const [jobNameFieldValue, setJobNameFieldValue] = useState(jobName);
    const [organization, setOrganization] = useState({})
    useEffect(() => {
        OrganizationAPIService.getOrganizationDetail()
            .then(res => {
                setOrganization(res.organization)
            }).catch(e => {
                console.error("fail to load organization")
            })
    }, [])

    // SET Schedule
    useEffect(() => {
        if (id || !Object.keys(organization)?.length) return

        let hourOffset = momentTZ(new Date()).tz(organization?.TimezoneTXT).utcOffset() / 60

        let payload = {
            period: runPeriod,
            minute,
            time,
            weekDay,
            monthDay,
            month,
            hourOffset,
            scheduleEnabled
        }

        if (runPeriod !== 6) {
            if (scheduleID) {
                ConfigureAPIService.putSchedule(payload, scheduleID).then(res => {
                    setScheduleID(res.scheduleID);
                });
            } else {
                ConfigureAPIService.putSchedule(payload).then(res => {
                    setScheduleID(res.scheduleID);
                });
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [runPeriod, minute, time, weekDay, monthDay, month, scheduleEnabled, organization]);

    // SET Job Data
    useEffect(() => {
        if (!id && (destinationState.connector?.DestinationDownloadFLG || destinationState.selectedObject) && sourceState.selectedObject) {
            let payload = {
                mapping: destinationState.mapping.filter(row => row.column),
                sourceObject: sourceState.selectedObject,
                destinationObject: destinationState.selectedObject,
                updateMethod: method,
                optionObjectJson: sourceState.options,
            }

            if (jobDataID) {
                ConfigureAPIService.putMapping(payload, jobDataID)
            } else {
                ConfigureAPIService.postMapping(payload).then(res => {
                    if (res) {
                        setJobDataID(res.JobDataID);
                    }
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [method]);

    // SET ETL Job Configuration after Job Data, Schedule or on job name
    useEffect(() => {
        if (id)  return;

        let payload = {
            jobName,
            sourceConnectorID: sourceState.connectorId,
            destinationConnectorID: destinationState.connectorId,
            sourceCredentialID: sourceState.credentialId,
            destinationCredentialID: destinationState.credentialId,
            jobDataID,
            scheduleID,
            dateRangeID,
            notificationID
        }

        if (jobDataID && scheduleID && jobDataID) {
            ConfigureAPIService.putJob(payload, jobID)
                .then(res => {
                    if (res) {
                        setJobID(res.PipelineID);
                    }
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobName, jobDataID, scheduleID, dateRangeID, notificationID]) // TODO: Add Schedule ID

    useEffect(() => {
        if (jobNameFieldValue && deployDisabled) setDeployDisabled(false)
        else if (!jobNameFieldValue && !deployDisabled) setDeployDisabled(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobNameFieldValue])

    return (<Container>
        <Grid container spacing={2}>
            <Grid item xs={12} md={8} >
                <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    label="Pipeline Name"
                    value={jobNameFieldValue}
                    onChange={(e) => setJobNameFieldValue(e.target.value)}
                    onBlur={() => {
                        setJobName(jobNameFieldValue)
                    }}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <ConfigureGeneralComponent />
            </Grid>
            <Grid item xs={12} md={4}>
                <ConfigureFiltersComponent jobID={id} />
            </Grid>
            <Grid item xs={12} md={4}>
                <ConfigureScheduleComponent timezone={organization.TimezoneTXT} />
            </Grid>
            <Grid item xs={12} md={4}>
                <ConfigureNotificationsComponent jobID={id} />
            </Grid>
            <Grid item xs={12}>
                <ConfigureTestRunComponent jobName={jobNameFieldValue} />
            </Grid>
        </Grid>
    </Container>)
}



