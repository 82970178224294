import React from 'react';
import { FormControl, InputLabel, Select, FormHelperText, MenuItem } from '@mui/material';

export default function SelectComponent ({ label, value, setValue, items, helperText, disabled }) {
    return (
        <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel>{label}</InputLabel>
            <Select
                value={value}
                label={label}
                onChange={(e) => setValue(e.target.value)}
                fullWidth
                disabled={disabled}
            >
                {items.map((item) => (
                    <MenuItem key={item.id} value={item.value}>{item.label}</MenuItem>
                ))}
            </Select>
            {helperText ? (
                <FormHelperText>{helperText}</FormHelperText>
            ) : ''}
        </FormControl>
    )
}

// GMT	Greenwich Mean Time	GMT
// UTC	Universal Coordinated Time	GMT
// ECT	European Central Time	GMT+1:00
// EET	Eastern European Time	GMT+2:00
// ART	(Arabic) Egypt Standard Time	GMT+2:00
// EAT	Eastern African Time	GMT+3:00
// MET	Middle East Time	GMT+3:30
// NET	Near East Time	GMT+4:00
// PLT	Pakistan Lahore Time	GMT+5:00
// IST	India Standard Time	GMT+5:30
// BST	Bangladesh Standard Time	GMT+6:00
// VST	Vietnam Standard Time	GMT+7:00
// CTT	China Taiwan Time	GMT+8:00
// JST	Japan Standard Time	GMT+9:00
// ACT	Australia Central Time	GMT+9:30
// AET	Australia Eastern Time	GMT+10:00
// SST	Solomon Standard Time	GMT+11:00
// NST	New Zealand Standard Time	GMT+12:00
// MIT	Midway Islands Time	GMT-11:00
// HST	Hawaii Standard Time	GMT-10:00
// AST	Alaska Standard Time	GMT-9:00
// PST	Pacific Standard Time	GMT-8:00
// PNT	Phoenix Standard Time	GMT-7:00
// MST	Mountain Standard Time	GMT-7:00
// CST	Central Standard Time	GMT-6:00
// EST	Eastern Standard Time	GMT-5:00
// IET	Indiana Eastern Standard Time	GMT-5:00
// PRT	Puerto Rico and US Virgin Islands Time	GMT-4:00
// CNT	Canada Newfoundland Time	GMT-3:30
// AGT	Argentina Standard Time	GMT-3:00
// BET	Brazil Eastern Time	GMT-3:00
// CAT	Central African Time	GMT-1:00