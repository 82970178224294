import { useEffect } from 'react';

const useScript = () => {

    useEffect(() => {
        const script = document.createElement('script');

        script.src = "https://www.googletagmanager.com/gtag/js?id=G-W967ZFK3DG";
        script.async = true;

        window.dataLayer = window.dataLayer || [];
        function gtag() { window.dataLayer.push(arguments); }
        gtag('js', new Date());

        gtag('config', 'G-W967ZFK3DG');

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);
};

export default useScript;



