import React, { useState, useEffect } from 'react';
import { Checkbox, FormControlLabel, Button, InputAdornment } from '@mui/material';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form'
import { ConnectorAPIService } from '../../../config/api-service';
import { usePipelineContext } from "../pipeline.context";
import DataConTextField from '../../common/data-con-form/FormFields'
import { CONNECTOR_REDUCER_TYPES } from '../../../reducers/connectorReducer';

export default function FieldBasedModal ({ app, authenticationMethod: { WhitelistingFLG, fields}, saveConnection, loading, connection_nm, connector_default_flg }) {

    const { handleSubmit, control, reset } = useForm({
        mode: 'all',
        defaultValues: {
            connectionName: connection_nm || ''
        }
    })

    const [inputs, setInputs] = useState({
        defaultAccount: connector_default_flg === 1 || false
    })
    const handleCheck = (event) => {
        setInputs({ ...inputs, [event.target.name]: event.target.checked })
    }

    useEffect(() => {
        let data = {
            connectionName: connection_nm || ''
        }
        fields.forEach(({ FieldKeyNM, FieldDefaultTXT: val }) => {
            data = { ...data, [FieldKeyNM]: val }
        })
        reset(data);
        //eslint-disable-next-line
    }, [fields])

    const handleSave = (values) => {
        const payload = {
            authenticationMethod: 1,
            ...values
        }

        saveConnection({connectorId: app.ConnectorID, payload});
    }

    return (
        <div style={{ paddingTop: 0 }}>
            <p style={{ marginBottom: 20, textAlign: "center" }}>Use the form below to authenticate with {app.ConnectorNM}</p>
            <form autoComplete="off">
                <DataConTextField
                    className="field"
                    autoComplete="off"
                    size="small"
                    fullWidth
                    variant="outlined"
                    label="Connection Name"
                    type="text"
                    name="connectionName"
                    required
                    control={control}
                    rules={{ required: `Connection Name is required` }}
                />
                {fields.map(({ FieldKeyNM, FieldLabelTXT, FieldTypeTXT, FieldDefaultTXT: val, FieldHintTXT, FieldPlaceholderTXT, FieldRequiredFLG, FieldEndAdornmentTXT }) => (
                    <DataConTextField
                        key={FieldKeyNM}
                        autoComplete="new-password"
                        className="field"
                        size="small"
                        fullWidth
                        variant="outlined"
                        label={FieldLabelTXT}
                        type={FieldTypeTXT}
                        name={FieldKeyNM}
                        placeholder={FieldPlaceholderTXT}
                        defaultValue={val}
                        required={+FieldRequiredFLG === 1}
                        control={control}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{FieldEndAdornmentTXT || ""}</InputAdornment>,
                        }}
                        rules={FieldRequiredFLG ? { required: `${FieldLabelTXT} is required` } : null}
                    />
                ))}
                {WhitelistingFLG ? <>
                    <p><span style={{ color: 'red' }}>IMPORTANT</span><br /> Make sure to add our Public IP Address to allow access to any IP restricted firewall servers. <br /><b>3.143.20.235</b><br /></p>
                    <Link to="">Learn More..</Link>
                </> : ""}
                <div>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="defaultAccount"
                                color="primary"
                                checked={inputs['defaultAccount']}
                                onChange={handleCheck}
                            />
                        }
                        label="Default Account"
                    />
                </div>

                <Button type="submit" variant="outlined" color="primary" style={{ marginTop: "5px" }} disabled={loading} onClick={handleSubmit(handleSave)}>
                    Add Connection
                </Button>
            </form>
        </div>
    )
}
