import React, { useEffect, useState } from 'react';
import { FormGroup, FormControlLabel, Checkbox, TextField, Autocomplete, Chip, Tooltip, Modal, Box, Typography, Divider } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { usePipelineContext } from "../pipeline.context";
import SectionDividerComponent from '../../common/section-divider';
import { AccountAPIService, ConfigureAPIService } from '../../../config/api-service';
import EmailSettings from '../../account/email/EmailSettings';

export default function ConfigureNotificationsComponent({ jobID }) {
    const {
        failureFLG,
        setFailureFLG,
        successFLG,
        setSuccessFLG,
        timeoutFLG,
        email,
        setEmail,
        notificationID,
        setNotificationID
    } = usePipelineContext();

    const [preferences, setPreferences] = useState({});
    const [user, setUser] = useState();
    const [modalOpen, setModalOpen] = useState(false);
    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);

    const getPreferences = async () => {
        const obj = await AccountAPIService.getEmailPreference();
        if (obj && Object.keys(obj).length) {
            setPreferences(obj);
        }
    };

    useEffect(() => {
        if (jobID) return

        let payload = {
            EmailTXT: email.join(","),
            SuccessFLG: successFLG ? 1 : 0,
            FailureFLG: failureFLG ? 1 : 0,
        };

        ConfigureAPIService.putNotification(payload, notificationID)
            .then(res => {
                setNotificationID(res.NotificationID);
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email, successFLG, failureFLG, timeoutFLG])

    useEffect(() => {
        getPreferences();
        const user = JSON.parse(localStorage.getItem('DATA_CONNECTOR'));
        if (user) {
            setUser(user.user);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalOpen, email]);

    return (<>
        <SectionDividerComponent label="Notifications" noPadding={false}>
            <Autocomplete
                multiple
                options={[]}
                defaultValue={email}
                freeSolo
                autoSelect
                onChange={(event, newValue) => {
                    setEmail(newValue)
                }}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Tooltip
                            title={option === user?.EmailTXT && !preferences?.PipelineRunNotificationFLG ? <p>{`${option} has pipeline run notifications turned off. Change on `} <span style={{ textDecoration: "underline", cursor: "pointer" }} onClick={handleModalOpen}>Email Settings.</span></p> : ""}
                            placement="top" arrow>
                            <Chip
                                sx={{ backgroundColor: option === user?.EmailTXT && !preferences?.PipelineRunNotificationFLG && "rgba(214, 5, 5, 0.39)" }}
                                variant="standard"
                                label={option}
                                {...getTagProps({ index })}
                            />
                        </Tooltip>
                    ))}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        placeholder="Emails"
                        helperText="Add emails by using Enter"
                    />
                )}
            />

            <FormGroup style={{ fontSize: "0.875rem", margin: '-1px 20px' }} row>
                <FormControlLabel control={<Checkbox size="small" checked={successFLG} onChange={(e) => setSuccessFLG(e.target.checked)} />} label="Success" />
                <FormControlLabel control={<Checkbox size="small" checked={failureFLG} onChange={(e) => setFailureFLG(e.target.checked)} />} label="Error" />
            </FormGroup>
            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    // width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    py: 3,
                    borderRadius: "10px"
                }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", px: 3 }}>
                        <Typography sx={{ color: "#444444", fontSize: "20px", fontWeight: 600 }}>Email Settings</Typography>
                        <CloseIcon onClick={handleModalClose} />
                    </Box>
                    <Divider sx={{ my: 2 }} />
                    <EmailSettings headerDisplay="none" />
                </Box>
            </Modal>
        </SectionDividerComponent>

    </>);
}
