import React from 'react';
// import { ThemeProvider } from '@material-ui/styles';
// import { createMuiTheme } from '@material-ui/core/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function MaterialUITheme({children}) {
    const theme = createTheme({
        palette: {
            primary: {
                main: '#56b2e3',
                contrastText: '#fff'
            },
            secondary: {
                main: '#00bfff',
            },
        },
        typography: {
            fontFamily: ['"quicksand-vf"', 'sans-serif'].join(','),
            fontSize: 14
        }
    });

    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    );
}
