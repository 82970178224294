import React, { useEffect } from "react";
import AuthenticationDetail from "./AuthenticationDetail";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export default function AuthenticationMethod(props) {
	const [authenticationType, setAuthenticationType] = React.useState(props.authenticationMethods[0].AuthenticationDetailID);
	const [authenticationMethod, setAuthenticationMethod] = React.useState(props.authenticationMethods[0]);

	useEffect(() => {
		if (props.authenticationMethods && props.authenticationMethods.length)
	  		setAuthenticationMethod(props.authenticationMethods[0])
	}, [props.authenticationMethods])
	

	const changeAuthenticationType = (e) => {
		setAuthenticationType(e.target.value);
		const newAuthenticationMethod = props.authenticationMethods.find(am => +am.AuthenticationDetailID === +e.target.value);
		setAuthenticationMethod(newAuthenticationMethod);
	}

	return (
		<div>
			{props.authenticationMethods.length > 1 ? (<>
				<FormControl 
					fullWidth
					size="small">
					<InputLabel id="demo-simple-select-label">Authentication Type</InputLabel>
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value={authenticationType}
						label="Authentication Type"
						onChange={changeAuthenticationType}
					>
						{props.authenticationMethods.map(({AuthenticationDetailID, AuthenticationDetailNM}) => (
							<MenuItem value={AuthenticationDetailID}>{AuthenticationDetailNM}</MenuItem>
						))}
					</Select>
				</FormControl>

				<AuthenticationDetail {...props} authenticationMethod={authenticationMethod} />
			</>) : props.authenticationMethods.length > 0 ? (<>
				<AuthenticationDetail {...props} authenticationMethod={authenticationMethod} />
			</>) : null}
		</div>
	);
}
