import { useEffect, useState } from 'react'
import { MenuItem, Select, InputLabel, FormControl } from '@mui/material'
import { parseISO, format } from 'date-fns'

export default function RangeSelect({ list, onChange, setIsTrial }) {
  const [value, setValue] = useState('')

  useEffect(() => {
    if (list?.length) setValue(list[0]?.billing_end_dt)
  }, [list])

  const handleChange = (date) => {
    setValue(date)
    onChange(list.find(v => v.billing_end_dt === date))
    const index = list.findIndex(v => v.billing_end_dt === date);
    setIsTrial(index + 1 === list.length)
  }

  return (
    <FormControl size="small" sx={{ minWidth: 200 }}>
      <InputLabel id="billing-statement-range-select-label">Billing Statement</InputLabel>
      <Select
        labelId="billing-statement-range-select-label"
        id="billing-statement-range-select"
        value={value}
        label="Billing Statement"
        onChange={(e) => handleChange(e.target.value)}
      >
        {
          list.map(({ billing_start_dt, billing_end_dt }, i) => <MenuItem key={i} value={billing_end_dt} sx={{ color: list.length - 1 === i && "#4caf50" }}>{format(parseISO(billing_start_dt), 'MM/dd/yyyy')} - {format(parseISO(billing_end_dt), 'MM/dd/yyyy')}</MenuItem>)
        }
      </Select>
    </FormControl>
  )
}