import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, IconButton, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-container': {
        alignItems: 'flex-start'
    },
    '& .MuiDialog-paper': {
        borderRadius: 10,
        marginTop: 190
    },
    '& .MuiDialogContent-root': {
        padding: theme.spacing(4),
        fontSize: '1.2rem',
        maxHeight: '80vh'
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(4),
        paddingTop: theme.spacing(0)
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2, fontWeight: 600 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

const ActionButton = styled(LoadingButton)(({ theme }) => ({
    width: 'calc((100% - 50px) / 2)',
    padding: theme.spacing(1)
}))

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};


const CancelMembershipModal = ({ open, handleClose, highlightYes, handleConfirm }) => {

    const [cancelReasonTxt, setCancelReasonTxt] = useState('');
    const [otherCancelReasonTxt, setOtherCancelReasonTxt] = useState('');
    const [loading, setLoading] = useState(false);

    const onClickYes = () => {
        setLoading(true)
        if (cancelReasonTxt === "Other") {
            handleConfirm(otherCancelReasonTxt)
        } else {
            handleConfirm(cancelReasonTxt)
        }
    }

    const handleCancelReasonTxt = (event) => {
        setCancelReasonTxt(event.target.value);
    };

    useEffect(() => {
        setLoading(false)
        setCancelReasonTxt("")
        setOtherCancelReasonTxt("")
    }, [open])



    return (
        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                Confirm
            </BootstrapDialogTitle>
            <DialogContent dividers sx={{
                borderBottom: 'unset',
            }}>
                <Typography gutterBottom sx={{ fontFamily: "var(--main-font)" }}>
                    We are sad to see you go. Please tell us your reason so we can improve our product for you and others.
                </Typography>

                <FormControl >
                    <RadioGroup
                        value={cancelReasonTxt}
                        onChange={handleCancelReasonTxt}
                    >
                        <FormControlLabel sx={{ "& .MuiTypography-root": { fontFamily: "var(--main-font)" } }} value="Too Expensive" control={<Radio />} label="Too Expensive" />
                        <FormControlLabel sx={{ "& .MuiTypography-root": { fontFamily: "var(--main-font)" } }} value="Not a priority for my business at this time" control={<Radio />} label="Not a priority for my business at this time" />
                        <FormControlLabel sx={{ "& .MuiTypography-root": { fontFamily: "var(--main-font)" } }} value="Performance issues" control={<Radio />} label="Performance issues" />
                        <FormControlLabel sx={{ "& .MuiTypography-root": { fontFamily: "var(--main-font)" } }} value="Bug/Error issues" control={<Radio />} label="Bug/Error issues" />
                        <FormControlLabel sx={{ "& .MuiTypography-root": { fontFamily: "var(--main-font)" } }} value="Not enough connectors" control={<Radio />} label="Not enough connectors" />
                        <FormControlLabel sx={{ "& .MuiTypography-root": { fontFamily: "var(--main-font)" } }} value="Other" control={<Radio />} label={
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Typography sx={{ mr: 1 }}>
                                    Other
                                </Typography>
                                <TextField
                                    sx={{ "& .MuiInput-input": { fontFamily: "var(--main-font)" } }}
                                    size='small'
                                    variant="standard"
                                    placeholder='Please specify'
                                    value={otherCancelReasonTxt}
                                    onChange={(e) => setOtherCancelReasonTxt(e.target.value)}
                                    disabled={cancelReasonTxt !== "Other"}
                                />
                            </Box>
                        } />
                    </RadioGroup>
                </FormControl>


            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-around' }}>
                {/* <Button autoFocus onClick={handleClose}>
                    Save changes
                </Button> */}
                <ActionButton variant="contained" sx={{ backgroundColor: highlightYes || '#aaa' }} onClick={onClickYes} loading={loading} disabled={!cancelReasonTxt}>
                    Cancel Membership
                </ActionButton>
                <ActionButton variant="contained" sx={{ backgroundColor: highlightYes && '#aaa' }} onClick={handleClose}>
                    Keep Membership
                </ActionButton>
            </DialogActions>
        </BootstrapDialog>
    );
};

export default CancelMembershipModal;