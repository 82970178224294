import { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

export default function UpDownVote ({ voteCount = 0, state, handleVoting }) {
  const [counter, setCounter] = useState(0)
  useEffect(() => {
    setCounter(+voteCount)
  }, [voteCount])

  const [upVote, setUpVote] = useState(false)
  const handleUpVote = () => {
    setUpVote((prevState) => {
      setDownVote(false)

      if (prevState) setCounter(prevCount => --prevCount)
      else setCounter(prevCount => ++prevCount)

      handleVoting({ upVote: !prevState, downVote: false })
      return !prevState
    })
  }

  const [downVote, setDownVote] = useState(false)
  const handleDownVote = () => {
    setDownVote((prevState) => {
      setUpVote(false)

      if (prevState) setCounter(prevCount => ++prevCount)
      else setCounter(prevCount => --prevCount)

      handleVoting({ upVote: false, downVote: !prevState })
      return !prevState
    })
  }

  useEffect(() => {
    if (state) {
      if (+state === 1) setUpVote(true)
      else if (+state === -1) setDownVote(true)
    }
  }, [state])

  return (
    <Box sx={{
      display: 'inline-flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '& .MuiSvgIcon-root': { cursor: 'pointer' }
    }}>
      {
        upVote
          ? <ArrowDropUpIcon onClick={handleUpVote} color={'primary'} viewBox="4 0 16 16" />
          : <KeyboardArrowUpIcon onClick={handleUpVote} viewBox="4 0 16 16" />
      }
      <Typography fontSize="1rem" fontWeight={600}>
        {counter > 1000 ? `${(counter / 1000).toFixed(2)}k` : counter}
      </Typography>
      {
        downVote
          ? <ArrowDropDownIcon onClick={handleDownVote} color={'error'} viewBox="4 6 16 16" />
          : <KeyboardArrowDownIcon onClick={handleDownVote} viewBox="4 6 16 16" />
      }
    </Box>
  )
}