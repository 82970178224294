import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { Button, IconButton, Tooltip } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import amexIcon from "../../../assets/images/card/amex_icon.png";
import discoverCardIcon from "../../../assets/images/card/discovercard_icon.png";
import masterCardIcon from "../../../assets/images/card/mastercard_icon.png";
import visaIcon from "../../../assets/images/card/visa_icon.png";
import { OrganizationAPIService, UserAPIService } from '../../../config/api-service';
import { APP_KEY, ORGANIZATIONS } from '../../../config/constants';
import { CommonLoaderContext } from "../../../config/router";
import { OrganizationContext } from '../../../context/organization.context';
import AlertDialog from "../../common/dialog";
import AddPaymentMethodForm from "./AddPaymentMethodForm";
import UpdatePaymentMethodForm from "./UpdatePaymentMethodForm";

import Register from '../../navigation/Register';
import CancelMembershipModal from './CancelMembershipModal';

const { REACT_APP_STRIPE_CLIENT_PUBLIC } = process.env;

const getCardType = (cardType) => {
    switch (cardType) {
        case "amex":
            return amexIcon;
        case "discover":
            return discoverCardIcon;
        case "mastercard":
            return masterCardIcon;
        default:
            return visaIcon

    }
}

const stripePromise = loadStripe(REACT_APP_STRIPE_CLIENT_PUBLIC)

export default function BillingSettingsComponent () {
    const [cancelModalShow, setCancelModalShow] = React.useState(false)
    const [reactivateModalShow, setReactivateModalShow] = React.useState(false)
    const [removePMModalShow, setRemovePMModalShow] = React.useState(null);
    const [openAddPMmodal, setOpenAddPMmodal] = React.useState(false);
    const [openUpdatePMmodal, setOpenUpdatePMmodal] = React.useState(false);
    const [paymentMethodObj, setPaymentMethodObj] = React.useState({});
    const [cardList, setCardList] = React.useState(null);
    const [defaultPaymentMethod, setDefaultPaymentMethod] = React.useState(null);
    const [, setCommonLoader] = useContext(CommonLoaderContext);
    const { checkUserActiveStatus } = useContext(OrganizationContext)
    const [user, setUser] = React.useState({});
    const [organization, setOrganization] = React.useState({})
    const [openRegisterModal, setOpenRegisterModal] = React.useState(false);
    const [userInfo, setUserInfo] = React.useState({});

    useEffect(() => {
        const localStorageData = localStorage.getItem(APP_KEY);
        if (localStorageData) {
            setUserInfo(JSON.parse(localStorageData));
        }
        window.addEventListener('anonymousUserEventChange', () => {
            const localStorageUserData = localStorage.getItem(APP_KEY);
            if (localStorageUserData) {
                setUserInfo(JSON.parse(localStorageUserData));
            }
        })
    }, []);

    const loadCardList = () => {
        setCommonLoader(true);
        UserAPIService.getPaymentMethod()
            .then(({ customer: { invoice_settings: { default_payment_method } }, paymentMethods: { data } }) => {
                setCardList(data)
                setDefaultPaymentMethod(default_payment_method)
                setCommonLoader(false);
            }).catch(e => {
                console.error(e)
                setCommonLoader(false)
            })
    }

    const setLocalStorageData = (organization) => {
        const { selectedOrganization, organizations, hasPMethods } = JSON.parse(localStorage.getItem(ORGANIZATIONS) || '{}')
        const newOrgList = organizations.map((org) => {
            if (org?.organization_id === organization.OrganizationID) {
                return {
                    ...org,
                    active_flg: organization?.ActiveFLG
                }
            }
            return org
        })
        localStorage.setItem(ORGANIZATIONS, JSON.stringify({ ...JSON.parse(localStorage.getItem(ORGANIZATIONS) || '{}'), selectedOrganization, organizations: newOrgList, hasPMethods: cardList ? (cardList?.length > 0 ? true : false) : hasPMethods }))
        checkUserActiveStatus()
    }

    const getOrganizationDetail = async () => {
        const { organization } = await OrganizationAPIService.getOrganizationDetail()
        setOrganization(organization)

        setLocalStorageData(organization)
    }

    useEffect(() => {
        const initialCalls = async () => {
            setCommonLoader(true)
            const [
                { user },
                { organization }
            ] = await Promise.all([
                UserAPIService.getUserDetail(),
                OrganizationAPIService.getOrganizationDetail()
            ])
            setUser(user)
            setOrganization(organization)
            setLocalStorageData(organization)
            setCommonLoader(false)
        }
        loadCardList()
        initialCalls()

    }, [])



    const handleCancelMembership = async (cancelReasonTxt) => {
        await UserAPIService.cancelMembership(organization?.OrganizationID, { cancelReasonTxt })
        getOrganizationDetail()
        setCancelModalShow(false)
    }
    const handleReactivateMembership = async () => {
        await UserAPIService.reactivateMembership(organization?.OrganizationID)
        getOrganizationDetail()
        setReactivateModalShow(false)
    }

    const handleEditPMmethod = async (data) => {
        setOpenUpdatePMmodal(!!Object.keys(data)?.length && true)
        setPaymentMethodObj(data)
    }

    const handleMakePreffered = async (cpmid) => {
        await UserAPIService.setDefaultPaymentMethod({ cpmid })
        toast.success('Payment method updated')
        loadCardList()
    }
    const handleRemovePaymentMethod = async (cpmid) => {
        await UserAPIService.removePaymentMethod(cpmid)
        toast.success('Deleted payment method')
        setRemovePMModalShow(null)
        loadCardList()
    }

    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: '20px' }}>
            <div style={{ width: '80%' }}>
                <div className='billing-section-header'>
                    <span>Billing  Settings</span>
                </div>
                <hr className='billing-section-hr' />
                <div className='billing-section-title'>Membership &amp; Billing</div>
                <div className='billing-section-card-btn-area'>
                    {
                        userInfo?.user?.UserAnonymousFlg
                        ?
                        <Button
                            sx={{ alignSelf: 'flex-start', textTransform: 'capitalize' }}
                            variant="contained"
                            color="inherit"
                            onClick={() => setOpenRegisterModal(true)}
                        >Start Membership</Button>
                        :
                        !!organization?.ActiveFLG
                        ? <Button
                            disabled={user?.UserID !== organization?.MainContactUserID}
                            sx={{ alignSelf: 'flex-start', textTransform: 'capitalize' }}
                            disableElevation
                            variant="contained"
                            color="inherit"
                            onClick={() => setCancelModalShow(true)}
                        >Cancel Membership</Button>
                        : !!cardList
                            ? <Button
                                disabled={user?.UserID !== organization?.MainContactUserID}
                                sx={{ alignSelf: 'flex-start', textTransform: 'capitalize' }}
                                disableElevation
                                variant="contained"
                                color="primary"
                                onClick={() => setReactivateModalShow(true)}
                            >Reactivate Membership</Button>
                            : <Button
                                disabled={user?.UserID !== organization?.MainContactUserID}
                                sx={{ alignSelf: 'flex-start', textTransform: 'capitalize' }}
                                disableElevation
                                variant="contained"
                                color="primary"
                                onClick={() => setOpenAddPMmodal(true)}
                            >Reactivate Membership</Button>
                    }
                    <div className="card-info-wrap">
                        {
                            cardList?.map((card, idx) => (
                                <div className='card-info' key={idx} >
                                    <img src={getCardType(card.card.brand)} alt='card-icon' className="billing-custom-credit-card " />
                                    <span>**** **** **** {card.card.last4}</span>
                                    <div className='card-preferred-btn-wrap' >
                                        {defaultPaymentMethod === card.id
                                            ? <div className='card-preferred-btn'>PREFERRED</div>
                                            : <Button sx={{
                                                fontSize: 10,
                                                color: '#aaa',
                                                '&:hover': {
                                                    backgroundColor: '#00bfff',
                                                    color: '#fff'
                                                }
                                            }}
                                                onClick={() => handleMakePreffered(card.id)}
                                            >Make Preferred</Button >}
                                    </div>
                                    <div className='card-edit-btn' onClick={() => handleEditPMmethod(card)} >Edit</div>
                                    {
                                        defaultPaymentMethod === card.id
                                            ? <Tooltip title="Make another payment method preferred to delete this payment method" arrow>
                                                <div>
                                                    <IconButton sx={{ ml: .5 }} disabled aria-label="delete" size="small">
                                                        <DeleteIcon style={{ fill: '#DCDCDC' }} fontSize="inherit" />
                                                    </IconButton>
                                                </div>
                                            </Tooltip>
                                            : <IconButton sx={{ ml: .5 }} aria-label="delete" size="small" onClick={() => setRemovePMModalShow(card.id)}>
                                                <DeleteIcon style={{ fill: '#F9564F' }} fontSize="inherit" />
                                            </IconButton>
                                    }

                                </div>
                            ))
                        }
                        <div className='add-card-btn' onClick={() => setOpenAddPMmodal(true)}> Add Payment Method </div>
                    </div>
                </div>
            </div>
            {
                openRegisterModal && <Register openRegisterModal={openRegisterModal} setOpenRegisterModal={setOpenRegisterModal} />
            }
            <CancelMembershipModal open={!!cancelModalShow} handleConfirm={handleCancelMembership} handleClose={() => setCancelModalShow(false)} />

            <AlertDialog open={!!reactivateModalShow} handleConfirm={handleReactivateMembership} handleClose={() => setReactivateModalShow(false)}>
                Are you sure you want to reactivate your membership?
            </AlertDialog>
            <AlertDialog open={!!removePMModalShow} handleConfirm={() => handleRemovePaymentMethod(removePMModalShow)} handleClose={() => setRemovePMModalShow(null)}>
                Are you sure you want to remove this payment method?
            </AlertDialog>


            <Elements stripe={stripePromise} >
                <AddPaymentMethodForm
                    open={openAddPMmodal}
                    handleOpen={setOpenAddPMmodal}
                    onSuccess={() => loadCardList()}
                    user={user}
                />
            </Elements>

            <UpdatePaymentMethodForm
                paymentMethodObj={paymentMethodObj}
                open={!!openUpdatePMmodal}
                handleOpen={handleEditPMmethod}
                onSuccess={() => loadCardList()}
            />
        </div>
    )
}
