import { Grid, Typography } from "@mui/material"
import MaterialUITheme from '../../config/material-ui-theme'
import xantie from '../../assets/images/app-icons/xantie.png'
import BusinessCard from './BusinessCard'
import useScript from "../../config/useScript"

export default function ProfessionalServices () {
  useScript()
  return (
    <MaterialUITheme>
      <Grid
        container
        sx={{
          maxWidth: 1250,
          mx: 'auto',
          p: 2,
          '& .MuiTypography-root': { fontWeight: 600 }
        }}
      >
        <Grid item xs={12}>
          <Typography component={'h2'} variant="h4" sx={{ pt: 2, textAlign: 'center' }}>
            Certified Professional Services Partners
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ py: 6 }}>
          <Typography component={'h6'} variant="h5" sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}`, pb: 1, mb: 2 }}>
            Datawarehousing, Reporting, Analytics, Automation
          </Typography>
          <BusinessCard
            Image={xantie}
            title="Xantie"
            txt1="Valuable data insights."
            txt2="It's kind of our thing."
            phone="435-299-1131"
            email="support@xantie.com"
          />
        </Grid>
        <Grid item xs={12} sx={{ py: 6 }}>
          <Typography component={'h6'} variant="h5" sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}`, pb: 1, mb: 2 }}>
            Pipeline management
          </Typography>
          <BusinessCard
            Image={xantie}
            title="Xantie"
            txt1="Valuable data insights."
            txt2="It's kind of our thing."
            phone="435-299-1131"
            email="support@xantie.com"
          />
        </Grid>
      </Grid>
    </MaterialUITheme>
  )
}