import React from 'react';
import MaterialUITheme from '../../config/material-ui-theme';
import { Card, Box, Typography } from '@mui/material'
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import DataConnectorImage from '../../assets/images/dataconnector_main.png'
import { useState } from 'react';
import { useContext } from 'react';
import { CommonLoaderContext } from '../../config/router';
import { OrganizationAPIService, UserAPIService } from '../../config/api-service';
import { useEffect } from 'react';
import PaymentForm from '../common/PaymentForm/PaymentForm';
import { APP_KEY, ORGANIZATIONS } from '../../config/constants';

const { REACT_APP_STRIPE_CLIENT_PUBLIC } = process.env;
const stripePromise = loadStripe(REACT_APP_STRIPE_CLIENT_PUBLIC)

const PaymentMethodAlert = () => {
    const [, setCommonLoader] = useContext(CommonLoaderContext)
    const [organizationUser, setOrganizationUser] = useState({})
    const [user, setUser] = React.useState({});

    const updateLocalStorage = async () => {
        const { paymentMethods } = await UserAPIService.getPaymentMethod()
        const localStorageData = await JSON.parse(localStorage.getItem(ORGANIZATIONS))
        localStorage.setItem(ORGANIZATIONS, JSON.stringify({ ...localStorageData, hasPMethods: paymentMethods?.data?.length > 0 ? true : false }))
    }

    useEffect(() => {
        const localStorageData = JSON.parse(localStorage.getItem(APP_KEY))
        setUser(localStorageData.user)
    }, [])

    const getOrganizationDetail = async () => {
        setCommonLoader(true)
        const { mainContactUser } = await OrganizationAPIService.getOrganizationDetail()
        setOrganizationUser(mainContactUser)
        setCommonLoader(false)
    }

    useEffect(() => {
        getOrganizationDetail()
    }, [])

    return (
        <MaterialUITheme>
            <Card raised={true} className='login' sx={{ textAlign: 'center' }}>
                <Box sx={{ maxWidth: 150, mx: 'auto' }}>
                    <img src={DataConnectorImage} alt="Logo" style={{ maxWidth: '100%' }} />
                </Box>
                <Typography component={'div'} mt={2} mb={2} fontSize="1.2rem" sx={{ px: 3, textAlign: "left" }}>
                    Welcome back, {organizationUser?.FirstNM || ''}{organizationUser?.LastNM && ` ${organizationUser?.LastNM}`}, to Data Connector!
                </Typography>
                <Typography component={'div'} mt={2} mb={2} fontSize="1.2rem" sx={{ px: 3, textAlign: "left" }}>
                    Your free trial has come to an end. To continue using Data Connector, please add a valid payment method.
                </Typography>

                <Elements stripe={stripePromise} >
                    <PaymentForm user={user} onSuccess={updateLocalStorage} />
                </Elements>

                <Typography component={'div'} mt={2} fontSize="1.2rem" sx={{ px: 3, textAlign: "left" }}>
                    Need help? Feel free to contact us at<br />support@dataconnector.com
                </Typography>
            </Card>
        </MaterialUITheme>
    );
};

export default PaymentMethodAlert;