import { useEffect, useState, useContext } from 'react'
import { Grid, TextField, Typography, Card, CardContent } from '@mui/material'
import MaterialUITheme from '../../config/material-ui-theme'
import { DocumentationApiService } from '../../config/api-service'
import ResourceDetails from './ResourceDetails'
import { CommonLoaderContext } from '../../config/router'
import useScript from '../../config/useScript'

export default function Resources () {
  const [, setCommonLoader] = useContext(CommonLoaderContext)
  const [detailsPage, setDetailsPage] = useState(null)
  const [resources, setResources] = useState([])
  const [filteredResources, setFilteredResources] = useState([])
  const [searchText, setSearchText] = useState('')

  const getResources = async () => {
    setCommonLoader(true)
    const data = await DocumentationApiService.getDocumentations()
    setCommonLoader(false)
    setResources(data)
    setFilteredResources(data)
  }

  useEffect(() => {
    getResources();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useScript()

  const searchChange = (e) => {
    let searchValue = e.target.value.toLowerCase()
    setSearchText(e.target.value)
    const filteredResults = resources.filter(item => (item.documentation_title_txt).toLowerCase().includes(searchValue))
    setFilteredResources(filteredResults)
  }

  const getImage = (name) => {
    let image
    try {
      image = require(`./../../assets/images/app-icons/${name}`).default
    } catch {
      image = require(`./../../assets/images/dataconnector_md.png`).default
    }
    return image
  }

  return (
    <MaterialUITheme>
      <Grid
        container
        spacing={2}
        sx={{
          maxWidth: 1125,
          mx: 'auto',
          p: 2
        }}
      >
        {detailsPage
          ? <ResourceDetails details={detailsPage} onClickBreadcrumb={setDetailsPage} />
          : <><Grid item xs={12} display="flex" justifyContent="flex-end">
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search Resources"
              value={searchText}
              onChange={searchChange}
            />
          </Grid>
            <Grid item container xs={12} spacing={3} sx={{ pb: 6 }}>
              {filteredResources.map(({ documentation_nm, documentation_title_txt, documentation_image_location_txt, documentation_html }) => (
                <Grid item key={documentation_title_txt}>
                  <Card
                    raised
                    onClick={() => setDetailsPage({ name: documentation_nm, content: documentation_html })}
                    sx={{
                      width: 250,
                      minHeight: 200,
                      boxSizing: 'border-box',
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer'
                    }}
                  >
                    <CardContent
                      sx={{
                        display: 'flex',
                        flexFlow: 'column wrap',
                        flex: '1 1 100%',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        '& img': { maxWidth: '95%', maxHeight: 120, borderRadius: 2, mb: 4 }
                      }}
                    >
                      <img src={getImage(documentation_image_location_txt)} alt={documentation_nm} />
                      <Typography
                        variant="p"
                        component="div"
                        title={documentation_title_txt}
                        sx={{
                          lineHeight: 'normal',
                          fontWeight: 600,
                          height: 40,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitLineClamp: '2',
                          WebkitBoxOrient: 'vertical'
                        }}
                      >
                        {documentation_title_txt}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid></>}
      </Grid>
    </MaterialUITheme>
  )
}