import momentTZ from 'moment-timezone';
import React, { createContext, useContext, useEffect, useState, useReducer } from 'react';
import DestinationInactive from '../../assets/destination_inactive.png';
import SourceInactive from '../../assets/source_inactive.png';
import { ConnectorAPIService, OrganizationAPIService } from '../../config/api-service';
import { APP_KEY } from '../../config/constants';
import connectorReducer, { CONNECTOR_REDUCER_TYPES } from '../../reducers/connectorReducer';
import pipelineReducer from '../../reducers/pipelineReducer';
const PipelineContext = createContext();
const selection = [null, "Today", "Yesterday", "Today Subtract", "Custom Date"];

export function usePipelineContext () {
    return useContext(PipelineContext);
}

const sourceInitialState = {
    connector: null,
    image: SourceInactive,
    connectorId: null,
    connections: [],
    credentialId: null,
    objects: [],
    connected: false,
    fields: [],
    getFieldsErrorMessage: '',
    selectedObject: null,
    selectedObjectName: "",
    mapping: [],
    testingConnection: false,
    connectionMessage: "",
    loadingConnections: false,
    fieldsLoading: false,
    options: {},
    selectedObjectOptions: []
}

const destinationInitialState = {
    connector: null,
    image: DestinationInactive,
    connectorId: null,
    connections: [],
    credentialId: null,
    objects: [],
    connected: false,
    fields: [],
    getFieldsErrorMessage: '',
    selectedObject: null,
    selectedObjectName: "",
    mapping: [],
    testingConnection: false,
    connectionMessage: "",
    loadingConnections: false,
    fieldsLoading: false,
    options: {},
    selectedObjectOptions: []
}

const pipelineInitialState = {
    updateMethod: 2,
    pipelineName: "",
    timeout: 24,
    filterObject: "",
    failureNotification: true,
    successNotification: true,
    timeoutNotification: false,
    notificationEmails: [],
    schedulePeriod: 1,
    minute: 0,
    time: "00:00",
    weekDay: 1,
    monthDay: 1,
    month: 1,
    jobDataID: null,
    jobID: null,
    scheduleID: null,
    dateRangeId: null,
    startSelectionDR: 0,
    endSelectionDR: 0,
    startCustomDate: "Custom Date",
    endCustomDate: "Custom Date",
    endDaysFromToday: "X",
    startDaysFromToday: "X",
    notificationId: null,
    scheduleEnabled: false,
}


export default function PipelineProvider ({ children }) {
    const [sourceState, dispatchSourceReducer] = useReducer(connectorReducer, sourceInitialState);
    const [pipelineState, dispatchPipelineReducer] = useReducer(pipelineReducer, pipelineInitialState);
    
    const [destinationState, dispatchDestinationReducer] = useReducer(connectorReducer, destinationInitialState);

    const [method, setMethod] = useState(2);
    const [jobName, setJobName] = useState("");
    const [timeout, setJobTimeout] = useState(24);
    const [filterObject, setFilterObject] = useState("");
    const [failureFLG, setFailureFLG] = useState(true);
    const [successFLG, setSuccessFLG] = useState(true);
    const [timeoutFLG, setTimeoutFLG] = useState(false);
    const [email, setEmail] = useState([]);

    const [runPeriod, setRunPeriod] = useState(1);
    const [minute, setMinute] = useState(0);
    const [time, setTime] = useState("00:00");
    const [weekDay, setWeekDay] = useState(1);
    const [monthDay, setMonthDay] = useState(1);
    const [month, setMonth] = useState(1);
    const [jobDataID, setJobDataID] = useState();
    const [jobID, setJobID] = useState();
    const [scheduleID, setScheduleID] = useState();
    const [dateRangeID, setDateRangeID] = useState();
    const [startSelectionDR, setStartSelectionDR] = useState(0);
    const [endSelectionDR, setEndSelectionDR] = useState(0);
    const [startCustomDate, setStartCustomDate] = useState("Custom Date");
    const [endCustomDate, setEndCustomDate] = useState("Custom Date");
    const [endDaysFromToday, setEndDaysFromToday] = useState("X");
    const [startDaysFromToday, setStartDaysFromToday] = useState("X");
    const [notificationID, setNotificationID] = useState(null);
    const [scheduleEnabled, setScheduleEnabled] = useState(false);

    const [previewColumns, setPreviewColumns] = useState([]);
    const [previewRows, setPreviewRows] = useState([]);
    const [previewError, setPreviewError] = useState('No Data');
    const [previewLoading, setPreviewLoading] = useState(false);
    const [previewNoRows, setPreviewNoRows] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [selectedRows, setSelectedRows] = useState([]);
    const [existingTable, setExistingTable] = useState(false);
    const [nextDisabled, setNextDisabled] = useState(true);
    const [connectors, setConnectors] = useState([]);
    const [mapDisabled, setMapDisabled] = useState(true);
    const [loadDisabled, setLoadDisabled] = useState(true);
    const [deployDisabled, setDeployDisabled] = useState(true);
    const [editMode, setEditMode] = useState(false);
    const [organization, setOrganization] = useState();
    const [userInfo, setUserInfo] = useState({});

    useEffect(() => {
        const localStorageData = localStorage.getItem(APP_KEY);
        if (localStorageData) {
            setUserInfo(JSON.parse(localStorageData));
        }
        window.addEventListener('anonymousUserEventChange', () => {
            const localStorageUserData = localStorage.getItem(APP_KEY);
            if (localStorageUserData) {
                setUserInfo(JSON.parse(localStorageUserData));
            }
        });
    }, []);

    useEffect(() => {
        if(userInfo?.user?.UserAnonymousFlg === null){
            setScheduleEnabled(true);
        }
    }, [userInfo?.user?.UserAnonymousFlg]);

    useEffect(() => {
        // GET LIST OF CONNECTORS
        ConnectorAPIService.getConnectors()
            .then(connectors => {
                if (connectors && connectors.length) {
                    setConnectors(connectors);
                }
            })

        OrganizationAPIService.getOrganizationDetail()
            .then(res => {
                setOrganization(res.organization);
            }).catch(e => {
                console.error("fail to load organization");
            })
    }, []);

    useEffect(() => {
        const validationArray = [sourceState.connectorId, destinationState.connectorId, sourceState.objects.length];
        if (sourceState.connector && !sourceState.connector.SourceUploadFLG) validationArray.push(sourceState.credentialId)
        if (destinationState.connector && !destinationState.connector.DestinationDownloadFLG) {
            validationArray.push(destinationState.credentialId, destinationState.objects.length);
        }

        if (validationArray.every(e => e)) {
            setMapDisabled(false);
        } else {
            setMapDisabled(true);
        }
    }, [ sourceState.connector,sourceState.connectorId, sourceState.credentialId, destinationState.connector, destinationState.connectorId, destinationState.credentialId, sourceState.objects, destinationState.objects]);

    useEffect(() => {
        const validationArray = [sourceState.selectedObject, destinationState.mapping.length];

        if (validationArray.every(e => !!e) && !mapDisabled) {
            setLoadDisabled(false);
        } else {
            setLoadDisabled(true);
        }
    }, [mapDisabled, sourceState.selectedObject, destinationState.mapping])

    const getPreviewData = ({connectorId, credentialId, selectedObject, mapping, newOptions}) => {
        setPreviewRows([])
        setPreviewColumns([])
        mapping = mapping || destinationState.mapping;
        if (mapping.length) {
            setPreviewLoading(true)
            setPreviewNoRows(false)

            mapping = mapping || destinationState.mapping;

            let columns = mapping.filter(row => row.mapped).map((row, index) => {
                return {
                    id: row.mapped || index,
                    field: row.mapped || null,
                    label: row.column
                }
            })
            setPreviewColumns(columns);

            let field_ids = mapping.filter(row => row.mapped && row.column).map(row => row.mapped)
            ConnectorAPIService.getPreview(connectorId || sourceState.connectorId, credentialId || sourceState.credentialId, selectedObject || sourceState.selectedObject, field_ids, newOptions || sourceState.options)
                .then(json => {
                    setPreviewLoading(false)
                    if (json?.results?.data) {
                        setPreviewRows(json.results.data)
                    } else {
                        setPreviewNoRows(true)
                    }
                })
            }
    }
    

    const getExistingTableDestinationMapping = (selectedObject) => {
        ConnectorAPIService.getFields(destinationState.connectorId, destinationState.credentialId, selectedObject)
            .then(res => {
                if (sourceState.fields.length && res?.results) {
                    res = res.results.map(row => {
                        let mappedField = sourceState.fields.find(r => r.field_id === row.field_id)
                        let rs = { 
                            ...row, 
                            mapped: (mappedField) ? mappedField.field_id : '',
                            mapped_label: (mappedField) ? mappedField.field_label : '', 
                            column: row.field_id 
                        }
                        return rs
                    })
                }
                dispatchDestinationReducer({type: CONNECTOR_REDUCER_TYPES.SET_DESTINATION_MAPPING, payload: {mapping: res}});
                getPreviewData({
                    selectedObject,
                    mapping: res,
                })
            })
    }

    const getNewTableDestinationMapping = (selectedObject, fields, newOptions) => {
        fields = fields || sourceState.fields;
        let newRows = fields.map(row => {
            return {
                mapped: row.field_id,
                mapped_label: row.field_label,
                column: row.field_name,
                data_type: "varchar",
                size: "max"
            }
        });

        dispatchDestinationReducer({type: CONNECTOR_REDUCER_TYPES.SET_DESTINATION_MAPPING, payload: {mapping: newRows}});
        getPreviewData({
			mapping: newRows,
            selectedObject,
            newOptions
		})
    }

    const getSourceFields = ({selectedObject, connectorId, credentialId, newOptions}) => {
        dispatchSourceReducer({type: CONNECTOR_REDUCER_TYPES.SET_SOURCE_FIELDS_LOADING})
        ConnectorAPIService.getFields(connectorId || sourceState.connectorId, credentialId || sourceState.credentialId, selectedObject || sourceState.selectedObject, newOptions || sourceState.options)
            .then(res => {
                dispatchSourceReducer({
                    type: CONNECTOR_REDUCER_TYPES.SET_SOURCE_FIELDS, 
                    payload: {
                        fields: res?.results || [], 
                        getFieldsErrorMessage: res?.message || '',
                        
                    }
                })
                // SET DESTINATION MAPPING IF THE CONNECTOR IS A DOWNLOADABLE
                if (res?.results && res.results.length && destinationState.connector?.DestinationDownloadFLG) {
                    getNewTableDestinationMapping(selectedObject, res.results, newOptions);
                }
            });
    }

    const dispatchSource = (sourceDispatch) => {
        // Add Connectors to app selected
        switch (sourceDispatch.type) {
            case CONNECTOR_REDUCER_TYPES.APP_SELECTED:
            case CONNECTOR_REDUCER_TYPES.EDIT_PIPELINE_INIT:
                sourceDispatch.payload.connectors = connectors;
                break;
            default:
                break;
        }

        dispatchSourceReducer(sourceDispatch)
    }

    const dispatchDestination = (destinationDispatch) => {
        // Add Connectors to app selected
        switch (destinationDispatch.type) {
            case CONNECTOR_REDUCER_TYPES.APP_SELECTED:
            case CONNECTOR_REDUCER_TYPES.EDIT_PIPELINE_INIT:
                destinationDispatch.payload.connectors = connectors;
                break;
            default:
                break;
        }

        dispatchDestinationReducer(destinationDispatch)
    }

    const getEditPayload = () => {
        let hourOffset = momentTZ(new Date()).tz(organization?.TimezoneTXT).utcOffset() / 60

        let StartSelectionNM = selection[startSelectionDR];
        let EndSelectionNM = selection[endSelectionDR];

        let StartValueTXT = ((startSelectionDR === 3) ? startDaysFromToday : ((startSelectionDR === 4) ? startCustomDate : null));
        let EndValueTXT = ((endSelectionDR === 3) ? endDaysFromToday : ((endSelectionDR === 4) ? endCustomDate : null));

        let payload = {
            jobName,
            schedule: {
                period: runPeriod,
                minute,
                time,
                weekDay,
                monthDay,
                month,
                hourOffset,
                scheduleEnabled,
                scheduleID,
            },
            connect: {
                sourceConnectorID: sourceState.connectorId,
                destinationConnectorID: destinationState.connectorId,
                sourceCredentialID: sourceState.credentialId,
                destinationCredentialID: destinationState.credentialId,
            },
            mapping: {
                jobDataID,
                mapping: destinationState.mapping.filter(row => row.column),
                sourceObject: sourceState.selectedObject,
                destinationObject: destinationState.selectedObject,
                updateMethod: method,
                objectOptionsJSON: sourceState.options,
            },
            dateRange: {
                dateRangeID,
                FilteredColumnNM: filterObject,
                StartSelectionNM,
                EndSelectionNM,
                StartValueTXT,
                EndValueTXT,
                TimezoneOffsetNBR: new Date().getTimezoneOffset() / 60
            },
            notifications: {
                notificationID,
                EmailTXT: email.join(","),
                SuccessFLG: successFLG ? 1 : 0,
                FailureFLG: failureFLG ? 1 : 0,
            }
        }

        return payload;
    }

    const testConnection = (connectorId, credentialId, dispatch) => {
        if (connectorId !== null && credentialId !== null) {
            dispatch({type: CONNECTOR_REDUCER_TYPES.TESTING_CONNECTION, payload: {testing: true}})
            
            ConnectorAPIService.testConnectorConnection(connectorId, credentialId)
                .then(res => {
                    dispatch({
                        type: CONNECTOR_REDUCER_TYPES.CONNECTION_TEST,
                        payload: {
                            objects: res?.results || [],
                            message: res?.message || "Can't connect. An internal error has occurred."
                        }
                    });
                })
        }
    }

    const testConnectionWithFields = (connectorId, credentialId, selectedObject, dispatch, newOptions) => {
        if (connectorId !== null && credentialId !== null) {
            dispatch({type: CONNECTOR_REDUCER_TYPES.TESTING_CONNECTION, payload: {testing: true}})
            
            ConnectorAPIService.testConnectorConnection(connectorId, credentialId)
                .then(res => {
                    // Get fields if connection was successful and still has selected object
                    if (res?.results && res.results.length && res.results.find(obj => obj.object_id === selectedObject)) {
                        getSourceFields({selectedObject, connectorId, credentialId, newOptions});
                    }

                    dispatch({
                        type: CONNECTOR_REDUCER_TYPES.CONNECTION_TEST,
                        payload: {
                            objects: res?.results || [],
                            message: res?.message || "Can't connect. An internal error has occurred."
                        }
                    });
                })
        }
    }

    const getConnections = (connectorId, dispatch, selectDefault) => {
        if (connectorId !== null) {
            ConnectorAPIService.getConnectorConnections(connectorId)
                .then(({ connections }) => {
                    if (connections.length) {
                        let defaultConnection = connections.find(con => Boolean(con.connector_default_flg))

                        // Set connections
                        dispatch({
                            type: CONNECTOR_REDUCER_TYPES.SET_CONNECTIONS, 
                            payload: {
                                connections, 
                                credentialId: selectDefault ? defaultConnection?.connector_credential_id || null : undefined
                            }
                        });

                        // Test connection if the connector has a default connection setup
                        if (defaultConnection && selectDefault) {
                            testConnection(connectorId, defaultConnection.connector_credential_id, dispatch)
                        }
                    } else {
                        // Set connections
                        dispatch({
                            type: CONNECTOR_REDUCER_TYPES.SET_CONNECTIONS, 
                            payload: {
                                connections: [], 
                            }
                        });
                    }
                })
        }
    }

    return (
        <PipelineContext.Provider value={{
            sourceState,
            destinationState,

            method,
            jobName,
            timeout,
            filterObject,
            failureFLG,
            successFLG,
            timeoutFLG,
            email,
            previewColumns,
            previewRows,
            previewError,
            previewLoading,
            previewNoRows,
            activeStep,
            selectedRows,
            runPeriod,
            minute,
            time,
            weekDay,
            monthDay,
            month,
            existingTable,
            jobDataID,
            jobID,
            scheduleID,
            dateRangeID,
            nextDisabled,
            startSelectionDR,
            endSelectionDR,
            startCustomDate,
            startDaysFromToday,
            endDaysFromToday,
            endCustomDate,
            notificationID,
            scheduleEnabled,
            connectors,
            mapDisabled,
            loadDisabled,
            deployDisabled,
            editMode,

            setMinute,
            setTime,
            setWeekDay,
            setMonthDay,
            setMonth,
            setRunPeriod,
            setJobName,
            setMethod,
            setJobTimeout,
            setFilterObject,
            setFailureFLG,
            setSuccessFLG,
            setTimeoutFLG,
            setEmail,
            setPreviewColumns,
            setPreviewRows,
            setPreviewError,
            setActiveStep,
            setSelectedRows,
            setExistingTable,
            setJobDataID,
            setJobID,
            setScheduleID,
            setDateRangeID,
            setNextDisabled,
            setStartSelectionDR,
            setEndSelectionDR,
            setEndDaysFromToday,
            setEndCustomDate,
            setStartCustomDate,
            setStartDaysFromToday,
            setNotificationID,
            setScheduleEnabled,
            setConnectors,
            setLoadDisabled,
            setMapDisabled,
            setDeployDisabled,
            setEditMode,
            getEditPayload,
            dispatchSource,
            dispatchDestination,

            getSourceFields,
            testConnection,
            testConnectionWithFields,
            getConnections,
            getNewTableDestinationMapping,
            getExistingTableDestinationMapping,
            getPreviewData
        }}>
            {children}
        </PipelineContext.Provider>
    )
}

