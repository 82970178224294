import React, { useState, useContext, createContext } from 'react';
import AlertProvider from './alert.context';
import MaterialProvider from './theme.context';

const AppContext = createContext();

export function useAppContext() {
    return useContext(AppContext);
}

export default function AppProvider({ children }) {
    const [name, setName] = useState('dataconnector');
    const [anonymousUserEmail, setAnonymousUserEmail] = useState("");

    return (
        <AppContext.Provider value={{ name, setName, anonymousUserEmail, setAnonymousUserEmail }}>
            <MaterialProvider>
                <AlertProvider>
                    {children}
                </AlertProvider>
            </MaterialProvider>
        </AppContext.Provider>
    )
}