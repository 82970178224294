import React, {useState, useEffect, useRef} from "react";
import { useParams } from "react-router";
import { ConnectorAPIService } from "../../../config/api-service";
import useScript from "../../../config/useScript";

const connectorInitialState = {
    connectors: [],
    connections: [],
    selectedConnector: "",
    selectedConnection: null,
}

const objectInitialState = {
    objects: [],
    authenticationMessage: "",
    connectResponse: "",
    selectedObject: null
}

const optionsInitialState = {

}

const fieldsInitialState = {
    fields: [],
    fieldsMessage: "",
    getFieldsResponse: "",
    selectedFields: [],
}

const previewInitialState = {
    preview: [],
    previewMessage: "",
    previewResponse: "",
}

const sourceTestInitialState = {
    preview: [],
    sourceTestMessage: "",
    sourceTestResponse: "",
}

const destinationTestInitialState = {
    preview: [],
    destinationTestMessage: "",
    destinationTestResponse: "",
}

export default function EditConnector() {
    const { id } = useParams();

    const [{connectors, connections, selectedConnector, selectedConnection}, setConnectorState] = useState(connectorInitialState);
    const [{authenticationMessage, objects, connectResponse, selectedObject}, setObjectState] = useState(objectInitialState);
    const [{}, setOptionsState] = useState(optionsInitialState);
    const [{fieldsMessage, fields, selectedFields, getFieldsResponse}, setFieldsState] = useState(fieldsInitialState);
    const [{previewMessage, previewResponse}, setPreviewState] = useState(previewInitialState);
    const [{destinationTestMessage, destinationTestResponse}, setDestinationTestState] = useState(destinationTestInitialState);
    const [{sourceTestMessage, sourceTestResponse}, setSourceTestState] = useState(sourceTestInitialState);
    const [updateMethod, setUpdateMethod] = useState(1);
    const [destinationObjectName, setDestinationObjectName] = useState(selectedObject)
    // const [credentialID, setCredentialID] = useState();
    // const [response, setResponse] = useState();
    // const [message, setMessage] = useState();
    // const [objects, setObjects] = useState([]);
    // const [fields, setFields] = useState([]);
    // const [fieldsResponse, setFieldsResponse] = useState()
    // const [getFieldsMessage, setGetFieldsMessage] = useState()
    // const [connections, setConnections] = useState([])
    // const [selectedFields, setSelectedFields] = useState([]);
    // const [getDataMessage, setGetDataMessage] = useState();
    // const [getDataResponse, setGetDataResponse] = useState()
    // const [selectedObject, setSelectedObject] = useState()
    // // const [connectors, setConnectors] = useState([]);
    // const [selectedConnector, setSelectedConnector] = useState()
    const itemsRef = useRef([]);

    const resetState = (overiddenValues, setState, initialState) => {
        setState((prev) => ({...initialState, ...overiddenValues}));
    }

    const changeConnectorState = (stateKey, value) => {
        setConnectorState((prevState) => ({...prevState, [stateKey]: value}));
    }
    
    const changeObjectState = (stateKey, value) => {
        setObjectState((prevState) => ({...prevState, [stateKey]: value}));
    }

    const changeFieldsState = (stateKey, value) => {
        setFieldsState((prevState) => ({...prevState, [stateKey]: value}));
    }

    const setKeyState = (setState, key, value) => {
        setState((prevState) => ({...prevState, [key]: value}));
    }

    const setNewObjectState = (setState, newObject) => {
        setState((prevState) => ({...prevState, ...newObject}));
    }

    useEffect(() => {
        ConnectorAPIService.getConnectors()
            .then(res => {
                changeConnectorState("connectors", res || []);
                if (res)
                    res.map(connector => {
                        if (+connector.ConnectorID === +id) {
                            changeConnectorState("selectedConnector", connector.ConnectorID);
                        }
                    })
            });
        
    }, [id]);

    useEffect(() => {
        ConnectorAPIService.getConnections(selectedConnector)
            .then(connections => {
                changeConnectorState("connections", connections || []);
            })
    }, [selectedConnector])

    useEffect(() => {
        if (selectedConnection && selectedConnection !== "null") {
            ConnectorAPIService.connectorTest(selectedConnector, {CredentialID: selectedConnection, action: "connect"})
                .then(res => {
                    changeObjectState("connectResponse", JSON.stringify(res.results || "Failed, no results", undefined, 4));
                    changeObjectState("authenticationMessage", res.message || "No message was returned from connector.");
                    changeObjectState("objects", res.results || []);
                });
            //ConnectorAPIService.connectorTest(id, {CredentialID: selectedConnection, action: "get_fields", object_id: e.target.value} );
        }
    }, [selectedConnector, selectedConnection]);

    useEffect(() => {
        if (selectedConnection && selectedConnection !== "null" && selectedObject && selectedObject !== "null") {
            // ConnectorAPIService.connectorTest(selectedConnector, {CredentialID: selectedConnection, action: "get_options", object_id: selectedObject} )
            //     .then(res => {
            //     });

            ConnectorAPIService.connectorTest(selectedConnector, {CredentialID: selectedConnection, action: "get_fields", object_id: selectedObject} )
                .then(res => {
                    setNewObjectState(setFieldsState, {
                        fieldsMessage: res.message || "No message recieved from connector",
                        fields: res.results || [],
                        getFieldsResponse: JSON.stringify(res.results || "Failed, no results", undefined, 4)
                    })
                });
        }
    }, [selectedConnector, selectedConnection, selectedObject])

    useScript()

    // useEffect(() => {
    //     itemsRef.current = itemsRef.current.slice(0, fields.length);
    // }, [fields]);

    const handleSelectedConnector = async (e) => {
        if (e.target.value === "null") return;
        resetState({selectedConnector: e.target.value, connectors}, setConnectorState, connectorInitialState);
    }

    const handleSelectedConnection = async (e) => {
        changeConnectorState("selectedConnection", e.target.value);
        resetState({connectResponse: ""}, setObjectState, objectInitialState);
    }

    const handleSelectedObject = (event) => {
        changeObjectState("selectedObject", event.target.value);
        setDestinationObjectName(event.target.value)
        resetState({}, setFieldsState, fieldsInitialState);
        resetState({}, setPreviewState, previewInitialState);
    }

    // const handleSelectedObject = async (e) => {
    //     if (e.target.value === "null") return;
    //     setSelectedObject(e.target.value)
    //     let connectResponse = await ConnectorAPIService.connectorTest(id, {CredentialID: credentialID, action: "get_fields", object_id: e.target.value} );
    //     setGetFieldsMessage(connectResponse.message);

    //     if (connectResponse.results) {
    //         let newFields = connectResponse.results.map(field => ({...field, checked: false}))
    //         setFields(newFields || []);
    //     }
    //     setFieldsResponse(JSON.stringify(connectResponse.results || "Failed", undefined, 4))
    // }

    const handleSelectAllFields = async (e) => {
        let newFields = fields.map(field => ({...field, checked: e.target.checked}))

        // setFields(newFields);
        setKeyState(setFieldsState, "fields", newFields);

        if (e.target.checked){
            // setSelectedFields([...newFields]);
            setKeyState(setFieldsState, "selectedFields", [...newFields])
        } else {
            // setSelectedFields([])
            setKeyState(setFieldsState, "selectedFields", [])
        }
    }

    const handleSelectedField = async (e, i) => {
        const newFields = [...fields];

        newFields[i] = {...newFields[i], checked: e.target.checked}

        setKeyState(setFieldsState, "fields", newFields);

        if (e.target.checked){
            setKeyState(setFieldsState, "selectedFields", [...selectedFields, newFields[i]])
        }
        else {
            const newSelectedFields = selectedFields.filter(field => field.field_id !== newFields[i].field_id);

            setKeyState(setFieldsState, "selectedFields", newSelectedFields)
        }
    }

    const handleGetPreview = async (e) => {
        if (!selectedFields.length) return;
        ConnectorAPIService.connectorTest(id, {CredentialID: selectedConnection, action: "get_preview", object_id:selectedObject, field_ids: selectedFields.map(f => f.field_id) } )
            .then(res => {
                setNewObjectState(setPreviewState, {
                    previewMessage: res.message || "No message was returned from connector", 
                    previewResponse: JSON.stringify(res.results || "Failed, no results", undefined, 4),
                    preview: res.results || []
                })
            })
    }

    const handleTestSource = async (e) => {
        ConnectorAPIService.connectorTest(id, {CredentialID: selectedConnection, action: "test_source", object_id:selectedObject, field_ids: selectedFields.map(f => f.field_id), mapping: null,  } )
            .then(res => {
                setNewObjectState(setSourceTestState, {
                    sourceTestMessage: res.message || "No message was returned from connector", 
                    sourceTestResponse: JSON.stringify(res.results || "Failed, no results", undefined, 4),
                    preview: res.results || []
                })
            })
    }

    const handleTestDestination = async (e) => {
        ConnectorAPIService.connectorTest(id, {CredentialID: selectedConnection, action: "test_destination", object_id:destinationObjectName, field_ids: selectedFields.map(f => f.field_id), mapping: selectedFields.map(f => ({column: f.field_id, mapped : f.field_id, data_type: f.data_type, size: f.size})), update_method: updateMethod } )
        .then(res => {
                setNewObjectState(setDestinationTestState, {
                    destinationTestMessage: res.message || "No message was returned from connector", 
                    destinationTestResponse: JSON.stringify(res.results || "Failed, no results", undefined, 4),
                    preview: res.results || []
                })
            })
    }

    return (
        <div style={{padding: 40}}>
            <select value={selectedConnector} onChange={handleSelectedConnector}>
                <option value="null">Select a Connector</option>
                {connectors.map(connector => <option key={connector.ConnectorID} value={connector.ConnectorID}>{connector.ConnectorNM}</option>)}
            </select>
           
            <div>Test Connector {id}</div>
            <select value={selectedConnection} onChange={handleSelectedConnection}>
                <option value="null">Select a connection</option>
                {connections.map(connection => <option key={connection.ConnectorCredentialID} value={connection.ConnectorCredentialID}>{connection.CredentialNM}</option>)}
            </select>
            <div>
            Authentication Message: {authenticationMessage}
            </div>
            <div>
                <textarea rows="10" cols="120" value={connectResponse} />
            </div>
            <select value={selectedObject} onChange={handleSelectedObject}>
                <option value="null">Select an object</option>
                {objects.map(object => <option key={object.object_id} value={object.object_id}>{object.object_label}</option>) || ""}
            </select>
             <div>
                Get fields Message: {fieldsMessage}
            </div>
            <div>
                <textarea rows="10" cols="120" value={getFieldsResponse} />
            </div>

            <div style={{border: '1px solid black', width: 400, maxHeight: 300, overflowY: 'scroll'}}>
                <ul style={{margin: 0, listStyle: 'none'}}>
                    <li><input type="checkbox" disabled={!fields.length} onChange={handleSelectAllFields} value="__SELECT_ALL" />Select All</li>
                    {fields.map((field, i)=> (
                        <li key={field.field_id}>
                            <input type="checkbox" value={field.field_id} onChange={(e) => handleSelectedField(e, i)} checked={field.checked} />{
                                field.field_label}
                        </li>
                    ))}
                </ul>
            </div>
            <div>
                Selected Fields:
                {selectedFields.map(sf => sf.field_id + ", ")}
            </div>
            <div>
                <div>
                <button onClick={handleGetPreview}>Get Data</button>
                </div>
                <div>
                    Get Data Message: {previewMessage}
                </div>
                <textarea rows="10" cols="120" value={previewResponse} />
            </div>
            <div>
                <button onClick={handleTestSource}>Test Source</button>
                <div>
                    Test Source Message: {sourceTestMessage}
                </div>
                <textarea rows="10" cols="120" value={sourceTestResponse} />
            </div>
            <div>
                <input placeholder="Destination Object name" value={destinationObjectName} onChange={(e) => setDestinationObjectName(e.target.value)} />
                <input type="number" min="1" max="5" placeholder="Update Method (0 replace, 1 append)" value={updateMethod} onChange={(e) => setUpdateMethod(+e.target.value)} />
                <button onClick={handleTestDestination}>Test Destination</button>
                <div>
                    Test Destination Message: {destinationTestMessage}
                </div>
                <textarea rows="10" cols="120" value={destinationTestResponse} />
            </div>
        </div>
    );
}
