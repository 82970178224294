import { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { Card, Box, Typography, Button } from '@mui/material'
import { OrganizationAPIService } from '../../config/api-service'
import DataConnectorImage from '../../assets/images/dataconnector_main.png'
import MaterialUITheme from '../../config/material-ui-theme'
import { APP_KEY } from '../../config/constants'
import { CommonLoaderContext } from "../../config/router"
import useScript from '../../config/useScript'

export default function MembershipAlert () {
  const [, setCommonLoader] = useContext(CommonLoaderContext)
  const [user, setUser] = useState({});
  const { REACT_APP_MARKETING_SITE = '//' } = process.env

  const [organization, setOrganization] = useState({})
  const [organizationUser, setOrganizationUser] = useState({})
  const getOrganizationDetail = async () => {
    setCommonLoader(true)
    const { mainContactUser, organization } = await OrganizationAPIService.getOrganizationDetail()
    setOrganization(organization)
    setOrganizationUser(mainContactUser)
    setCommonLoader(false)
  }

  useEffect(() => {
    const dataConnectorUser = JSON.parse(localStorage.getItem(APP_KEY))
    if (dataConnectorUser) {
      setUser(dataConnectorUser.user)
    }
  }, [])

  useEffect(() => {
    getOrganizationDetail()
    const localStorageData = JSON.parse(localStorage.getItem(APP_KEY))
    if (localStorageData) {
      setUser(localStorageData.user)
    }
  }, [])

  useScript()

  return (
    <MaterialUITheme>
      <Card raised={true} className='login' sx={{ textAlign: 'center' }}>
        <Box sx={{ maxWidth: 150, mx: 'auto' }}>
          <img src={DataConnectorImage} alt="Logo" style={{ maxWidth: '100%' }} />
        </Box>

        {
          user?.UserID === organizationUser?.UserID
            ? <>
              <Typography component={'div'} mt={2} mb={2} fontSize="1.2rem">
                Welcome back, {organizationUser?.FirstNM || ''}{organizationUser?.LastNM && ` ${organizationUser?.LastNM}`}, to Data Connector!
                <br />
                Click below to reactivate your account
              </Typography>
              <Button fullWidth variant="contained" component={Link} to="/account/billing-setting">Reactivate Membership</Button>
            </>
            : <>
              <Typography component={'div'} mt={2} fontSize="1.2rem">
                We're sorry, your account {user?.EmailTXT || ''} is tied to an organization ({organization?.OrganizationNM || ''}) that has it's membership on pause.
              </Typography>
              <Typography component={'div'} mt={2} fontSize="1.2rem">
                Please reach out to {organizationUser?.FirstNM || ''}{organizationUser?.LastNM && ` ${organizationUser?.LastNM}`}: <span style={{ borderBottom: '1px solid' }}>{organizationUser?.EmailTXT}</span> to reenable it's membership.
              </Typography>
              <Typography component={'div'} mt={2} mb={1} fontSize="1.2rem">
                You may also create a new account under a new email by clicking below, or feel free to reach out to our Support team.
              </Typography>
              <Button fullWidth variant="contained" onClick={() => window.location.href = `${REACT_APP_MARKETING_SITE}/register`}>Create new login</Button>
            </>
        }

      </Card>
    </MaterialUITheme>
  )
}
