import React, {useState, useContext, createContext, useEffect} from 'react';
import { ConnectorAPIService } from '../config/api-service';

const ConnectorContext = createContext();

export function useConnectorContext() {
    return useContext(ConnectorContext);
}

export default function ConnectorProvider({children}) {
    const [connectors, setConnectors] = useState([])

    useEffect(() => {
        // GET LIST OF CONNECTORS
        ConnectorAPIService.getConnectors()
            .then(connectors => {
                setConnectors(connectors);
            })
    }, []);
    return (
        <ConnectorContext.Provider value={{
            connectors
        }}>
            {children}
        </ConnectorContext.Provider>
    )
}
