import { useState, useContext } from 'react'
import { Switch, Link, Route } from "react-router-dom"
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import PrivateRoute from '../common/authentication/PrivateRoute'
import AccountsComponent from './accounts';
import AccountSettingsComponent from './account-settings';
import BillingSettingsComponent from "./billing-settings";
import EmailSettings from "./email/EmailSettings";
import BillingStatementsComponent from './billing-statements';
import LogsComponent from './logs';
import OrganizationSettingsComponent from './organization-settings';
import ConnectionsComponent from './connections';
import { OrganizationContext } from '../../context/organization.context'
import useScript from '../../config/useScript';

const settingsPages = [
    {
        page: 'Account Settings',
        link: "/account",
    },
    {
        page: 'Billing',
        open: false,
        children: [
            {
                page: 'Statements',
                link: "/account/billing-statement",
            },
            {
                page: 'Settings',
                link: "/account/billing-setting",
            }
        ],
    },
    {
        page: 'Email',
        link: "/account/email-setting",
    },
    {
        page: 'Connections',
        link: "/account/connections",
    },
    {
        page: 'Logs',
        link: "/account/logs",
    },
    {
        page: "Organization",
        link: "/account/organization",
    },
    {
        page: 'Users',
        link: "/account/user",
    }
]

export default function AccountComponent () {
    const { isActiveUser } = useContext(OrganizationContext)

    const [selectedPage, setSelectedPage] = useState('Account Settings')
    const [pages, setPages] = useState(settingsPages);

    useScript()

    const changePage = (page, index) => {
        if (page.children) {
            let list = [...pages]
            list[index].open = !list[index].open
            setPages(list);
        } else {
            setSelectedPage(page.page)
        }
    }



    return (
        <div className='settings-page'>
            {isActiveUser && <List sx={{ width: 240, padding: 0, borderRight: .1, borderColor: '#ECECEC' }}>
                {pages.map((page, index) => (<div key={page.page}>
                    {
                        !!page.link ?
                            <Link to={page.link} style={{ textDecoration: "unset" }} >
                                <ListItemButton
                                    selected={selectedPage === page.page}
                                    onClick={() => changePage(page, index)}
                                >
                                    <ListItemText primary={page.page} />
                                    {page.children ? page.open ? <ExpandLess /> : <ExpandMore /> : ""}
                                </ListItemButton>
                            </Link> : <ListItemButton
                                selected={selectedPage === page.page}
                                onClick={() => changePage(page, index)}
                            >
                                <ListItemText primary={page.page} />
                                {page.children ? page.open ? <ExpandLess /> : <ExpandMore /> : ""}
                            </ListItemButton>
                    }
                    {page.children ?
                        <Collapse in={page.open} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {page.children.map((childPage, index) => (
                                    !!childPage.link ?
                                        <Link key={index} to={childPage.link} style={{ textDecoration: "unset" }} >
                                            <ListItemButton
                                                selected={selectedPage === childPage.page}
                                                sx={{ pl: 4 }}
                                                onClick={() => changePage(childPage, index)}
                                            >
                                                <ListItemText primary={childPage.page} />
                                            </ListItemButton>
                                        </Link> : <ListItemButton
                                            key={index}
                                            selected={selectedPage === childPage.page}
                                            sx={{ pl: 4 }}
                                            onClick={() => changePage(childPage, index)}
                                        >
                                            <ListItemText primary={childPage.page} />
                                        </ListItemButton>
                                ))}
                            </List>
                        </Collapse>
                        : ""}
                </div>
                ))}
            </List>}
            <div className="settings-component" >
                <Switch>
                    <PrivateRoute path="/account" exact={true}>
                        <AccountSettingsComponent />
                    </PrivateRoute>
                    <PrivateRoute path="/account/billing-statement" exact={true}>
                        <BillingStatementsComponent />
                    </PrivateRoute>
                    <Route path="/account/billing-setting" exact={true}>
                        <BillingSettingsComponent />
                    </Route>
                    <PrivateRoute path="/account/email-setting" exact={true}>
                        <EmailSettings />
                    </PrivateRoute>
                    <PrivateRoute path="/account/connections" exact={true}>
                        <ConnectionsComponent />
                    </PrivateRoute>
                    <PrivateRoute path="/account/logs" exact={true}>
                        <LogsComponent />
                    </PrivateRoute>
                    <PrivateRoute path="/account/organization" exact={true}>
                        <OrganizationSettingsComponent />
                    </PrivateRoute>
                    <PrivateRoute path="/account/user" exact={true}>
                        <AccountsComponent />
                    </PrivateRoute>
                </Switch>

                <ToastContainer
                    position="bottom-right"
                    progress={undefined}
                    theme="colored"
                />
            </div>
        </div>
    );
}
