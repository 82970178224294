import React from 'react'
import FieldBasedModal from './FieldBasedModal';
import OauthModal from './OuathModal'

export default function AuthenticationDetail(props) {
    switch (+props.authenticationMethod.AuthenticationMethodID) {
      case 1:
          return (<FieldBasedModal {...props} />);
      case 2:
          return (<OauthModal {...props} />);
      default:
          throw new Error("Authentication Method not supported.")
    }
}
