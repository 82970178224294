import React, {useState} from 'react';
import FilteredSearchBar from '../common/search-bar.jsx';
import SearchIcon from "@material-ui/icons/Search";
import './style.css';
import { ContactForm } from './contact-form.jsx';
import useScript from '../../config/useScript.js';

export const HelpComponent = () => {
    const [topics, setTopics] = useState(["Choosing connectors", "Mapping the data", "Configuring your pipeline", "Other topics"]);
    const [displayed, setDisplayed] = useState(0);
    const [formShown, setFormShown] = useState(false);
    useScript()
    const contactForm = formShown ? (<ContactForm/>) :
        (
            <div>
                <div className="row margin-1">
                    <button type="expand" className="btn btn-secondary" onClick={() => {setFormShown(true)}}>
                        Contact us
                    </button>
                </div>
            </div>
        );

    const formatDisplay = () => {
            if (displayed === 1) {
              return (
                    <div className="margin-3">
                        <div className="text title body-title">Getting Started</div>
                        <div className="text body">
                            Shall I speak ill of him that is my husband? 
                            Ah, poor my lord, what tongue shall smooth thy name 
                            When I, thy three-hours wife, have mangled it? 
                            But wherefore, villain, didst thou kill my cousin?
                            That villain cousin would have killed my husband.
                            Back, foolish tears, back to your native spring!
                            Your tributary drops belong to woe,
                            Which you, mistaking, offer up to joy.
                            My husband lives, that Tybalt would have slain;
                            And Tybalt's dead, that would have slain my husband.
                            All this is comfort; wherefore weep I then?
                            Some word there was, worser than Tybalt's death,
                            That murd'red me. I would forget it fain;
                            But O, it presses to my memory
                            Like damnèd guilty deeds to sinners' minds!
                            'Tybalt is dead, and Romeo--banishèd!'
                            That 'banishèd,' that one word 'banishèd,'
                            Hath slain ten thousand Tybalts. Tybalt's death
                            Was woe enough, if it had ended there;
                            Or, if sour woe delights in fellowship
                            And needly will be ranked with other griefs,
                            Why followèd not, when she said 'Tybalt's dead,'
                            Thy father, or thy mother, nay, or both,
                            Which modern lamentation might have moved?
                            But with a rearward following Tybalt's death,
                            'Romeo is banishèd'--to speak that word
                            Is father, mother, Tybalt, Romeo, Juliet,
                            All slain, all dead. 'Romeo is banishèd'--
                            There is no end, no limit, measure, bound,
                            In that word's death; no words can that woe sound.
                        </div>
                    </div>
                );
            } else if (displayed === 2) {
                return (
                    <div className="margin-3">
                    <div className="text title body-title">Connectors Help</div>
                    <div className="text body">
                        He dashed back across the road, hurried up to his offi ce,
                        snapped at his secretary not to disturb him, seized his
                        telephone and had almost fi nished dialling his home number
                        when he changed his mind. He put the receiver back down
                        and stroked his moustache, thinking … no, he was being
                        stupid. Potter wasn’t such an unusual name. He was sure there
                        were lots of people called Potter who had a son called Harry.
                        Come to think of it, he wasn’t even sure his nephew was called
                        Harry. He’d never even seen the boy. It might have been
                        Harvey. Or Harold. There was no point in worrying Mrs
                        Dursley, she always got so upset at any mention of her sister.
                        He didn’t blame her – if he’d had a sister like that … but all the
                        same, those people in cloaks …
                        He found it a lot harder to concentrate on drills that
                        afternoon, and when he left the building at fi ve o’clock, he
                        was still so worried that he walked straight into someone just
                        outside the door.
                        ‘Sorry,’ he grunted, as the tiny old man stumbled and
                        almost fell. It was a few seconds before Mr Dursley realised
                        that the man was wearing a violet cloak. He didn’t seem at all
                        upset at being almost knocked to the ground. On the contrary,
                        his face split into a wide smile and he said in a squeaky voice
                        that made passers- by stare: ‘Don’t be sorry, my dear sir, for
                        nothing could upset me today! Rejoice, for You-Know-Who
                        has gone at last! Even Muggles like yourself should be
                        celebrating, this happy, happy day!’
                        And the old man hugged Mr Dursley around the middle
                        and walked off.
                        Mr Dursley stood rooted to the spot. He had been hugged
                        by a complete stranger. He also thought he had been called a
                        Muggle, whatever that was. He was rattled. He hurried to his
                        car and set off home, hoping he was imagining things, which
                        he had never hoped before, because he didn’t approve of
                        imagination.
                        As he pulled into the driveway of number four, the fi rst
                        thing he saw – and it didn’t improve his mood – was the tabby
                        cat he’d spotted that morning. It was now sitting on his garden
                        wall. He was sure it was the same one; it had the same markings
                        around its eyes.
                        ‘Shoo!’ said Mr Dursley loudly.
                        The cat didn’t move. It just gave him a stern look. Was this
                        normal cat behaviour, Mr Dursley wondered. Trying to pull
                        himself together, he let himself into the house. He was still
                        determined not to mention anything to his wife.
                        Mrs Dursley had had a nice, normal day. She told him over
                        dinner all about Mrs Next Door’s problems with her daughter
                        and how Dudley had learnt a new word (‘Shan’t!’). Mr Dursley
                        tried to act normally. When Dudley had been put to bed, he
                        went into the living- room in time to catch the last report on
                        the evening news:
                        ‘And fi nally, bird- watchers everywhere have reported that
                        the nation’s owls have been behaving very unusually today.
                        Although owls normally hunt at night and are hardly ever
                        seen in daylight, there have been hundreds of sightings of
                        these birds fl ying in every direction since sunrise. Experts
                        are unable to explain why the owls have suddenly changed
                        their sleeping pattern.’ The news reader allowed himself
                        a grin. ‘Most mysterious. And now, over to Jim McGuffi n with
                        the weather. Going to be any more showers of owls tonight,
                        Jim?’
                        ‘Well, Ted,’ said the weatherman, ‘I don’t know about that,
                        but it’s not only the owls that have been acting oddly today.
                        Viewers as far apart as Kent, Yorkshire and Dundee have been
                        phoning in to tell me that instead of the rain I promised
                        yesterday, they’ve had a downpour of shooting stars! Perhaps
                        people have been celebrating Bonfi re Night early – it’s not
                        until next week, folks! But I can promise a wet night tonight.’
                        Mr Dursley sat frozen in his armchair. Shooting stars all
                        over Britain? Owls fl ying by daylight? Mysterious people in
                        cloaks all over the place? And a whisper, a whisper about the
                        Potters …
                        Mrs Dursley came into the living- room carrying two cups
                        of tea. It was no good. He’d have to say something to her. He
                        cleared his throat nervously. ‘Er – Petunia, dear – you haven’t
                        heard from your sister lately, have you?’
                        As he had expected, Mrs Dursley looked shocked and
                        angry. After all, they normally pretended she didn’t have a
                        sister.
                        ‘No,’ she said sharply. ‘Why?’
                        ‘Funny stuff on the news,’ Mr Dursley mumbled. ‘Owls …
                        shooting stars … and there were a lot of funny- looking people
                        in town today …’
                        ‘So?’ snapped Mrs Dursley.
                        ‘Well, I just thought … maybe … it was something to do
                        with … you know … her lot .’
                        Mrs Dursley sipped her tea through pursed lips. Mr Dursley
                        wondered whether he dared tell her he’d heard the name
                        ‘Potter’. He decided he didn’t dare. Instead he said, as casually
                        as he could, ‘ Their son – he’d be about Dudley’s age now,
                        wouldn’t he?’ 
                    </div>
                </div>);
            } else if (displayed === 3) {
                return (
                    <div className="margin-3">
                        <div className="text title body-title">Video Tutorials</div>
                        <div className="text body">
                        Mr and Mrs Dursley, of number four, Privet Drive, were
                        proud to say that they were perfectly normal, thank
                        you very much. They were the last people you’d expect to be
                        involved in anything strange or mysterious, because they just
                        didn’t hold with such nonsense.
                        Mr Dursley was the director of a fi rm called Grunnings,
                        which made drills. He was a big, beefy man with hardly
                        any neck, although he did have a very large moustache.
                        Mrs Dursley was thin and blonde and had nearly twice the
                        usual amount of neck, which came in very useful as she spent
                        so much of her time craning over garden fences, spying on the
                        neighbours. The Dursleys had a small son called Dudley and
                        in their opinion there was no fi ner boy anywhere.
                        The Dursleys had everything they wanted, but they also
                        had a secret, and their greatest fear was that somebody would
                        discover it. They didn’t think they could bear it if anyone
                        found out about the Potters. Mrs Potter was Mrs Dursley’s
                        sister, but they hadn’t met for several years; in fact, Mrs
                        Dursley pretended she didn’t have a sister, because her sister
                        and her good- for-nothing husband were as unDursleyish as it
                        was possible to be. The Dursleys shuddered to think what the
                        neighbours would say if the Potters arrived in the street. The
                        Dursleys knew that the Potters had a small son, too, but they
                        had never even seen him. This boy was another good reason
                        for keeping the Potters away; they didn’t want Dudley mixing
                        with a child like that.
                        When Mr and Mrs Dursley woke up on the dull, grey
                        Tuesday our story starts, there was nothing about the cloudy
                        sky outside to suggest that strange and mysterious things
                        would soon be happening all over the country. Mr Dursley
                        hummed as he picked out his most boring tie for work and
                        Mrs Dursley gossiped away happily as she wrestled a screaming
                        Dudley into his high chair.
                        None of them noticed a large tawny owl fl utter past the
                        window.
                        At half past eight, Mr Dursley picked up his briefcase,
                        pecked Mrs Dursley on the cheek and tried to kiss Dudley
                        goodbye but missed, because Dudley was now having a
                        tantrum and throwing his cereal at the walls. ‘Little tyke,’
                        chortled Mr Dursley as he left the house. He got into his car
                        and backed out of number four’s drive.
                        It was on the corner of the street that he noticed the fi rst
                        sign of something peculiar – a cat reading a map. For a second,
                        Mr Dursley didn’t realise what he had seen – then he jerked
                        his head around to look again. There was a tabby cat standing
                        on the corner of Privet Drive, but there wasn’t a map in sight.
                        What could he have been thinking of? It must have been a
                        trick of the light. Mr Dursley blinked and stared at the cat. It
                        stared back. As Mr Dursley drove around the corner and up
                        the road, he watched the cat in his mirror. It was now reading
                        the sign that said Privet Drive – no, looking at the sign; cats
                        couldn’t read maps or signs. Mr Dursley gave himself a little
                        shake and put the cat out of his mind. As he drove towards
                        town he thought of nothing except a large order of drills he
                        was hoping to get that day.
                        But on the edge of town, drills were driven out of his mind
                        by something else. As he sat in the usual morning traffi c jam, he
                        couldn’t help noticing that there seemed to be a lot of strangely
                        dressed people about. People in cloaks. Mr Dursley couldn’t
                        bear people who dressed in funny clothes – the get- ups you saw
                        on young people! He supposed this was some stupid new
                        fashion. He drummed his fi ngers on the steering wheel and his
                        eyes fell on a huddle of these weirdos standing quite close by.
                        They were whispering excitedly together. Mr Dursley was
                        enraged to see that a couple of them weren’t young at all; why,
                        that man had to be older than he was, and wearing an emeraldgreen cloak! The nerve of him! But then it struck Mr Dursley
                        that this was probably some silly stunt – these people were
                        obviously collecting for something … yes, that would be it.
                        The traffi c moved on, and a few minutes later, Mr Dursley
                        arrived in the Grunnings car park, his mind back on drills.
                        Mr Dursley always sat with his back to the window in his
                        office on the ninth fl oor. If he hadn’t, he might have found it
                        harder to concentrate on drills that morning. He didn’t see the
                        owls swooping past in broad daylight, though people down in
                        the street did; they pointed and gazed open- mouthed as owl
                        after owl sped overhead. Most of them had never seen an owl
                        even at night- time. Mr Dursley, however, had a perfectly
                        normal, owl- free morning. He yelled at fi ve different people.
                        He made several important telephone calls and shouted a bit
                        more. He was in a very good mood until lunchtime, when he
                        thought he’d stretch his legs and walk across the road to buy
                        himself a bun from the baker’s opposite.
                        He’d forgotten all about the people in cloaks until he
                        passed a group of them next to the baker’s. He eyed them
                        angrily as he passed. He didn’t know why, but they made him
                        uneasy. This lot were whispering excitedly, too, and he
                        couldn’t see a single collecting tin. It was on his way back past
                        them, clutching a large doughnut in a bag, that he caught a
                        few words of what they were saying.
                        ‘ The Potters, that’s right, that’s what I heard –’
                        ‘– yes, their son, Harry –’
                        Mr Dursley stopped dead. Fear fl ooded him. He looked
                        back at the whisperers as if he wanted to say something to
                        them, but thought better of it.
                        </div>
                    </div>
                );
            } else if (displayed === 4) {
                return (
                    <div className="margin-3">
                        <div className="text title body-title">The Other Title</div>
                        <div className="text body">
                            ‘I suppose so,’ said Mrs Dursley stiffl y.
                            ‘What’s his name again? Howard, isn’t it?’
                            ‘Harry. Nasty, common name, if you ask me.’
                            ‘Oh, yes,’ said Mr Dursley, his heart sinking horribly. ‘Yes,
                            I quite agree.’
                            He didn’t say another word on the subject as they went
                            upstairs to bed. While Mrs Dursley was in the bathroom, Mr
                            Dursley crept to the bedroom window and peered down into
                            the front garden. The cat was still there. It was staring down
                            Privet Drive as though it was waiting for something.
                            Was he imagining things? Could all this have anything to
                            do with the Potters? If it did … if it got out that they were
                            related to a pair of – well, he didn’t think he could bear it.
                            The Dursleys got into bed. Mrs Dursley fell asleep quickly
                            but Mr Dursley lay awake, turning it all over in his mind. His
                            last, comforting thought before he fell asleep was that even if
                            the Potters were involved, there was no reason for them to
                            come near him and Mrs Dursley. The Potters knew very well
                            what he and Petunia thought about them and their kind …
                            He couldn’t see how he and Petunia could get mixed up in
                            anything that might be going on. He yawned and turned over.
                            It couldn’t affect them …
                            How very wrong he was.
                            Mr Dursley might have been drifting into an uneasy sleep,
                            but the cat on the wall outside was showing no sign of
                            sleepiness. It was sitting as still as a statue, its eyes fi xed
                            unblinkingly on the far corner of Privet Drive. It didn’t so
                            much as quiver when a car door slammed in the next street,
                            28493.indb 8 8493.indb 8 18/07/2014 16:36 8/07/2014 16:36
                            9
                            THE BOY WHO LIVED
                            nor when two owls swooped overhead. In fact, it was nearly
                            midnight before the cat moved at all.
                            A man appeared on the corner the cat had been watching,
                            appeared so suddenly and silently you’d have thought he’d
                            just popped out of the ground. The cat’s tail twitched and its
                            eyes narrowed.
                            Nothing like this man had ever been seen in Privet Drive.
                            He was tall, thin and very old, judging by the silver of his hair
                            and beard, which were both long enough to tuck into his belt.
                            He was wearing long robes, a purple cloak which swept the
                            ground and high- heeled, buckled boots. His blue eyes were
                            light, bright and sparkling behind half- moon spectacles and
                            his nose was very long and crooked, as though it had been
                            broken at least twice. This man’s name was Albus Dumbledore.
                            Albus Dumbledore didn’t seem to realise that he had just
                            arrived in a street where everything from his name to his boots
                            was unwelcome. He was busy rummaging in his cloak, looking
                            for something. But he did seem to realise he was being
                            watched, because he looked up suddenly at the cat, which was
                            still staring at him from the other end of the street. For some
                            reason, the sight of the cat seemed to amuse him. He chuckled
                            and muttered, ‘I should have known.’
                            He had found what he was looking for in his inside pocket.
                            It seemed to be a silver cigarette lighter. He fl icked it open,
                            held it up in the air and clicked it. The nearest street lamp
                            went out with a little pop. He clicked it again – the next lamp
                            fl ickered into darkness. Twelve times he clicked the Put-Outer,
                            until the only lights left in the whole street were two tiny
                            28493.indb 9 8493.indb 9 18/07/2014 16:36 8/07/2014 16:36
                            HARRY POTTER AND THE PHILOSOPHER’S STONE
                            10
                            pinpricks in the distance, which were the eyes of the cat
                            watching him. If anyone looked out of their window now,
                            even beady- eyed Mrs Dursley, they wouldn’t be able to see
                            anything that was happening down on the pavement.
                            Dumbledore slipped the Put-Outer back inside his cloak and
                            set off down the street towards number four, where he sat
                            down on the wall next to the cat. He didn’t look at it, but after
                            a moment he spoke to it.
                            ‘Fancy seeing you here, Professor McGonagall.’
                            He turned to smile at the tabby, but it had gone. Instead he
                            was smiling at a rather severe- looking woman who was
                            wearing square glasses exactly the shape of the markings the
                            cat had had around its eyes. She, too, was wearing a cloak, an
                            emerald one. Her black hair was drawn into a tight bun. She
                            looked distinctly ruffl ed.
                            ‘How did you know it was me?’ she asked.
                            ‘My dear Professor, I’ve never seen a cat sit so stiffl y.’
                            ‘You’d be stiff if you’d been sitting on a brick wall all day,’
                            said Professor McGonagall.
                            ‘All day? When you could have been celebrating? I must
                            have passed a dozen feasts and parties on my way here.’
                            Professor McGonagall sniffed angrily.
                            ‘Oh yes, everyone’s celebrating, all right,’ she said impatiently. ‘You’d think they’d be a bit more careful, but no –
                            even the Muggles have noticed something’s going on. It was
                            on their news.’ She jerked her head back at the Dursleys’ dark
                            living- room window. ‘I heard it. Flocks of owls … shooting
                            stars … Well, they’re not completely stupid. They were bound
                            28493.indb 10 8493.indb 10 18/07/2014 16:36 8/07/2014 16:36
                            11
                            THE BOY WHO LIVED
                            to notice something. Shooting stars down in Kent – I’ll bet
                            that was Dedalus Diggle. He never had much sense.’
                            ‘You can’t blame them,’ said Dumbledore gently. ‘We’ve
                            had precious little to celebrate for eleven years.’
                            ‘I know that,’ said Professor McGonagall irritably. ‘But
                            that’s no reason to lose our heads. People are being downright
                            careless, out on the streets in broad daylight, not even dressed
                            in Muggle clothes, swapping rumours.’
                            She threw a sharp, sideways glance at Dumbledore here, as
                            though hoping he was going to tell her something, but he
                            didn’t, so she went on: ‘A fi ne thing it would be if, on the very
                            day You-Know-Who seems to have disappeared at last, the
                            Muggles found out about us all. I suppose he really has gone,
                            Dumbledore?’
                            ‘It certainly seems so,’ said Dumbledore. ‘We have much to
                            be thankful for. Would you care for a sherbet lemon?’
                            ‘A what ?’
                            ‘A sherbet lemon. They’re a kind of Muggle sweet I’m
                            rather fond of.’
                            ‘No, thank you,’ said Professor McGonagall coldly, as
                            though she didn’t think this was the moment for sherbet
                            lemons. ‘As I say, even if You-Know-Who has gone –’
                            ‘My dear Professor, surely a sensible person like yourself
                            can call him by his name? All this “You-Know-Who” nonsense – for eleven years I have been trying to persuade people
                            to call him by his proper name: Voldemort .’ Professor McGonagall fl inched, but Dumbledore, who was unsticking two
                            sherbet lemons, seemed not to notice. ‘It all gets so confusing
                            28493.indb 11 8493.indb 11 18/07/2014 16:36 8/07/2014 16:36
                            HARRY POTTER AND THE PHILOSOPHER’S STONE
                            12
                            if we keep saying “You-Know-Who”.’ I have never seen any
                            reason to be frightened of saying Voldemort’s name.’
                            ‘I know you haven’t,’ said Professor McGonagall, sounding
                            half- exasperated, half- admiring. ‘But you’re different. Everyone knows you’re the only one You-Know – oh, all right,
                            Voldemort – was frightened of.’
                            ‘You fl atter me,’ said Dumbledore calmly. ‘Voldemort had
                            powers I will never have.’
                            ‘Only because you’re too – well – noble to use them.’
                            ‘It’s lucky it’s dark. I haven’t blushed so much since Madam
                            Pomfrey told me she liked my new earmuffs.’
                            Professor McGonagall shot a sharp look at Dumbledore
                            and said, ‘ The owls are nothing to the rumours that are fl ying
                            around. You know what everyone’s saying? About why he’s
                            disappeared? About what fi nally stopped him?’
                            It seemed that Professor McGonagall had reached the
                            point she was most anxious to discuss, the real reason she had
                            been waiting on a cold hard wall all day, for neither as a cat
                            nor as a woman had she fi xed Dumbledore with such a piercing
                            stare as she did now. It was plain that whatever ‘everyone’ was
                            saying, she was not going to believe it until Dumbledore told
                            her it was true. Dumbledore, however, was choosing another
                            sherbet lemon and did not answer.
                            ‘What they’re saying ,’ she pressed on, ‘is that last night
                            Voldemort turned up in Godric’s Hollow. He went to fi nd the
                            Potters. The rumour is that Lily and James Potter are – are –
                            that they’re – dead .’ 
                        </div>
                    </div>
                );
            } else {
              return (<div></div>)
          }
    }

    return (
        <div className="main-container">
            <div className="margin-10 top">
                <div className="dramatic-title text">Hi, how can we help?</div>
                <div className="input-row input-box margin-3">
                    <SearchIcon className="icon margin-1" />
                    <FilteredSearchBar objects={topics} setFiltered={setTopics} placeholder="Enter your search terms"/>
                    <button type="search" className="btn btn-primary body">Search</button>
                </div>
                <div className="margin-5 row text body">
                    Or Browse Categories
                </div>
            </div>
            <div className="button-cards row">
                <button type="topic" className="btn btn-card" onClick={() => {setDisplayed(1)}}>
                    <div className="col margin-1 bottom">
                        <div className="text body-title bold margin-1">Getting Started</div>
                        <div className="text body">Trying to deploy your first pipeline? This will show you how to become a pro at DataConnector.</div>
                    </div>
                </button>
                <button type="topic" className="btn btn-card" onClick={() => {setDisplayed(2)}}>
                    <div className="col margin-1 bottom">
                        <div className="text body-title bold margin-1">Connectors Help</div>
                        <div className="text body">Trying to deploy your first pipeline? This will show you how to become a pro at DataConnector.</div>
                    </div>
                </button>
                <button type="topic" className="btn btn-card" onClick={() => {setDisplayed(3)}}>
                    <div className="col margin-1 bottom">
                        <div className="text body-title bold margin-1">Video Tutorials</div>
                        <div className="text body">Trying to deploy your first pipeline? This will show you how to become a pro at DataConnector.</div>
                    </div>
                </button>
                <button type="topic" className="btn btn-card"  onClick={() => {setDisplayed(4)}}>
                    <div className="col margin-1 bottom">
                        <div className="text body-title bold margin-1">ANOTHER TOPIC</div>
                        <div className="text body">Hey Joe, I just came up with these topics off the top of my head. What else?</div>
                    </div>
                </button>
            </div>
            <div className="margin-3">{formatDisplay()}</div>
            <div className="box-container margin-5">
                <div className="text title body-title">Not finding what you are looking for?</div>
                <div className="text body">Let us know, and we will try our best to help you.</div>
                {contactForm}
            </div>
        </div>
    )
}