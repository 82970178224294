import React, { useState, useEffect } from "react";
import { Card, Link, CircularProgress, Box } from "@mui/material";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { ConnectorAPIService, PipelineAPIService } from "../../../config/api-service";
import { FileDownload, CloudDownload } from "@mui/icons-material";

export default function RunHistoryComponent ({ job, openRunning = false, handleClose }) {
    const [rows, setRows] = useState([]);
    const [loadingHistory, setLoadingHistory] = useState(true);
    const [sortModel, setSortModel] = useState([
        {
            field: 'PipelineStartDTS',
            sort: 'desc'
        }
    ])

    useEffect(() => {
        setLoadingHistory(true);
        PipelineAPIService.getLoadHistory(job).then((res) => {
            setRows(res);
            setLoadingHistory(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [job]);

    useEffect(() => {
        if (!openRunning) return
        const running = rows.find(({ StatusDSC }) => StatusDSC === 'Running')
        if (running?.id) {
            window.open(`/logs/${running.id}`, "_blank")
            handleClose()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rows])

    const formatDate = (value) => {
        let MM,
            dd,
            yy,
            hh,
            mm,
            ap,
            formatted = "";
        if (value) {
            let valueDate = new Date(value);
            dd = String(valueDate.getDate()).padStart(2, "0");
            MM = String(valueDate.getMonth() + 1).padStart(2, "0");
            yy = String(valueDate.getFullYear()).substring(2);
            hh = String(
                valueDate.getHours() > 12
                    ? valueDate.getHours() - 12
                    : valueDate.getHours()
            );
            mm = String(valueDate.getMinutes()).padStart(2, "0");
            ap = valueDate.getHours() > 12 ? "PM" : "AM";

            formatted = `${MM}/${dd}/${yy} ${hh}:${mm} ${ap}`;
        }

        return formatted;
    };

    const formatRowCount = (value) => {
        let digi = ["K", "M", "B"];
        let final = value;
        value = parseInt(value);

        if (value > 999) {
            value = ((value - (value % 1000)) / 1000).toString();
            final = value + digi[0];
        } else if (value > 999999) {
            value = ((value - (value % 1000000)) / 1000000).toString();
            final = value + digi[1];
        } else if (value > 999999999) {
            value = ((value - (value % 1000000000)) / 1000000000).toString();
            final = value + digi[3];
        }

        return final;
    };

    const bytesToSize = (bytes) => {
        if (isNaN(bytes) || bytes === null) return "";
        bytes = parseInt(bytes);
        var sizes = ["B", "KB", "MB", "GB", "TB"];
        if (bytes === 0) return "0";
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    };

    const columns = [
        {
            field: "PipelineStartDTS",
            headerName: "START DATE",
            headerClassName: "pipelines-table-header",
            width: 150,
            valueFormatter: ({ value }) => formatDate(value)
        },
        {
            field: "PipelineEndDTS",
            headerName: "END DATE",
            headerClassName: "pipelines-table-header",
            width: 150,
            valueFormatter: ({ value }) => formatDate(value),
        },
        {
            field: "id",
            headerName: "MINUTES",
            headerClassName: "pipelines-table-header",
            width: 100,
            renderCell: ({row}) => {
                let diff = 0
                if (row.PipelineEndDTS) diff = new Date(row.PipelineEndDTS) - new Date(row.PipelineStartDTS)
                else if (row.update_dts) diff = new Date(row.update_dts) - new Date(row.PipelineStartDTS)
                
                return <div>{(diff / 60000).toFixed(2)}</div>
            }
        },
        {
            field: "RowsInsertedNBR",
            headerName: "ROWS",
            headerClassName: "pipelines-table-header",
            width: 90,
            valueFormatter: ({ value }) => formatRowCount(value),
        },
        {
            field: "BytesTransferredNBR",
            headerName: "BYTES",
            headerClassName: "pipelines-table-header",
            width: 100,
            valueFormatter: ({ value }) => bytesToSize(value),
        },
        {
            field: "StatusDSC",
            headerName: "STATUS",
            headerClassName: "pipelines-table-header",
            width: 90,
        },
        {
            field: "Logs",
            headerName: "LOGS",
            headerClassName: "pipelines-table-header",
            width: 70,
            sortable: false,
            renderCell: (props) => (
                <>
                    <Link href={`/logs/${props.id}`} target="_blank">
                        Open
                    </Link>
                </>
            ),
        },
        {
            field: "downloadable",
            renderHeader: (params) => (<CloudDownload />),
            headerClassName: "pipelines-table-header",
            width: 50,
            sortable: false,
            renderCell: (props) => {
                const downloadFile = () => {
                    ConnectorAPIService.downloadRunFile(props.id).catch(err => {
                        console.log(err)
                    })
                }

                if (props.value) {
                    return (<div onClick={downloadFile} style={{display: 'flex', alignItems: 'center'}}>
                            <FileDownload style={{color: 'var(--data-con-blue)', cursor: 'pointer'}} />
                    </div>)
                }
                return <></>;
            },
        },
    ];

    const CustomNoRowsOverlay = () => (
        <GridOverlay>This pipeline has not run yet.</GridOverlay>
    );

    if (openRunning && !rows?.length) return (
        <Box sx={{ display: 'flex', height: 1, alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress />
        </Box>
    )

    return (
        <Card style={{ margin: "10% auto 0 auto", padding: 10, width: 820}}>
            <div className="text title body-title">RUN HISTORY</div>
            <div style={{ height: "40vh" }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    disableSelectionOnClick
                    density="compact"
                    disableColumnMenu={true}
                    hideFooter
                    loading={loadingHistory}
                    getRowClassName={(params) => {
                        let className = "";
                        if (
                            params.getValue(params.id, "StatusDSC") === "Failed"
                        ) {
                            className = "pipelines-table-row-failed";
                        }
                        return className;
                    }}
                    components={{
                        NoRowsOverlay: CustomNoRowsOverlay,
                    }}
                    sortModel={sortModel}
                    onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                />
            </div>
        </Card>
    );
}
