import { useState } from 'react'

const SinglePipelineRow = ({ connector, count, countEl, currencyAlignment }) => {
    const addCommas = (num) =>
        num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const [isExpand, setIsExpand] = useState(false)

    return (<div className='connector-list-item'>
        <div className='connector-list-item-header' style={{ position: 'relative' }} onClick={() => setIsExpand(!isExpand)} >
            <div className={'expand' + (!!isExpand ? "  expanded" : "")}> </div>
            <div className='connector-item-title'> {connector.name}  (  {count} {countEl} )</div>
            <div className='amount' style={currencyAlignment && { position: 'absolute', right: 0 }}> {addCommas(connector.value)} </div>
        </div>
        {
            !!isExpand &&
            <div className='connector-list-item-details'>
                <div className='connector-list-item-details-list'>
                    {
                        connector.details.sort((a, b) => b.value - a.value).map((detail, detailIndex) => (
                            <div className='connector-list-item-details-list-item' style={{ position: 'relative' }} key={detailIndex} >
                                <div className='connector-item-title'> {detail.name} </div>
                                <div className='amount' style={currencyAlignment && { position: 'absolute', right: 0 }}> {addCommas(detail.value)} </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        }
    </div>);

}


export default SinglePipelineRow;