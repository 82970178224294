import { useEffect, useState, useContext } from 'react'
import { format, parseISO } from 'date-fns'
import { CSVLink } from 'react-csv'
import { Button, Grid, Typography } from '@mui/material'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import MaterialUITheme from '../../../config/material-ui-theme'
import SingleConnector from "./singleConnector"
import SinglePipelineRow from './singlePipelineRow'
import RangeSelect from "./RangeSelect"
import { AccountAPIService } from '../../../config/api-service'
import { CommonLoaderContext } from "../../../config/router"
import './style.css'



export default function BillingStatementsComponent() {
    const [, setCommonLoader] = useContext(CommonLoaderContext)
    const [dateList, setDateList] = useState([])
    const [billingDate, setBillingDate] = useState({})
    const [rangeLabel, setRangeLabel] = useState('')
    const [bills, setBills] = useState([])
    const [isTrial, setIsTrial] = useState(false)
    const [pipelineRuns, setPipelineRuns] = useState([])
    const [pipelineRunCharge, setPipelineRunCharge] = useState(0)
    const [connectorList, setConnectorList] = useState([{
        name: 'Connectors',
        currency: '$',
        value: 0,
        details: []
    }])
    const [pipelineRunList, setPipelineRunList] = useState([
        {
            name: 'Pipeline rows inserted:',
            currency: '$',
            value: 0,
        },
        {
            name: 'Pipeline charge/million rows inserted:',
            currency: '$',
            value: 0,
        }
    ])

    const getTotal = (dataList) => {
        let total = 0;
        dataList.forEach((data) => {
            total += Number(data.value || 0)
        })
        return total
    }

    useEffect(() => {
        const getDateList = async () => {
            setCommonLoader(true)
            try {
                const { billingDateList = [] } = await AccountAPIService.getDateList()
                setDateList(billingDateList)
                setBillingDate(billingDateList[0])
            } catch { }
        }
        getDateList()
    }, [])

    const getBills = async ({ billing_start_dt, billing_end_dt }) => {
        setCommonLoader(true)

        try {
            const data = await AccountAPIService.getBilling({
                startDate: billing_start_dt,
                endDate: billing_end_dt
            })
            setBills(data.connectorBill)
            setPipelineRuns(data?.pipeineRunBill)
            setCommonLoader(false)
        } catch {
            setCommonLoader(false)
        }
    }

    useEffect(() => {
        if (Object.keys(billingDate)?.length) {
            getBills(billingDate)
            setRangeLabel(`${format(parseISO(billingDate.billing_start_dt), 'MM/dd/yyyy')} - ${format(parseISO(billingDate.billing_end_dt), 'MM/dd/yyyy')}`)
        }
    }, [billingDate])

    useEffect(() => {
        if (!bills) return
        setConnectorList([
            {
                name: 'Connectors',
                currency: '$',
                value: bills.reduce(
                    (previousValue, { connector_monthly_price_nbr }) => previousValue + +connector_monthly_price_nbr,
                    0
                ),
                details: bills.map(({ connector_nm, connection_cnt, connector_monthly_price_nbr }) => ({
                    name: connector_nm,
                    currency: '$',
                    count: connection_cnt,
                    value: connector_monthly_price_nbr || 0
                }))
            }
        ])

        setPipelineRunList([
            {
                name: 'Pipeline rows inserted:',
                currency: 'minutes',
                value: pipelineRuns.reduce(
                    (previousValue, { pipeline_rows_inserted_nbr }) => previousValue + +pipeline_rows_inserted_nbr,
                    0
                ),
                details: pipelineRuns.map(({ pipeline_nm, pipeline_rows_charge_amt, pipeline_rows_inserted_nbr }) => ({
                    name: pipeline_nm,
                    currency: 'minutes',
                    charge: pipeline_rows_charge_amt,
                    value: pipeline_rows_inserted_nbr || 0
                }))
            }
        ])

        setPipelineRunCharge(pipelineRuns.reduce(
            (previousValue, { pipeline_rows_charge_amt }) => previousValue + +pipeline_rows_charge_amt,
            0
        ))
    }, [bills, pipelineRuns])

    const csvHeader = [
        { label: "Date", key: "date" },
        { label: "Charge Type", key: "chargeType" },
        { label: "Charge Name", key: "chargeName" },
        { label: "Amount", key: "amount" },
        { label: "Charge", key: "charge" }
    ]
    const [csvData, setCsvData] = useState([])
    useEffect(() => {
        let data = []
        if (connectorList[0]?.details?.length) {
            data = [...data, ...connectorList[0].details.map(con => ({
                date: `${billingDate.billing_display_dt}`,
                chargeType: 'Connector',
                chargeName: con?.name,
                amount: con?.count,
                charge: con?.currency + ' ' + con?.value
            }))]
        }
        if (pipelineRunList[0]?.details?.length) {
            data = [...data, ...pipelineRunList[0].details.map(pl => ({
                date: `${billingDate.billing_display_dt}`,
                chargeType: 'Pipeline',
                chargeName: pl?.name,
                amount: pl?.value,
                charge: '$' + pl?.charge
            }))]
        }

        data = [
            ...data,
            {
                date: '',
                chargeType: '',
                chargeName: '',
                amount: '',
                charge: ''
            },
            {
                date: 'Total',
                chargeType: '',
                chargeName: '',
                amount: '',
                charge: '$ ' + (getTotal(connectorList) + pipelineRunCharge)
            }
        ]
        setCsvData(data)
    }, [connectorList, pipelineRunList])



    const formatNumber = (num) => {
        return num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

    return (
        <MaterialUITheme>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: '20px' }}>
                <div style={{ width: '80%' }}>
                    <div className='accounts-header'>
                        <span>Billing  Statement</span>
                    </div>
                    <Typography component={'div'} variant="h6" textAlign="center" fontWeight={600} sx={{ color: isTrial && "#4caf50" }}>
                        {rangeLabel}
                    </Typography>
                    <Grid container spacing={2} className='p-20'>
                        <Grid item xs={12} sm={6}>
                            <RangeSelect list={dateList} onChange={setBillingDate} setIsTrial={setIsTrial} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className='button-list'>
                                <CSVLink
                                    filename={'Billing-Statement.csv'}
                                    data={csvData}
                                    headers={csvHeader}
                                >
                                    <Button
                                        size="small"
                                        className='float-right csv-Download-btn'
                                    ><FileDownloadIcon />Download CSV </Button>
                                </CSVLink>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={10} className='p-20'>
                        <Grid item xs={12} sm={6}>
                            <div className='connector-area'>
                                <h3>Connector</h3>
                                <div className='connector-list' style={{ width: '100%' }}>
                                    {
                                        connectorList.map((connector, index) => (
                                            <SingleConnector connector={connector} key={index}
                                                enterpriseText={connector?.details?.length > 4 ? 'Enterprise Pricing' : ''}
                                                count={connector?.details?.length > 4 ? connector?.details?.length - 1 : connector?.details?.length}
                                            />
                                        ))
                                    }
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className='pipe-line-run-area'>
                                <h3>Pipeline Runs</h3>
                                <div className='pipe-line-run-list'>
                                    {
                                        pipelineRunList.map((pipelineRun, index) => (
                                            <SinglePipelineRow connector={pipelineRun} count={pipelineRun?.details?.length} countEl="pipelines" currencyAlignment="right" key={index} />
                                        ))
                                    }
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={10} className='p-20'>
                        <Grid item xs={12} sm={6}>
                            <div className='connector-total'>
                                <div className='connector-total-title'> Connector Charges {connectorList[0].details.length > 4 ? '(Enterprise)' : ''} </div>
                                <div className='amount'> {formatNumber(getTotal(connectorList))} </div>
                                <div className='currency'> $ </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className='pipe-line-run-total'>
                                <div className='pipe-line-run-total-title'> Pipeline charge/million rows inserted: </div>
                                <div className='amount'> {formatNumber(+pipelineRuns[0]?.price_per_million_rows_nbr || 0)} </div>
                                <div className='currency'> $ </div>
                            </div>
                            <div className='pipe-line-run-total'>
                                <div className='pipe-line-run-total-title'> Pipeline row charges </div>
                                <div className='amount'> {formatNumber(pipelineRunCharge)} </div>
                                <div className='currency'> $ </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <hr />
                            {isTrial ? <div className='all-charges' style={{ color: "#4caf50" }}>
                                <div className='total-charges total-panel-font'> 7-Day Free Trial: </div>
                                <div className='amount total-panel-font'> 0.00 </div>
                                <div className='currency total-panel-font'> $ </div>
                            </div> : <div className='all-charges'>
                                <div className='total-charges total-panel-font'> Total charges: </div>
                                <div className='amount total-panel-font'> {formatNumber(getTotal(connectorList) + pipelineRunCharge)} </div>
                                <div className='currency total-panel-font'> $ </div>
                            </div>}
                        </Grid>
                    </Grid>
                </div>
            </div>
        </MaterialUITheme>
    )
}
