import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { usePipelineContext } from "../pipeline.context";
import ConnectionsDropdown from './ConnectionsDropdown'
import { Box, CardMedia, CircularProgress, Grid, useMediaQuery } from '@mui/material';
import { Tooltip, TextField } from '@mui/material';
import { ConnectorAPIService, EventLogAPIService } from '../../../config/api-service';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import ConnectorAuthenticationModal from './ConnectorAuthenticationModal';
import Dialog from '../../common/dialog'
import ConnectionEditForm from './ConnectionEditForm';
import { CONNECTOR_REDUCER_TYPES } from '../../../reducers/connectorReducer';
import FolderIcon from '@mui/icons-material/Folder';
import { toast } from 'react-toastify';

export default function ConnectorHolder () {
    const {
        sourceState,
        destinationState,
        dispatchSource,
        dispatchDestination,
        testConnection,
        getConnections,
        getSourceFields,
    } = usePipelineContext();

    const [showSourceAuthenticationModal, setShowSourceAuthenticationModal] = useState(false);
    const [showDestinationAuthenticationModal, setShowDestinationAuthenticationModal] = useState(false);
    const [sourceAuthenticationProps, setSourceAuthenticationProps] = useState();
    const [destinationAuthenticationProps, setDestinationAuthenticationProps] = useState();

    const matches = useMediaQuery('(max-width:599px)');
    const [deleteModal, setDeleteModal] = useState(null);

    useEffect(() => {
        if (sourceState.connectorId !== null && !sourceState.connections.length && !sourceState.loadingConnections && !sourceState.connector.SourceUploadFLG) {
            setSourceAuthenticationProps({})
            setShowSourceAuthenticationModal(true);
        }
    }, [sourceState.connectorId, sourceState.connections, sourceState.loadingConnections, sourceState.connector])

    useEffect(() => {
        if (destinationState.connectorId !== null && !destinationState.connections.length && !destinationState.loadingConnections && !destinationState.connector.DestinationDownloadFLG) {
            setDestinationAuthenticationProps({})
            setShowDestinationAuthenticationModal(true);
        }
    }, [destinationState.connectorId, destinationState.connections, destinationState.loadingConnections, destinationState.connector])

    const handleDeleteConnection = async ({ connector_id, connector_credential_id, source }) => {
        ConnectorAPIService.deleteConnection({
            connector_id,
            connector_credential_id
        }).then(res => {
            if (!res?.message) {
                toast.error("An internal server error has occurred.");
            } else {
                if (source) {
                    getConnections(sourceState.connectorId, dispatchSource, (+connector_credential_id === +sourceState.credentialId));
                } else {
                    getConnections(destinationState.connectorId, dispatchDestination, (+connector_id === +destinationState.credentialId));
                }

                toast.success(res?.message)
            }
        })

        setDeleteModal(null)
    }

    const handleChangeDefaultConnection = async (payload) => {
        const { source, ...rest } = payload
        await ConnectorAPIService.updateDefaultConnection({ ...rest })
        if (source) {
            getConnections(sourceState.connectorId, dispatchSource, false);
        } else {
            getConnections(destinationState.connectorId, dispatchDestination, false);
        }
    }

    const handlePlaceHolderClicked = (isSource) => {
        if ((isSource && sourceState.image.includes("_inactive")) || (!isSource && destinationState.image.includes("_inactive"))) {
            let eventMessage = isSource ? "Source Placeholder was clicked" : "Destination Placeholder was clicked";
            EventLogAPIService.postEvent(eventMessage, null);
        }
    }

    const handleCsvFile = async (e) => {
        if (e.target.files.length) {
            ConnectorAPIService.uploadFile(e.target.files[0]).then(data => {
                dispatchSource({type: CONNECTOR_REDUCER_TYPES.SET_SELECTED_OBJECT, payload: { selectedObject: data.object_id, selectedObjectName: e.target.files[0].name, options: {}}});
                dispatchSource({type: CONNECTOR_REDUCER_TYPES.CONNECTION_TEST, payload: { objects: [data]}});
                getSourceFields({selectedObject: data.object_id});
            })
        }
    }

    return (
        <div>
            <Grid sx={{ maxWidth: '95%', mx: 'auto', mt: 2 }} container spacing={1}>
                <Grid item xs={6} sx={{ "& .MuiGrid-root": { p: 0 } }}>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                        <div>
                            <CardMedia
                                component={"img"}
                                alt="Source Placeholder"
                                image={sourceState.image}
                                onClick={() => handlePlaceHolderClicked(false)}
                                sx={{ borderRadius: (sourceState.connectorId) ? '10px' : '0', maxWidth: { xs: 150, sm: 220, md: 250 } }}
                            />
                        </div>
                        <div className="account-dropdown">
                            <Box sx={{ position: "absolute", left: { xs: "-23px", sm: "-30px" } }}>
                                {(sourceState.testingConnection) ? (<CircularProgress thickness={4} size={matches ? 21 : 25} />) : ""}
                                {(!!sourceState.credentialId && !sourceState.testingConnection) ?
                                    ((sourceState.connected) ?
                                        (<Tooltip title={sourceState.connectionMessage}><CheckCircleIcon sx={{ color: "#4bb543" }} /></Tooltip>) :
                                        (<Tooltip title={sourceState.connectionMessage}><ErrorIcon sx={{ color: "#f32013" }} /></Tooltip>)) : ""
                                }
                            </Box>
                            {sourceState.connector && sourceState.connector.SourceUploadFLG ? 
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                            <TextField size='small' value={sourceState.selectedObjectName || "No File Selected"} />
                            <label htmlFor="file-upload">
                                <FolderIcon className={sourceState.selectedObjectName === "" ? "border-pulse" : ""} sx={{ fontSize: "36px", color: "var(--data-con-blue)", cursor: "pointer", ml: 1, mt: 1 }} />
                            </label>
                            <input id='file-upload' type="file" accept='.csv' onChange={handleCsvFile} style={{ display: "none" }} />
                        </Box> :
                            <ConnectionsDropdown
                                credentialID={sourceState.credentialId}
                                connectorID={sourceState.connectorId}
                                connections={sourceState.connections}
                                selectConnection={(val) => {
                                    // active selection was selected, return
                                    const id = Number(val.connector_credential_id)
                                    if (+sourceState.credentialId === +id) return

                                    dispatchSource({type: CONNECTOR_REDUCER_TYPES.CONNECTION_SELECTED, payload: {credentialId: id}});
                                    testConnection(sourceState.connectorId, val.connector_credential_id, dispatchSource);
                                }}
                                createConnection={() => {
                                    setShowSourceAuthenticationModal(true);
                                    setDestinationAuthenticationProps({})
                                }}
                                editConnection={(connection) => {
                                    setShowSourceAuthenticationModal(true);
                                    setSourceAuthenticationProps(prev => ({...prev, ...connection}))
                                }}
                                deleteConnection={(connection) => {
                                    setDeleteModal({ ...connection, source: true })
                                }}
                                onChangeDefault={(payload) => {
                                    handleChangeDefaultConnection({...payload, source: true, })
                                }}
                            /> }
                        </div>

                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                        <div>
                            <CardMedia
                                component={"img"}
                                alt="Destination Placeholder"
                                image={destinationState.image}
                                onClick={() => handlePlaceHolderClicked(false)}
                                sx={{ borderRadius: (destinationState.connectorId) ? '10px' : '0', maxWidth: { xs: 150, sm: 220, md: 250 } }}
                            />
                        </div>
                        <div className="account-dropdown">
                            <Box sx={{ position: "absolute", right: { xs: "-23px", sm: "-30px" } }}>
                                {(destinationState.testingConnection) ? (<CircularProgress thickness={4} size={matches ? 21 : 25} />) : ""}
                                {(!!destinationState.credentialId && !destinationState.testingConnection) ?
                                    ((destinationState.connected) ?
                                        (<Tooltip title={destinationState.connectionMessage}><CheckCircleIcon sx={{ color: "#4bb543" }} /></Tooltip>) :
                                        (<Tooltip title={destinationState.connectionMessage}><ErrorIcon sx={{ color: "#f32013" }} /></Tooltip>)) : ""
                                }
                            </Box>
                            {destinationState.connector && destinationState.connector.DestinationDownloadFLG ? <></> : <ConnectionsDropdown
                                credentialID={destinationState.credentialId}
                                connectorID={destinationState.connectorId}
                                connections={destinationState.connections}
                                selectConnection={(val) => {
                                    // active selection was selected, return
                                    const id = Number(val.connector_credential_id)
                                    if (+destinationState.credentialId === +id) return

                                    dispatchDestination({type: CONNECTOR_REDUCER_TYPES.CONNECTION_SELECTED, payload: {credentialId: id}});
                                    testConnection(destinationState.connectorId, val.connector_credential_id, dispatchDestination);
                                }}
                                createConnection={() => {
                                    setShowDestinationAuthenticationModal(true);
                                    setDestinationAuthenticationProps({})
                                }}
                                editConnection={(connection) => {
                                    setShowDestinationAuthenticationModal(true);
                                    setDestinationAuthenticationProps(prev => ({...prev, ...connection}))
                                }}
                                deleteConnection={(connection) => {
                                    setDeleteModal({ ...connection, source: false })
                                }}
                                onChangeDefault={(payload) => {
                                    handleChangeDefaultConnection({...payload, source: false, })
                                }}
                            />}
                        </div>
                    </Box>
                </Grid>
            </Grid>

            <ConnectorAuthenticationModal source={true} open={showSourceAuthenticationModal} setOpen={setShowSourceAuthenticationModal} {...sourceAuthenticationProps} />
            <ConnectorAuthenticationModal source={false} open={showDestinationAuthenticationModal} setOpen={setShowDestinationAuthenticationModal} {...destinationAuthenticationProps} />
            <Dialog
                open={deleteModal}
                handleClose={() => setDeleteModal(null)}
                title="Confirm Delete"
                handleConfirm={() => handleDeleteConnection(deleteModal)}
            >
                You are about to delete a connection with {deleteModal?.pipeline_ct} pipelines. Are you sure you want to delete this? This will disable those {deleteModal?.pipeline_ct} pipelines.
            </Dialog>
        </div>
    )

}