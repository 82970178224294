import React, {useContext, useEffect, createContext, useState} from 'react';
import {Snackbar, Alert} from '@mui/material';

const AlertContext = createContext();

export function useAlertContext() {
    return useContext(AlertContext);
}

export default function AlertProvider({children}) {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState();
    const [alertMessage, setAlertMessage] = useState();
    const [severity, setSeverity] = useState('error');

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }

        setOpen(false);
    };

    useEffect(() => {
        if (open) {
            setOpen(false);
        }

        if (message) {
            setAlertMessage(message);
            setOpen(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message]);

    return (
        <AlertContext.Provider value={{ setMessage, setSeverity }}>
            {children}
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={open}
                autoHideDuration={10000}
                onClose={handleClose}
            >
                <Alert severity={severity}>
                    {alertMessage}
                </Alert>
            </Snackbar>
        </AlertContext.Provider>
    )
}