import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import OauthPopup from 'react-oauth-popup'
import { useForm } from 'react-hook-form'
import { FormControl, FormLabel, RadioGroup, Radio, Button, FormControlLabel, Checkbox, Box, CircularProgress } from '@mui/material'
import DataConTextField from '../../common/data-con-form/FormFields'
import { ConnectorAPIService } from '../../../config/api-service'

export default function ConnectionEditForm ({ connection, handleSave, handleClose } = {}) {
  const { handleSubmit, control, reset } = useForm({
    mode: 'all',
    defaultValues: {
      connectionName: connection?.connection_nm || ''
    }
  })

  const [authenticationDetails, setAuthenticationDetails] = useState(null)
  useEffect(() => {
    if (!connection?.connector_id) return
    ConnectorAPIService.getAuthenticationDetails(connection.connector_id, connection.source)
      .then(res => {
        setAuthenticationDetails({
          fields: res?.details?.fields,
          environments: res?.details?.length ? res.details : [],
          method: res.dataValues.AuthenticationMethodID,
          whitelisting: res.dataValues.WhitelistingFLG === 1
        })
      })
  }, [connection])

  const defaultValues = {
    defaultAccount: !!connection?.connector_default_flg
  }

  const [authURL, setAuthURL] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [inputs, setInputs] = useState(defaultValues)
  const [environment, setEnvironment] = useState({ environment: "production" })
  useEffect(() => {
    if (authenticationDetails?.method === '1' && authenticationDetails?.fields?.length) {
      let data = {
        connectionName: connection?.connection_nm || ''
      }
      authenticationDetails?.fields.forEach(({ field, default: val }) => {
        data = { ...data, [field]: val }
      })
      reset(data)
    }

    if (authenticationDetails?.method === '2') {
      setEnvironment(authenticationDetails?.environments[0])
    }
    //eslint-disable-next-line
  }, [authenticationDetails])

  useEffect(() => {
    if (authenticationDetails?.method === '2') {
      const redirectURL = window.location.origin + '/oauth'
      const { redirectURLPrefix, authorizationURL } = environment

      setAuthURL(authorizationURL + redirectURLPrefix + redirectURL)
    }
  }, [authenticationDetails, environment])

  const [isAuthRequired, setIsAuthRequired] = useState(true)
  const [oauthCredentials, setOauthCredentials] = useState()
  const oauthCodeResponse = (code, params) => {
    let parm = {}
    params.forEach((value, key) => parm[key] = value)
    if (!parm["error"]) {
        setOauthCredentials(parm);
        setIsAuthRequired(false)
    } else {
        setErrorMessage(
            "Canceled with status message: " +
                parm["error"] +
                `. Please reauthenticate by clicking the ${connection?.connector_nm} LOGIN button.`
        );
    }
  }

  const oauthClose = () => { }

  const handleCheck = (event) => {
    setInputs({ ...inputs, [event.target.name]: event.target.checked })
  }

  const [loading, setLoading] = useState(false)
  const handleOnClickSave = async (values) => {
    setLoading(true)
    let payload = {
      authenticationMethod: authenticationDetails?.method,
      defaultAccount: inputs['defaultAccount'],
      ...values
    }
    if (authenticationDetails?.method === '2') {
      payload = {
        ...payload,
        ...oauthCredentials,
        environment: environment.environment,
        redirectURL: window.location.origin + '/oauth'
      }
    }
    await handleSave({
      ...connection,
      payload
    })
    setLoading(false)
  }

  if (!authenticationDetails) return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <CircularProgress />
    </Box>
  )

  if (authenticationDetails?.method === '2') return (
    isAuthRequired
      ? <div style={{ textAlign: "center" }}>
        <p style={{ marginBotton: 20, marginTop: 0 }}>Use the button below to reauthenticate with {connection?.connector_nm}</p>
        {authenticationDetails?.environments.length ? (
          <FormControl component="fieldset">
            <FormLabel sx={{ ml: -2.25 }} component="legend">Environment</FormLabel>
            <RadioGroup
              aria-label="gender"
              value={environment.environment}
              name="radio-buttons-group"
              onChange={(e) => setEnvironment(authenticationDetails.environments.find(env => env.environment === e.target.value))}
            >
              {authenticationDetails.environments.map(env => (
                <FormControlLabel key={env.environment} value={env.environment} control={<Radio />} label={env.environmentName} />
              ))}
            </RadioGroup>
          </FormControl>
        ) : ""}

        <OauthPopup
          url={authURL}
          onCode={oauthCodeResponse}
          onClose={oauthClose}
        >
          <Button variant="contained" color="primary">{connection?.connector_nm} Login</Button>
        </OauthPopup>
        <div
				style={{
					color: "red",
					paddingTop: 5,
					width: "90%",
					margin: "0 auto",
				}}
			>
				{errorMessage}
			</div>
      </div>
      : <form autoComplete="off">
        <DataConTextField
          className="field"
          autoComplete="off"
          size="small"
          fullWidth
          variant="outlined"
          label="Connection Name"
          type="text"
          name="connectionName"
          required
          control={control}
          rules={{ required: `Connection Name is required` }}
        />
        <div>
          <FormControlLabel
            control={
              <Checkbox
                name="defaultAccount"
                color="primary"
                checked={inputs['defaultAccount']}
                onChange={handleCheck}
              />
            }
            label="Default Connection"
          />
        </div>

        <div style={{ borderTop: "1px solid #ccc", margin: "20px 0px" }}></div>

        <Button variant="contained" color="primary" disabled={loading} onClick={handleSubmit(handleOnClickSave)}>Save</Button>
        <Button type="button" variant="outlined" color="secondary" onClick={() => { handleClose(); setIsAuthRequired(true) }} style={{ float: "right" }}>Cancel</Button>
      </form >
  )

  return (
    <form autoComplete="off">
      <p style={{ textAlign: 'center' }}>Use the form below to reauthenticate with {connection?.connector_nm}</p>

      <DataConTextField
        autoComplete="off"
        className="field"
        size="small"
        fullWidth
        variant="outlined"
        label="Connection Name"
        type="text"
        name="connectionName"
        required
        control={control}
        rules={{ required: `Connection Name is required` }}
      />

      {
        authenticationDetails?.method === '1' &&
        authenticationDetails?.fields?.length &&
        authenticationDetails?.fields?.map(({ field, label, type, default: val, required }) => (
          <DataConTextField
            key={field}
            autoComplete="new-password"
            className="field"
            size="small"
            fullWidth
            variant="outlined"
            label={label}
            type={type}
            name={field}
            defaultValue={val}
            required={required}
            control={control}
            rules={required ? { required: `${label} is required` } : null}
          />
        ))
      }

      {authenticationDetails?.whitelisting ? <>
        <p><span style={{ color: 'red' }}>IMPORTANT</span><br /> Make sure to add our Public IP Address to allow access to any IP restricted firewall servers. <br /><b>3.143.20.235</b><br /></p>
        <Link to="">Learn More..</Link>
      </> : ""}

      <div>
        <FormControlLabel
          control={
            <Checkbox
              name="defaultAccount"
              color="primary"
              checked={inputs['defaultAccount']}
              onChange={handleCheck}
            />
          }
          label="Default Connection"
        />
      </div>

      <div style={{ borderTop: "1px solid #ccc", margin: "20px 0px" }}></div>

      <Button variant="contained" color="primary" disabled={loading} onClick={handleSubmit(handleOnClickSave)}>Save</Button>
      <Button type="button" variant="outlined" color="secondary" onClick={handleClose} style={{ float: "right" }}>Cancel</Button>
    </form >
  )
} 