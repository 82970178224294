import React, { useState, useEffect } from 'react';
import { format } from 'date-fns'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableSortLabel, TableRow, Button, CircularProgress, Tooltip } from '@mui/material';
import { visuallyHidden } from '@mui/utils'
import Dialog from '../../common/dialog';
import BarComponent from '../../common/bar';
import { usePipelineContext } from "../pipeline.context";
import { ConfigureAPIService, ConnectorAPIService } from '../../../config/api-service';
import { useParams } from 'react-router';

const testRunColumns = [
    { id: 'LogDTS', label: 'Date' },
    { id: 'ConnectorNM', label: 'Connector' },
    { id: 'LogTXT', label: 'Log' }
]

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}

function EnhancedTableHead({ order, orderBy, onRequestSort }) {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property)
    }

    return (
        <TableHead>
            <TableRow>
                {testRunColumns.map((col) => (
                    <TableCell
                        key={col.id}
                        align="left"
                        className="table-header-cell"
                        sortDirection={orderBy === col.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === col.id}
                            direction={orderBy === col.id ? order : 'asc'}
                            onClick={createSortHandler(col.id)}
                        >
                            {col.label}
                            {orderBy === col.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

export default function ConfigureTestRunComponent({ jobName }) {
    const [testRows, setTestRows] = useState([]);
    const [invoked, setInvoked] = useState(false);
    const [buttonState, setButtonState] = useState("Start");
    const [historyID, setHistoryID] = useState();
    const [openEditChangesModal, setOpenEditChangesModal] = useState(false)
    const { jobID, getEditPayload, destinationState } = usePipelineContext();
    const { id } = useParams()

    useEffect(() => {
        if (invoked && historyID) {
            const getLogs = () => {
                ConfigureAPIService.getLogs(historyID).then(res => {
                    if (res) {
                        let error = false;
                        res.logs.forEach(log => {
                            if (log.LogTypeCD === 2) error = true;
                            log.LogDTS = new Date(log.LogDTS)
                        })
                        setTestRows(res.logs);

                        if (res.done) {
                            clearInterval(intervalId);
                            setInvoked(false);
                            setButtonState("Retry");
                            if (destinationState.connector?.DestinationDownloadFLG && !error) {
                                // Download file
                                ConnectorAPIService.downloadRunFile(historyID)
                                    .catch(err => {
                                        console.log(err)
                                    })
                            }
                        }
                    }
                })
            }
            getLogs();
            const intervalId = setInterval(getLogs, 5000);
            return () => clearInterval(intervalId); //This is important
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoked])

    const runTest = async (saveEditChanges) => {

        if (id && !saveEditChanges) {
            setOpenEditChangesModal(true);
            return;
        }

        if (saveEditChanges) {
            let payload = getEditPayload();

            await ConfigureAPIService.putPipelineChanges(payload, jobID);

            setOpenEditChangesModal(false);
        }

        if (jobID) {
            ConfigureAPIService.postInvoke(jobID).then(res => {
                setHistoryID(res.historyID);
                setInvoked(true);
            })
        }
        // setInvoked(true);
        setButtonState("Running")
    }

    const [order, setOrder] = useState('desc')
    const [orderBy, setOrderBy] = useState('LogDTS')
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    return (
        <div>
            <BarComponent>
                {
                    !jobName
                        ? <Tooltip title="You must name your pipeline before running" arrow>
                            <span style={{ cursor: 'not-allowed' }}>
                                <Button variant="contained" style={{ backgroundColor: '#eee', color: '#000', opacity: 0.8, borderRadius: 3, padding: '5px 15px', fontSize: 12, fontWeight: 600 }} disabled>
                                    {buttonState}
                                    {buttonState === "Running" && <CircularProgress color="primary" size={16} thickness={6} sx={{ ml: 1.2 }} />}
                                </Button>
                            </span>
                        </Tooltip>
                        : <Button variant="contained" style={{ backgroundColor: '#eee', color: '#000', borderRadius: 3, padding: '5px 15px', fontSize: 12, fontWeight: 600, opacity: invoked && 0.8 }} onClick={() => runTest(false)} disabled={invoked}>
                            {(invoked) ? (<>
                                <CircularProgress color="primary" size={16} thickness={6} sx={{ mr: 1.2 }} />
                            </>) : ""}
                            {buttonState}
                        </Button>
                }

                <span style={{ paddingLeft: 20, fontSize: 18 }}>
                    Test Run loading {jobName}
                </span>
                <span></span>
            </BarComponent>
            <TableContainer
                sx={{
                    borderRadius: 0,
                    mb: 2,
                    height: 220,
                    '& .MuiTableCell-root': {
                        width: '15%',
                        '&:last-child': { width: '70%' }
                    }
                }}
            >
                <Table size="small" stickyHeader>
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        {testRows.slice()
                            .sort(getComparator(order, orderBy))
                            .map(({ LogDTS, ConnectorNM, LogTXT, LogTypeCD }, index) => (
                                <TableRow sx={{ verticalAlign: 'top' }} className={(LogTypeCD === 2) ? "error-row" : ""} key={index} row={index} size="small">
                                    <TableCell title={LogDTS && format(LogDTS, 'MM/dd/yyyy hh:mm a')}>{LogDTS && format(LogDTS, 'MM/dd/yyyy hh:mm a')}</TableCell>
                                    <TableCell title={ConnectorNM}>{ConnectorNM}</TableCell>
                                    <TableCell sx={{ whiteSpace: 'normal !important' }} title={LogTXT}>{LogTXT}</TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog
                open={openEditChangesModal}
                handleClose={() => setOpenEditChangesModal(false)}
                title="Confirm Save"
                handleConfirm={() => runTest(true)}
            >
                You must save your changes before running
            </Dialog>
        </div>
    )
}



