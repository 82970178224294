import { Grid, Typography, Breadcrumbs } from '@mui/material'

export default function ResourceDetails ({ details: { name = '', content = '' }, onClickBreadcrumb }) {
  function createMarkup () {
    return { __html: content }
  }

  return (
    <>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb" sx={{ pt: 4, pb: 2, '& p': { fontWeight: 600 } }}>
          <Typography sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }} onClick={() => onClickBreadcrumb(null)}>
            Resources
          </Typography>
          <Typography color="text.primary">{name}</Typography>
        </Breadcrumbs>
      </Grid>

      <Grid item xs={12} sx={{ pb: 4 }}>
        <Typography component={'h3'} variant="h5" sx={{ fontSize: 26, fontWeight: 600, textAlign: 'center' }} color="primary">
          {name}
          {' '}
          Documentation
        </Typography>
        <Typography component={'div'} variant="div" dangerouslySetInnerHTML={createMarkup()} />
      </Grid>
    </>
  )
}