import { TextField } from '@mui/material'
import { Controller } from 'react-hook-form'

export default function DataConTextField({ name, control, label, placeholder, rules = {}, helperText, ...rest }) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
        <TextField
          onChange={onChange}
          onBlur={onBlur}
          name={name}
          value={value}
          label={label}
          placeholder={placeholder}
          {...rest}
          error={!!error}
          helperText={error ? error.message : helperText}
        />
      )}
      rules={rules}
    />
  )
}
