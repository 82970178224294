import { useContext, useEffect, useState } from 'react'
import { Box, Typography, CircularProgress } from '@mui/material'
import MaterialUITheme from '../../../config/material-ui-theme'
import Connection from './Connection'
import Dialog from '../../common/dialog'
import ConnectionEditForm from '../../pipeline/connect/ConnectionEditForm'
import { ConnectorAPIService } from '../../../config/api-service'
import { CommonLoaderContext } from "../../../config/router"



export default function ConnectionsComponent () {
  const [, setCommonLoader] = useContext(CommonLoaderContext)
  const [allConnections, setAllConnections] = useState([])

  const getAllConnections = () => {
    setCommonLoader(true)
    ConnectorAPIService.getAllConnections()
      .then(res => {
        setAllConnections(res?.connections)
        setCommonLoader(false)
      })
  }

  useEffect(() => {
    getAllConnections();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  const [editConnection, setEditConnection] = useState(null)
  const handleEditModal = (connection) => {
    setEditConnection(connection)
  }

  const handleEditConnection = async ({ connector_id, connector_credential_id, payload }) => {
    await ConnectorAPIService.updateConnection({
      connector_id,
      connector_credential_id,
      payload
    })
    setEditConnection(null)

    getAllConnections()
  }

  const [deleteConnection, setDeleteConnection] = useState(null)
  const [pipelines, setpipeliens] = useState(null)
  const handleDeleteModal = (connection) => {
    setDeleteConnection(connection)
    !!connection && ConnectorAPIService.getConnectionPipelines({ connector: connection?.connector_id, connection: connection?.connector_credential_id })
      .then(res => {
        setpipeliens(res?.pipelines)
      })
  }

  const handleDeleteConnection = async ({ connector_id, connector_credential_id }) => {
    await ConnectorAPIService.deleteConnection({
      connector_id,
      connector_credential_id
    })
    setDeleteConnection(null)
    setpipeliens(null)

    getAllConnections()
  }

  const [disableConnector, setDisableConnector] = useState(null)
  const onCheckUncheck = (connector) => {
    setDisableConnector(connector)
    !!connector && ConnectorAPIService.getConnectorPipelines(connector.connector_id)
      .then(res => {
        setpipeliens(res?.pipelines)
      })
  }

  const handleDisableConnector = async ({ connector_id }) => {
    await ConnectorAPIService.disableConnectorPipelines(connector_id)
    setDisableConnector(null)
    setpipeliens(null)

    getAllConnections()
  }

  return (
    <MaterialUITheme>
      <Typography component={'h2'} variant="h3" sx={{ textAlign: 'center', mb: 3, mt: 2.5, fontSize: '2.5rem' }}>Connections</Typography>

      <Box sx={{ width: '60%', mx: 'auto', display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-between' }}>
        <Connection
          label="Connections"
          data={allConnections}
          onEditConnection={handleEditModal}
          onDeleteConnection={handleDeleteModal}
          onCheckUncheck={onCheckUncheck}
        />
      </Box>

      <Dialog
        open={editConnection}
        handleClose={() => setEditConnection(null)}
        title="Edit Connection"
        centerAligned={false}
      >
        <ConnectionEditForm connection={editConnection} handleClose={() => setEditConnection(null)} handleSave={handleEditConnection} />
      </Dialog>

      <Dialog
        open={deleteConnection}
        handleClose={() => { handleDeleteModal(null); setpipeliens(null) }}
        title="Confirm Delete"
        handleConfirm={() => handleDeleteConnection(deleteConnection)}
      >
        Are you sure you want to delete {deleteConnection?.connector_nm} connection: {deleteConnection?.connection_nm}?
        <br />
        This would disable {pipelines?.length || 0} pipelines.

        {!pipelines && <Box sx={{ width: 1, mt: 3, display: 'flex', justifyContent: 'center' }}>
          <CircularProgress size={24} />
        </Box>}

        <ol style={{ width: '80%', margin: '20px auto 0', textAlign: 'left', maxHeight: 125, overflow: 'auto' }}>
          {pipelines?.map(({ PipelineNM }, i) => (
            <li key={i}>{PipelineNM}</li>
          ))}
        </ol>
      </Dialog>

      <Dialog
        open={disableConnector}
        handleClose={() => { setDisableConnector(null); setpipeliens(null) }}
        title="Confirm Disable"
        handleConfirm={() => handleDisableConnector(disableConnector)}
      >
        Are you sure you want to disable {disableConnector?.connector_nm}?
        <br />
        This would disable {pipelines?.length || 0} pipelines.

        {!pipelines && <Box sx={{ width: 1, mt: 3, display: 'flex', justifyContent: 'center' }}>
          <CircularProgress size={24} />
        </Box>}

        <ol style={{ width: '80%', margin: '20px auto 0', textAlign: 'left', maxHeight: 125, overflow: 'auto' }}>
          {pipelines?.map(({ PipelineNM }, i) => (
            <li key={i}>{PipelineNM}</li>
          ))}
        </ol>
      </Dialog>
    </MaterialUITheme >
  )
}
