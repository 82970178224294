import React, { useEffect, useState } from 'react';
import moment from 'moment';


const useTimeCounter = (eventTime, interval) => {

    const calculateTimeLeft = () => {
        let currentTime = (Math.floor(Date.now() / 1000)).toString();
        let leftTime = moment(eventTime).unix() - currentTime;
        let duration = moment.duration(leftTime, 'seconds');
        if (duration.asSeconds() <= 0) {
            clearInterval(interval);
        }
        duration = moment.duration(duration.asSeconds() - 1, 'seconds');
        // return (duration.days() >= 0 ? duration.days() : 0 + ' Days ' + duration.hours() + ' Hours ' + duration.minutes() + ' Minutes.');
        return duration;
    }

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
    });

    return timeLeft
};

export default useTimeCounter;